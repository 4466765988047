import { DATE_FORMAT_OPTIONS, DateFormats, formatDateString } from '@/utils/date-utils';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import {
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridRenderCellParams,
  useGridApiContext
} from '@mui/x-data-grid-pro';
import { capitalize } from 'lodash';
import { useState } from 'react';

const CustomUserItem = (props: GridColumnMenuItemProps) => {
  const apiRef = useGridApiContext();
  const theme = useTheme();

  const { value } = props;

  const [open, setOpen] = useState(false);
  const onMenuItemClick = () => setOpen(!open);

  // get default state for the column format
  const initialColumn = apiRef.current.getAllColumns().find((column) => column.field === props.colDef.field);
  const initialDateFormat = initialColumn?.description || 'day';

  const [selectedValue, setSelectedValue] = useState(initialDateFormat);

  // handle new format selection
  const onRadioSelectChange = (dateFormatOption: DateFormats) => {
    setSelectedValue(dateFormatOption);
    // get existing columns
    const initialColumns = apiRef.current.getAllColumns();

    // update the date column to show the new date format
    const newColumns = initialColumns.map((column) => {
      if (column.field === props.colDef.field) {
        return {
          ...column,
          description: dateFormatOption,
          renderCell: (cell: GridRenderCellParams) => {
            return (
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
                variant="body2"
              >
                {cell.value ? `${formatDateString(cell.value, dateFormatOption)}` : ''}
              </Typography>
            );
          }
        };
      }
      return column;
    });

    // update the columns & data grid state
    apiRef.current.updateColumns(newColumns);
    apiRef.current.forceUpdate();
  };

  return (
    <>
      <ListItemButton onClick={onMenuItemClick}>
        <CalendarMonthIcon fontSize="small" sx={{ mr: 2, color: theme.palette.grey[700] }} />
        <ListItemText>{value}</ListItemText>
        {open ? (
          <ExpandLess sx={{ color: theme.palette.grey[700] }} />
        ) : (
          <ExpandMore sx={{ color: theme.palette.grey[700] }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ ml: 3 }}>
          <RadioGroup value={selectedValue} onChange={(e) => onRadioSelectChange(e.target.value as DateFormats)}>
            {DATE_FORMAT_OPTIONS.map((option) => (
              <FormControlLabel
                key={option}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 16 },
                  '& .MuiFormControlLabel-label': { fontSize: 12 }
                }}
                value={option}
                control={<Radio />}
                label={capitalize(option)}
              />
            ))}
          </RadioGroup>
        </List>
      </Collapse>
    </>
  );
};

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  const dateSlots =
    // only show the custom menu item on the date column
    props.colDef.field === 'displayDate'
      ? {
          slots: {
            // Add new item
            columnMenuUserItem: CustomUserItem
          },
          slotProps: {
            columnMenuUserItem: {
              displayOrder: 1, // show date menu item at top of list
              // pass additional props
              value: 'Change Date Format'
            }
          }
        }
      : {};
  return <GridColumnMenu {...props} {...dateSlots} />;
};
