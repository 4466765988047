import { ParsedDataRow } from '@/types/data-uploads';
import { Box, Theme, Typography } from '@mui/material';

interface DocumentStatusHeaderProps {
  rows: ParsedDataRow[];
  theme: Theme;
}

export const DocumentUploadStatusHeader = ({ rows, theme }: DocumentStatusHeaderProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end" // Align items to the bottom
      pt={theme.spacing(6)}
      pb={theme.spacing(2)}
    >
      <Typography variant="h6" sx={{ color: theme.palette.grey[400], pb: theme.spacing(5) }}>
        Document Status
      </Typography>
      {rows.length > 0 && (
        <Box display="flex" alignItems="flex-end">
          <Typography sx={{ fontSize: '0.8rem', mb: theme.spacing(1) }}>Total Documents in Collection</Typography>
          <Typography variant="h1" sx={{ ml: theme.spacing(1), lineHeight: 1, fontSize: '5rem' }}>
            {rows.length}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
