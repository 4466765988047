import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { EditDeleteDialog } from './edit-delete';

// Form wrapper with save and delete buttons (+ confirmation of deletion dialog)
export const EditPage = ({
  header,
  children,
  postDeleteEntity,
  handleClose,
  onSaveClicked,
  navigate,
  confirmDeleteMsg,
  shouldPinBottom = false
}: {
  header: string;
  handleClose: () => void;
  postDeleteEntity: () => void;
  children: ReactNode;
  onSaveClicked: () => void;
  navigate: NavigateFunction;
  confirmDeleteMsg?: string;
  shouldPinBottom?: boolean;
}) => {
  const [openConfirmDeleteCollectionModal, setOpenConfirmDeleteCollectionModal] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const theme = useTheme();

  const onSave = async () => {
    setSaveInProgress(true);
    try {
      onSaveClicked();
    } finally {
      setSaveInProgress(false);
    }
  };

  const onDelete = () => {
    setOpenConfirmDeleteCollectionModal(true);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: theme.spacing(3),
          pl: theme.spacing(5),
          pr: theme.spacing(5)
        }}
      >
        <Typography variant="h2">{header}</Typography>
        <IconButton
          sx={{
            pb: theme.spacing(3.3),
            '&:hover': {
              color: theme.palette.green.medium,
              backgroundColor: 'transparent'
            }
          }}
          onClick={() => {
            handleClose();
            navigate(-1);
          }}
          aria-label="close"
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={theme.spacing(2)}
        pl={theme.spacing(5)}
        pr={theme.spacing(5)}
        sx={{ height: '100%' }}
      >
        <Box
          flexGrow={1} // This makes the content container grow and push the buttons to the bottom
          display="flex"
          flexDirection="column"
          gap={theme.spacing(1)}
        >
          {children}
          <EditDeleteDialog
            openConfirmDeleteModal={openConfirmDeleteCollectionModal}
            setOpenConfirmDeleteModal={setOpenConfirmDeleteCollectionModal}
            postDeleteEntity={postDeleteEntity}
            handleClose={handleClose}
            confirmDeleteMsg={confirmDeleteMsg}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            background: theme.palette.background.paper,
            pb: theme.spacing(3),
            pl: shouldPinBottom ? theme.spacing(1) : theme.spacing(6),
            pr: shouldPinBottom ? theme.spacing(1) : theme.spacing(6),
            position: shouldPinBottom ? 'sticky' : 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000
          }}
        >
          <Button
            variant="clean-creative"
            color="primary"
            disabled={saveInProgress}
            sx={{
              ml: theme.spacing(-2)
            }}
            onClick={() => onSave()}
          >
            Save
          </Button>
          <Button variant="destructive-light" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
