import { basicTheme, kfLuxuryTheme } from '@/styles/custom-themes';
import { SxProps } from '@mui/material';

// get theme based on route
export const getThemeBasedOnRoute = (pathname: string) => {
  if (pathname.startsWith('/prism-themes') || pathname.startsWith('/v2') || pathname.startsWith('/sonicboom')) {
    return kfLuxuryTheme;
  } else {
    return basicTheme; // Default theme
  }
};

/**
 * hides the permanently shown scrollbar for all browsers when an overflow
 * is in play
 */
export const getHideScrollBarProps = (): SxProps => {
  return {
    scrollbarWidth: 'none', // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
      display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
      display: 'none' // Hide the scrollbar for IE
    }
  };
};
