import { DataGrid } from '@/components';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import { Add, Clear, Edit, RadioButtonChecked } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography,
  lighten
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

export const CollectionsFlexibleMetaschemaTable = ({
  isVisible,
  theme,
  onChange,
  metadataScheme
}: {
  isVisible: boolean;
  theme: Theme;
  onChange: (metadata: { id: number; label: string; type?: string; prompt?: string }[]) => void;
  metadataScheme?: { id: number; label: string; type?: string; prompt?: string }[];
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    id: Date.now() + Math.floor(Math.random() * 1000),
    label: '',
    type: '',
    prompt: ''
  });

  const [localRows, setLocalRows] = useState(metadataScheme || []);

  useEffect(() => {
    setLocalRows(metadataScheme || []);
  }, [metadataScheme]);

  const columns = [
    {
      field: 'edit',
      title: ' ',
      width: 30,
      minWidth: 0,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setDialogData(params.row);
              setIsDialogOpen(true);
            }}
            sx={{
              color: theme.palette.primary.contrastText
            }}
          >
            <Edit fontSize="small" style={{ color: theme.palette.green.light }} />
          </IconButton>
        );
      }
    },
    {
      field: 'label',
      title: 'LABEL',
      resizable: true,
      width: 150,
      valueGetter: (params: { row: { label?: string } }) => (params.row.label ? params.row.label : 'N/A')
    },
    {
      field: 'type',
      title: 'TYPE',
      resizable: true,
      width: 80,
      valueGetter: (params: { row: { type?: string } }) => (params.row.type ? params.row.type : 'N/A')
    },
    {
      field: 'prompt',
      title: 'PROMPT',
      resizable: true,
      width: 400,
      valueGetter: (params: { row: { prompt?: string } }) => (params.row.prompt ? params.row.prompt : 'N/A'),
      renderCell: (params: GridRenderCellParams) => {
        return params.value ? (
          <Tooltip title={<Typography variant="body2">{params.value}</Typography>} arrow>
            <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: 12 }}>
              {params.value}
            </Typography>
          </Tooltip>
        ) : (
          'N/A'
        );
      }
    },
    {
      field: 'remove',
      title: ' ',
      width: 30,
      minWidth: 0,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteRow(params.row.id);
            }}
            sx={{
              color: theme.palette.primary.contrastText
            }}
          >
            <Clear fontSize="small" style={{ color: theme.palette.red.light }} />
          </IconButton>
        );
      }
    }
  ];

  if (!isVisible) return null;

  const handleAddRow = () => {
    const newRow = { id: Date.now() + Math.floor(Math.random() * 1000), label: '', type: '', prompt: '' };
    const updatedRows = [...localRows, newRow];
    setLocalRows(updatedRows);
    onChange(updatedRows); // Emit changes immediately or on specific action like "Save"
  };

  const handleDeleteRow = (id: number) => {
    const updatedRows = localRows.filter((row) => row.id !== id);
    setLocalRows(updatedRows);
    onChange(updatedRows); // Emit changes immediately or on specific action like "Save"
  };

  const handleUpdateRow = (updatedRow: { id: number; label: string; type: string; prompt: string }) => {
    const updatedRows = localRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setLocalRows(updatedRows);
    onChange(updatedRows); // Emit changes immediately or on specific action like "Save"
  };

  return (
    <Box sx={{ width: '100%', overflow: 'auto', ...getHideScrollBarProps() }}>
      <Box display="flex" sx={{}}>
        <Typography
          variant="button"
          sx={{ fontSize: 16, fontWeight: 600, pt: theme.spacing(5.8), pr: theme.spacing(3) }}
        >
          Document Metadata Scheme
        </Typography>
        <Button
          variant="clean-creative"
          color="primary"
          sx={{ pt: theme.spacing(6) }}
          onClick={() => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'application/json';
            fileInput.onchange = (event: Event) => {
              const target = event.target as HTMLInputElement;
              const file = target.files?.[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const json = e?.target?.result;
                  try {
                    const data = JSON.parse((json as string) || '[]');
                    setLocalRows(data);
                  } catch (error) {
                    console.error('Error parsing JSON:', error);
                  }
                };
                reader.readAsText(file);
              }
            };
            fileInput.click();
          }}
        >
          Import
        </Button>
        <Button
          variant="destructive-light"
          sx={{ pt: theme.spacing(5.2), color: theme.palette.purple.lightest }}
          onClick={() => {
            const now = new Date();
            const filename = `Prism_CollectionMetadata-${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}-${now.getHours()}${now.getMinutes()}.json`;

            const json = JSON.stringify(localRows);
            const blob = new Blob([json], { type: 'application/json' });

            const url = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url); // Clean up the URL object
          }}
        >
          Export
        </Button>
        <Box display="flex" alignItems="flex-end" sx={{ pl: theme.spacing(3) }}>
          <Typography sx={{ fontSize: '0.8rem', mb: theme.spacing(1) }}>Metadata Fields</Typography>
          <Typography variant="h1" sx={{ ml: theme.spacing(1), lineHeight: 1, fontSize: '4rem' }}>
            {localRows.length}
          </Typography>
        </Box>
      </Box>
      <DataGrid
        rows={localRows ?? []}
        columns={columns}
        checkboxSelection={false}
        autoHeight={true}
        rowHeight={125}
        style={{ width: '100%' }}
        disableVirtualization={true}
        sx={{ ...getHideScrollBarProps() }}
      />
      <IconButton
        size="small"
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(5),
          background: theme.palette.primary.lightest,
          color: theme.palette.primary.main,
          ':hover': {
            background: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
            boxShadow: `0em 0em 0.4em 0em ${lighten(theme.palette.green.light, 0.2)}`
          }
        }}
        onClick={handleAddRow}
      >
        <Add />
      </IconButton>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Edit Metadata</DialogTitle>
        <DialogContent sx={{ width: '30vw' }}>
          <Typography variant="subtitle1" gutterBottom>
            Label
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="label"
            type="text"
            fullWidth
            variant="outlined"
            value={dialogData.label}
            onChange={(e) => setDialogData({ ...dialogData, label: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.green.light
                }
              }
            }}
          />
          <Typography sx={{ pt: theme.spacing(2) }} variant="subtitle1" gutterBottom>
            Type
          </Typography>
          <RadioGroup
            aria-label="type"
            name="type"
            value={dialogData.type}
            onChange={(e) => setDialogData({ ...dialogData, type: e.target.value })}
            row
          >
            <FormControlLabel
              value="text"
              control={<Radio checkedIcon={<RadioButtonChecked sx={{ color: theme.palette.green.light }} />} />}
              label="Text"
            />
            <FormControlLabel
              value="number"
              control={<Radio checkedIcon={<RadioButtonChecked sx={{ color: theme.palette.green.light }} />} />}
              label="Number"
            />
            <FormControlLabel
              value="date"
              control={<Radio checkedIcon={<RadioButtonChecked sx={{ color: theme.palette.green.light }} />} />}
              label="Date"
            />
          </RadioGroup>
          <Typography sx={{ pt: theme.spacing(2) }} variant="subtitle1" gutterBottom>
            Prompt
          </Typography>
          <TextField
            margin="dense"
            id="prompt"
            type="text"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            value={dialogData.prompt}
            onChange={(e) => setDialogData({ ...dialogData, prompt: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.green.light
                }
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="clean-creative"
            onClick={() => {
              handleUpdateRow(dialogData);
              setIsDialogOpen(false);
            }}
          >
            Save
          </Button>
          <Button variant="destructive-light" onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
