import theme from '@/styles/theme';
import { useMediaQuery } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { ReactNode } from 'react';

export const RoundedBodyArea = ({ children, sx = {} }: { children?: ReactNode; sx?: SxProps }) => {
  return (
    <Box
      sx={{
        height: 'unset',
        borderTopLeftRadius: theme.borderRadius,
        borderTopRightRadius: theme.borderRadius,
        top: 85,
        position: 'absolute',
        bottom: 0,
        overflowY: 'scroll',
        width: { xs: '100vw', sm: '100%' }, // 100vw on extra-small screens and 100% on small screens and up
        // hide the native scrollbar until we have a better style for it.
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&-ms-overflow-style:': {
          display: 'none'
        },
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

/**
 * Rounded responsive area for the main body element that shows between side nav and chat
 * - currently includes search box as well
 */
export const ResponsiveBody = ({
  children,
  isChatting,
  sx = {}
}: {
  children?: ReactNode;
  isChatting: boolean;
  sx?: SxProps;
}) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        mr: isChatting && !isSmallScreen ? 2 : 0,
        // larger ml (5) when just viewing vault item on md screen, smaller ml (2) when md screen with chat open, and no ml on small screen
        ml: isMdScreen && !isChatting && !isSmallScreen ? 5 : isChatting && !isSmallScreen && isMdScreen ? 2 : 0,
        width: isChatting && !isSmallScreen ? '70%' : '100%',
        transition: 'width 1200ms',
        position: 'relative',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

/**
 * Layout for the main body area
 */
export const MainBodyLayout = ({ children }: { children?: ReactNode }) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      sx={{
        height: 'calc(100vh - 10px)',
        pt: 2,
        pb: isSmallScreen ? theme.spacing(7) : 0
      }}
    >
      {children}
    </Box>
  );
};
