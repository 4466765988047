import { useAuth0 } from '@auth0/auth0-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, ButtonBase, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavBar } from '../stores/navbar-context';

export const NavBarProfile = () => {
  const theme = useTheme();

  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { color } = useNavBar();

  const colorInUse = color?.main || theme.palette.blue.main;
  const textColor = theme.palette.getContrastText(colorInUse);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <ButtonBase aria-describedby={id} onClick={handleClick}>
        <Typography sx={{ color: textColor }} variant="h5">
          {user?.email}
        </Typography>
        <ArrowDropDownIcon sx={{ color: textColor }} />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.primary.contrastText }}
          onClick={() => {
            logout({ logoutParams: { returnTo: `${window.location.origin}/landing` } });
            handleClose();
          }}
        >
          <Typography variant="body1">Logout</Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
};
