/**
 * inspo: https://github.com/loadingio/css-spinner/
 */
import { makeStyles } from '@mui/styles';
import theme from '@/styles/theme';

const useStyles = makeStyles({
  ldsDualRing: {
    display: 'inline-block',
    width: '1.75rem',
    height: '1.75rem',
    marginTop: '0.5rem',
    '&:after': {
      content: '""',
      display: 'block',
      width: '1.0rem',
      height: '1.0rem',
      margin: '0.25rem',
      borderRadius: '50%',
      border: `0.175rem solid ${theme.palette.primary.main}`,
      borderColor: `${theme.palette.primary.main} transparent ${theme.palette.primary.main} transparent`,
      animation: '$ldsDualRing 1.2s linear infinite'
    }
  },
  '@keyframes ldsDualRing': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});

const DualRing = () => {
  const classes = useStyles();

  return <span className={classes.ldsDualRing}></span>;
};

export default DualRing;
