import { StaticTwoPanelLayout } from '@/features/layout';
import { useNavBar } from '@/features/navbar';
import { useGetApi } from '@/hooks/use-get-api';
import { LibraryProject } from '@/types/projects';
import { Box, Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { CreateLibraryButton } from './create-library';
import { LibraryCard } from './library-card';

export const LibrarySelectionLayout = ({ libs }: { libs: LibraryProject[] }) => {
  const theme = useTheme();
  return (
    <Grid container columnSpacing={theme.spacing(4)} justifyContent="center">
      {libs.map((lib) => (
        <Grid key={lib.uuid} item minWidth={350} height={350}>
          <LibraryCard library={lib} />
        </Grid>
      ))}
    </Grid>
  );
};

export const LibrarySelection = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  const [libraries, setLibraries] = useState<LibraryProject[]>([]);
  const [projects] = useGetApi<{ publicProjects: LibraryProject[]; privateProjects: LibraryProject[] }>(
    `project/available/libraries`
  );

  const { setParentBreadcrumb, setChildBreadcrumb } = useNavBar();

  // #region use effect
  useEffect(() => {
    setLibraries([...(projects?.privateProjects || []), ...(projects?.publicProjects || [])]);
  }, [projects]);

  useEffect(() => {
    setChildBreadcrumb(undefined);
    setParentBreadcrumb(undefined);
  }, [setChildBreadcrumb, setParentBreadcrumb]);
  // #endregion use effect

  return (
    <StaticTwoPanelLayout
      sideNavChildren={
        <Box height="100vh" sx={{ m: theme.spacing(4), mr: theme.spacing(0), position: 'relative' }}>
          {libraries.length > 0 && <Typography variant="h2">Please select a library.</Typography>}
          <Box sx={{ position: 'absolute', bottom: isMobile ? 30 : 150 }}>
            <CreateLibraryButton />
          </Box>
        </Box>
      }
      middlePanelChildren={
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{
            m: theme.spacing(5),
            mr: theme.spacing(7),
            ml: theme.spacing(7)
          }}
        >
          {isMobile && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h2" sx={{ mb: theme.spacing(6) }}>
                {libraries.length > 1 ? 'Please select a library.' : 'No libraries yet.'}
              </Typography>
              <Box sx={{ mb: theme.spacing(6) }}>
                <CreateLibraryButton />
              </Box>
            </Box>
          )}
          <LibrarySelectionLayout libs={libraries} />
        </Box>
      }
    />
  );
};
