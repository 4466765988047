import { DocumentScanner, FindInPage } from '@mui/icons-material';
import { Box, Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocumentContext } from '../../stores/document-context';
import { FileMetadata } from '../../types';
import { FilePreview } from './file-preview';

/**
 * Snippet view for search results
 */
export const ResultSnippet = ({ result, objectURL }: { result: FileMetadata; objectURL: string | null }) => {
  const theme = useTheme();
  const { setCurrentDocument } = useDocumentContext();

  const navigate = useNavigate();
  const { collectionId, projectId } = useParams<{
    collectionId: string;
    projectId: string;
  }>();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <Box
      sx={{
        pt: theme.spacing(3),
        pb: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.primary.lightest}`,
        width: '100%'
      }}
    >
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        sx={{
          m: 2
        }}
      >
        {/* Result file preview */}
        <Grid item xs={12} sm={4} minWidth="fit-content">
          <FilePreview
            id={result.uuid}
            objectURL={objectURL}
            pageNumber={result.pageNumber ?? 1}
            isSmallScreen={isSmallScreen}
            setIsPreviewOpen={setIsPreviewOpen}
            isPreviewOpen={isPreviewOpen}
          />
        </Grid>
        {/* Result document info */}
        <Grid item xs={12} sm={8}>
          <Grid container direction="column" justifyContent="space-between" height="100%">
            <Grid item>
              <Box>
                <Typography variant="h3">{result.displayName || result.title}</Typography>
                <Typography variant="subtitle2">Page {result.pageNumber}</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    maxHeight: 150,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '6',
                    WebkitBoxOrient: 'vertical',
                    mt: theme.spacing(2),
                    mb: theme.spacing(2)
                  }}
                >
                  {result.content}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex">
                <IconButton
                  size="medium"
                  sx={{ color: theme.palette.primary.lightest }}
                  onClick={() => setIsPreviewOpen(true)}
                >
                  <DocumentScanner />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => {
                    setCurrentDocument(result);
                    navigate(
                      `/v2/knowledge-finder/${projectId}/collections/${
                        collectionId ?? result.documentCollectionId
                      }/documents/${result.documentId}?sourcePage=${result.pageNumber}`
                    );
                  }}
                  sx={{ color: theme.palette.primary.lightest }}
                >
                  <FindInPage />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
