import { SNACKBAR_HANG_TIME } from '@/types/constants';
import { Close } from '@mui/icons-material';
import { Alert, IconButton, Snackbar, Typography, useTheme } from '@mui/material';

export const SaveSuccessSnackbar = ({
  saveSuccess,
  setSaveSuccess,
  anchorOriginVertical = 'top',
  anchorOriginHorizontal = 'right',
  paddingRight = 15,
  autohideDuration = SNACKBAR_HANG_TIME.MEDIUM
}: {
  saveSuccess: string;
  setSaveSuccess: React.Dispatch<React.SetStateAction<string>>;
  anchorOriginVertical?: 'top' | 'bottom';
  anchorOriginHorizontal?: 'left' | 'center' | 'right';
  paddingRight?: number;
  autohideDuration?: number;
}) => {
  const theme = useTheme();
  return (
    <>
      {!!saveSuccess && (
        <Snackbar
          ClickAwayListenerProps={{ onClickAway: () => setSaveSuccess('') }}
          open={!!saveSuccess}
          autoHideDuration={autohideDuration}
          onClose={() => setSaveSuccess('')}
          anchorOrigin={{ vertical: anchorOriginVertical, horizontal: anchorOriginHorizontal }}
          sx={{ paddingTop: 6, paddingRight, maxWidth: '600px' }}
        >
          <Alert
            icon={<Typography>✦</Typography>}
            onClose={() => setSaveSuccess('')}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => setSaveSuccess('')}>
                <Close fontSize="inherit" />
              </IconButton>
            }
            severity="success"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2),
              '.MuiIconButton-root': { color: theme.palette.success.main }
            }}
          >
            {saveSuccess}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
