import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useAuthToken } from './use-auth-token';

export const useGetApiWithPolling = <T,>(
  endpoint: string,
  initialData: T | null = null,
  restartDependency: boolean
): [T | null, Error | null] => {
  const [data, setData] = useState<T | null>(initialData);
  const [error, setError] = useState<Error | null>(null);

  const REQUEST_DELAY = 3 * 1000; // 3 second delay
  const NO_CHANGE_THRESHOLD = 10; // if no changes after 10 times (30s), stop askin as data state can be assumed stable

  const prevDataRef = useRef<T | null>(null);
  const noChangeCounterRef = useRef<number>(0);

  const fetchToken = useAuthToken();

  useEffect(() => {
    noChangeCounterRef.current = 0;

    const fetchData = async () => {
      try {
        const token = await fetchToken();
        const response = await axios.get(
          `${
            import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
          }/${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (JSON.stringify(prevDataRef.current) === JSON.stringify(response.data)) {
          // data is the same, increment the counter for polling getting it closer to stopping
          noChangeCounterRef.current += 1;
        } else {
          // data changed, reset the counter for polling
          noChangeCounterRef.current = 0;
          prevDataRef.current = response.data;
        }

        setData(response.data);

        if (noChangeCounterRef.current >= NO_CHANGE_THRESHOLD) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error(`Error loading data from ${endpoint}:`, error);
        setError(error instanceof Error ? error : new Error(String(error)));
        clearInterval(intervalId); // Stop polling if there's an error
      }
    };

    // Call fetchData immediately for the first iteration
    fetchData();

    const intervalId = setInterval(fetchData, REQUEST_DELAY);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [endpoint, fetchToken, REQUEST_DELAY, restartDependency]);

  return [data, error];
};
