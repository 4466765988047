import { DataGridProps } from '@/types/data-grid';
import { Box, useTheme } from '@mui/material';
import { GridColDef, LicenseInfo, DataGridPro as MuiDataGrid } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';

// using pro version of mui grid
LicenseInfo.setLicenseKey(
  'f946f773e635b428505fa6410a6d9bf5Tz04MjIwMSxFPTE3MzY3OTY3ODEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

export type GridColumn = Partial<GridColDef> & {
  field: string;
  title?: string;
};

// derives title from field if no title
const normalizeHeader = (columns: GridColumn[]): GridColDef[] =>
  columns.map((c) => ({
    ...c,
    headerName: c.title || c.field?.replace(/ /g, '_')
  }));

/**
 * Read-only data grid
 *
 * Supports a variety of renderers, such as chips and links.
 *
 */
export const DataGrid = ({
  autoHeight,
  columns,
  getRowDetail,
  height,
  hideBorder = true,
  rows,
  ...props
}: DataGridProps): JSX.Element => {
  const theme = useTheme();
  const defaultHeight = height || autoHeight ? null : '80vh';

  const addId = !rows[0]?.id;

  const formattedColumns = useMemo(() => normalizeHeader(columns), [columns]);
  const getDetailPanelContent = useCallback(
    ({ row }: { row: Record<string, unknown> }) => (getRowDetail ? getRowDetail({ row }) : null),
    [getRowDetail]
  );
  return (
    <Box sx={{ height: height || defaultHeight }}>
      <MuiDataGrid
        checkboxSelection
        hideFooter
        autoHeight={autoHeight}
        density="compact"
        columns={formattedColumns}
        getDetailPanelContent={getRowDetail ? getDetailPanelContent : undefined}
        getDetailPanelHeight={() => 'auto'} // Height based on the content.
        rows={addId ? rows.map((row, index) => ({ ...row, id: index })) : rows}
        {...props}
        slotProps={{
          baseTextField: {
            sx: {
              '& input': {
                '&::placeholder': {
                  color: theme.palette.grey[300]
                }
              },
              '& label': {
                color: theme.palette.green.light
              },
              '& label.Mui-focused': {
                color: theme.palette.green.light
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.grey[500]
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: theme.palette.green.light
              }
            }
          },
          baseInputLabel: {
            sx: {
              color: theme.palette.green.light
            }
          },
          filterPanel: {
            sx: {
              '& .MuiButton-root': {
                color: theme.palette.green.light,
                '&:hover': {
                  textDecoration: 'underline',
                  background: 'none'
                }
              }
            }
          },
          ...props.slotProps
        }}
        sx={{
          border: hideBorder ? 'none' : undefined,
          ...props.sx // Ensure any sx prop passed down is included
        }}
      />
    </Box>
  );
};
