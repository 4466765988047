import { HTMLElements } from '@/features/chatbot';
import { Card, ListItem, Theme, Typography } from '@mui/material';
import MuiMarkdown, { getOverrides } from 'mui-markdown';
import { ReactElement } from 'react';
import { ANALYZING_MESSAGE, ChatRoles, TOOL_DETECTION_STRING } from '../../types/constants';

interface MessageItemProps {
  msg: { role: string; content: string };
  index: number;
  theme: Theme;
}

// common markdown overrides
const getMarkDownOverrides = (theme: Theme, msgType: 'ASSISTANT' | 'USER') => ({
  ...getOverrides({}),
  ...HTMLElements.reduce(
    (acc, element) => ({
      ...acc,
      [element]: {
        component: element,
        props:
          element === 'a'
            ? {
                style: {
                  ...theme.typography.body2,
                  color: msgType === 'ASSISTANT' ? theme.palette.primary.contrastText : theme.palette.primary.dark
                }
              }
            : {
                style: theme.typography.body2
              }
      }
    }),
    {}
  )
});

/**
 * Styled Prism assistant message
 */
const AssistantMessageItem = ({ msg, index, theme }: MessageItemProps): ReactElement => (
  <ListItem key={index}>
    <Typography variant="h3" sx={{ position: 'absolute', top: -8, left: 4 }}>
      {'✦'}
    </Typography>
    <Card
      variant="outlined"
      sx={{
        p: theme.spacing(2),
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomRightRadius: theme.sharperBorderRadius,
        borderTopRightRadius: theme.sharperBorderRadius,
        borderBottomLeftRadius: theme.sharperBorderRadius
      }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.contrastText
        }}
        variant="body2"
      >
        <MuiMarkdown overrides={getMarkDownOverrides(theme, 'ASSISTANT')}>
          {msg.content.includes(TOOL_DETECTION_STRING) ? `${ANALYZING_MESSAGE}` : msg.content}
        </MuiMarkdown>
      </Typography>
    </Card>
  </ListItem>
);

/**
 * Styled user chat message
 */
const UserMessageItem = ({ msg, index, theme }: MessageItemProps): ReactElement => (
  <ListItem key={index}>
    <Card
      variant="outlined"
      sx={{
        p: theme.spacing(2),
        width: '100%',
        backgroundColor: theme.palette.primary.lightest,
        borderTopLeftRadius: theme.sharperBorderRadius,
        borderBottomRightRadius: theme.sharperBorderRadius,
        borderTopRightRadius: theme.sharperBorderRadius,
        borderBottomLeftRadius: theme.sharperBorderRadius
      }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.dark
        }}
        variant="body2"
      >
        <MuiMarkdown overrides={getMarkDownOverrides(theme, 'USER')}>{msg.content}</MuiMarkdown>
      </Typography>
    </Card>
  </ListItem>
);

export const MessageItem = ({ msg, index, theme }: MessageItemProps): ReactElement =>
  msg.role !== 'system' ? (
    msg.role === ChatRoles.ASSISTANT ? (
      <AssistantMessageItem msg={msg} index={index} theme={theme} />
    ) : (
      <UserMessageItem msg={msg} index={index} theme={theme} />
    )
  ) : (
    <></>
  );
