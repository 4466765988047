import { getHideScrollBarProps } from '@/utils/theme-utils';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

const StaticSideNavPanel = ({ width, children }: { width: string | number; children: ReactNode }) => {
  return (
    <Box width={width} sx={{ position: 'relative', display: { md: 'block', xs: 'none' } }}>
      <Box position="absolute" sx={{ top: 0, bottom: 0, left: 0, right: 0 }}>
        {children}
      </Box>
    </Box>
  );
};

const MiddlePanel = ({ width, children }: { width: string | number; children: ReactNode }) => {
  return (
    <Box width={width} position="relative">
      <Box
        position="absolute"
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'scroll',
          ...getHideScrollBarProps()
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

/**
 * Two panel layout (no chat)
 * - For Project Selection screens
 */
export const StaticTwoPanelLayout = ({
  sideNavChildren,
  middlePanelChildren
}: {
  sideNavChildren: ReactNode;
  middlePanelChildren: ReactNode;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const sideNavWidth = isMobile ? 0 : 300;
  const mainPanelWidth = `calc(100% - ${sideNavWidth}px)`;

  const isMobilePortrait = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMobileLandscape = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px) and (orientation: landscape)`);
  let appBarHeight = '64px';
  if (isMobilePortrait) {
    appBarHeight = '56px';
  } else if (isMobileLandscape) {
    appBarHeight = '48px';
  }

  return (
    <Box
      display="flex"
      height={`calc(100vh - ${appBarHeight})`}
      sx={{
        overflow: 'hidden',
        ...getHideScrollBarProps()
      }}
    >
      <StaticSideNavPanel width={sideNavWidth}>{sideNavChildren}</StaticSideNavPanel>
      <MiddlePanel width={mainPanelWidth}>{middlePanelChildren}</MiddlePanel>
    </Box>
  );
};
