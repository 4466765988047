// Chatbot Constants

export const ASSISTANT_MESSAGE_PREFIX = '✦&nbsp;&nbsp;';

export const ANALYZING_MESSAGE = `${ASSISTANT_MESSAGE_PREFIX} <strong><i>Analyzing...</i></strong>`;

export const TOOL_DETECTION_STRING = '[TOOL-USE]';

export const TOOL_USE_REGEX = /\[TOOL-USE\]<tool\s+([^>]+)>(.*?)<\/tool>/;

export const DOCUMENT_SEARCH_ERROR =
  'Sorry. I encountered an error sarching the document. Please check the logs and try again.';

export enum ChatRoles {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system'
}
