import { ParsedDataRow } from '@/types/data-uploads';
import { formatDateString } from '@/utils/date-utils';
import { getNameOrEmail } from '@/utils/name-utils';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { DocumentCollection } from '../../types';

interface DocumentHeaderProps {
  collection: DocumentCollection | undefined;
  rows: ParsedDataRow[];
  projectId: string | undefined;
  collectionId: string | undefined;
  navigate: (path: string) => void;
  theme: Theme;
}

export const DocumentUploadHeader = ({
  collection,
  rows,
  projectId,
  collectionId,
  navigate,
  theme
}: DocumentHeaderProps) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Collection Documents</Typography>
        <IconButton
          onClick={() => navigate(`/v2/knowledge-finder/${projectId}/collections/${collectionId}`)}
          aria-label="close"
        >
          <Close />
        </IconButton>
      </Box>
      <Typography variant="subtitle2" sx={{ color: theme.palette.grey[400] }}>
        Created {`${formatDateString(new Date(collection?.createdAt as unknown as Date).toLocaleString())} by `}
        {getNameOrEmail({
          email: collection?.curatedBy.email,
          firstName: collection?.curatedBy.firstName,
          lastName: collection?.curatedBy.lastName
        })}
      </Typography>
      {rows && rows.length > 0 && (
        <Typography variant="subtitle2" sx={{ color: theme.palette.grey[400] }}>
          Documents Last Added{' '}
          {formatDateString(
            new Date(
              rows.reduce((latest, row) => {
                return new Date(row.createdAt) > new Date(latest.createdAt) ? row : latest;
              }).createdAt
            ).toLocaleString()
          )}
        </Typography>
      )}
    </>
  );
};
