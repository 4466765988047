import { useGetApi } from '@/hooks/use-get-api';
import PrismLogoLoader from '@/styles/loaders/prism-logo-loader';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
  linearProgressClasses,
  styled,
  useTheme
} from '@mui/material';

interface ColoredLinearProgressProps extends LinearProgressProps {
  colorIndex: number;
}

interface ReportingDataCounts {
  facet: string;
  count: number;
}

export const InvestigationsPulse = () => {
  const theme = useTheme();

  const [counts] = useGetApi<ReportingDataCounts[]>(`tatsu/investigations/pulse`);

  const maxValues: { [key: string]: number } = {
    user: 50,
    project: 100,
    document_collection: 200,
    document: 500,
    document_semantic_embedding: 10000
  };

  const palette = [
    theme.palette.red,
    theme.palette.orange,
    theme.palette.yellow,
    theme.palette.green,
    theme.palette.blue,
    theme.palette.purple,
    theme.palette.violet,
    theme.palette.pink
  ];

  // Styled LinearProgress for buffer effect with dynamic colors
  const ColoredLinearProgress = styled(LinearProgress)<ColoredLinearProgressProps>(({ colorIndex }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette[colorIndex % palette.length].dark // the darker color as the background
    },
    [`& .${linearProgressClasses.bar1Determinate}`]: {
      backgroundColor: palette[colorIndex % palette.length].light // the main color for the progress bar
    }
  }));

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(5),
        left: 0,
        right: 0,
        pl: theme.spacing(5),
        pr: theme.spacing(5)
      }}
    >
      <Typography variant="h4" sx={{ color: 'black', fontWeight: '400' }}>
        Pulse
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], mb: theme.spacing(5) }}>
        get a sense of the number of existing entities in the platform
      </Typography>
      {!counts ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 20 }}>
          <PrismLogoLoader style={{ fontSize: '250px' }} />
        </Box>
      ) : (
        counts.map((item, index) => (
          <Box key={index} sx={{ mb: 4, position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ color: 'black' }}>
                {item.facet.replace(/_/g, ' ')}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'black' }}>
                {item.count} / {maxValues[item.facet]}
              </Typography>
            </Box>
            <ColoredLinearProgress
              variant="determinate"
              value={Math.min(100, (item.count / maxValues[item.facet]) * 100)}
              colorIndex={index}
            />
          </Box>
        ))
      )}
    </Box>
  );
};
