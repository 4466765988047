import { MainBodyLayout, ResponsiveBody, SideNavLayout, StaticTwoPanelLayout } from '@/features/layout';
import { useNavBar, useNavBarMenu } from '@/features/navbar';
import PrismLogoLoader from '@/styles/loaders/prism-logo-loader';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TatsuInvestigations, TatsuOrchestrations } from '../types/functionality';
import { FnTypes, QueryParams } from '../types/query-params';
import { InvestigationsInquire } from './investigations/investigations-inquire';
import { InvestigationsPulse } from './investigations/investigations-pulse';
import { OrchestrationsDeleteTags } from './orchestrations/orchestrations-delete-tags';
import { OrchestrationsManageOnboarding } from './orchestrations/orchestrations-manage-onboarding/orchestrations-manage-onboarding';
import { OrchestrationsManageUsers } from './orchestrations/orchestrations-manage-users/orchestrations-manage-users';
import { TatsuSideNavContent } from './side-nav';
import { UnderConstruction } from './under-construction';

export const FullTatsu = () => {
  const { user, logout } = useAuth0();
  const theme = useTheme();
  const { setContent } = useNavBarMenu();
  const { setColor, setProductTitle } = useNavBar();

  // Move side effects to useEffect
  useEffect(() => {
    if (!user || !user.email?.endsWith('@prism.bio')) {
      // kick em out if they aren't Prism ppl
      logout({ logoutParams: { returnTo: window.location.origin } });
    }

    setColor(theme.palette.red);
    setProductTitle('Admin');

    setContent(
      <SideNavLayout>
        <TatsuSideNavContent />
      </SideNavLayout>
    );
  }, [user, logout, setColor, setProductTitle, setContent, theme.palette.red]);

  // #region search param management
  const [dynamicComponent, setDynamicComponent] = useState<JSX.Element | null>(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const componentMap = {
      [FnTypes.Investigation as string]: {
        [TatsuInvestigations.Inquire as string]: <InvestigationsInquire />,
        [TatsuInvestigations.Pulse as string]: <InvestigationsPulse />
      },
      [FnTypes.Orchestration as string]: {
        [TatsuOrchestrations.ManageUsers as string]: <OrchestrationsManageUsers />,
        [TatsuOrchestrations.DeleteTags as string]: <OrchestrationsDeleteTags />,
        [TatsuOrchestrations.ManageOnboarding as string]: <OrchestrationsManageOnboarding />
      }
    };

    const chosenType = searchParams.get(QueryParams.Type);
    const chosenFn = searchParams.get(QueryParams.Function);

    const mappedComponent = chosenType && chosenFn && componentMap?.[chosenType]?.[chosenFn];

    if (mappedComponent) {
      setDynamicComponent(mappedComponent);
    } else if (!chosenType || !chosenFn) {
      setDynamicComponent(null);
    } else {
      setDynamicComponent(<UnderConstruction name={chosenFn} />);
    }
  }, [searchParams]);
  // #endregion search param management

  return (
    <StaticTwoPanelLayout
      sideNavChildren={<TatsuSideNavContent />}
      middlePanelChildren={
        <MainBodyLayout>
          <ResponsiveBody isChatting={false}>
            <Box
              sx={{
                borderTopLeftRadius: theme.borderRadius,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.default,
                zIndex: 1
              }}
            >
              {dynamicComponent ? (
                dynamicComponent
              ) : (
                <Box sx={{ marginBottom: theme.spacing(25) }}>
                  <PrismLogoLoader style={{ fontSize: '400px' }} />
                </Box>
              )}
            </Box>
          </ResponsiveBody>
        </MainBodyLayout>
      }
    ></StaticTwoPanelLayout>
  );
};
