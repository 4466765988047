import { CustomMarkdownRenderer } from '@/components/custom-markdown-renderer';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

interface StepData {
  title: string;
  content: string;
  position: string;
}

interface EditStepDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  stepData: StepData;
  setStepData: (stepData: StepData) => void;
}

export const EditStepDialog: React.FC<EditStepDialogProps> = ({ open, onClose, onSave, stepData, setStepData }) => {
  const theme = useTheme();

  const { title, content, position } = stepData;

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setStepData({ ...stepData, title: e.target.value });
  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setStepData({ ...stepData, content: e.target.value });
  const handlePositionChange = (e: React.ChangeEvent<{ value: unknown }>) =>
    setStepData({ ...stepData, position: e.target.value as string });

  // #region shared styles
  const textInputProps = {
    disableUnderline: true,
    style: {
      backgroundColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: `1px solid ${theme.palette.text.primary}`
    }
  };
  const textInputLabelProps = {
    shrink: true,
    style: {
      transition: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
      transform: 'translate(0, -6px) scale(0.75)',
      ...theme.typography.h6
    }
  };
  const textSx = {
    mb: theme.spacing(1),
    mt: theme.spacing(1),
    color: theme.palette.text.primary,
    '& .MuiInputBase-inputMultiline': {
      color: theme.palette.text.primary,
      resize: 'vertical'
    },
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&:hover:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&.Mui-focused:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&.Mui-focused:after': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      color: theme.palette.text.primary,
      backgroundColor: 'transparent'
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      '&.Mui-focused': {
        color: theme.palette.text.primary
      }
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.text.primary
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1
    }
  };
  // #endregion shared styles

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Step</DialogTitle>
      <DialogContent sx={{ pb: theme.spacing(3), height: '95vh', width: '700px' }}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                autoFocus
                id="title"
                label="Title"
                type="text"
                variant="filled"
                fullWidth
                value={title}
                onChange={handleTitleChange}
                InputProps={textInputProps}
                InputLabelProps={textInputLabelProps}
                sx={textSx}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label="Position"
                value={position}
                onChange={handlePositionChange}
                variant="filled"
                fullWidth
                InputProps={textInputProps}
                InputLabelProps={textInputLabelProps}
                sx={textSx}
              >
                {['center', 'left', 'right'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="content"
              label="Content"
              type="text"
              variant="filled"
              fullWidth
              multiline
              value={content}
              onChange={handleContentChange}
              InputProps={textInputProps}
              InputLabelProps={textInputLabelProps}
              sx={textSx}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: theme.spacing(5), display: 'flex', justifyContent: 'center' }}>
            <Box border={1} borderColor="grey.500" p={2} height="100%" sx={{ maxWidth: '500px', width: '100%' }}>
              <Typography variant="h3" sx={{ p: theme.spacing(5), textAlign: 'center', fontSize: 36 }}>
                {title}
              </Typography>
              <Box sx={{ color: theme.palette.gray.lightest }}>
                <CustomMarkdownRenderer content={content} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            background: theme.palette.background.paper,
            pb: theme.spacing(3),
            pl: theme.spacing(6),
            pr: theme.spacing(6),
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000
          }}
        >
          <Button variant="clean-creative" onClick={onSave}>
            Update
          </Button>
          <Button variant="destructive-light" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
