import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { LibraryProject } from '@/types/projects';
import { getBrandedColorFromString } from '@/utils/color-utils';
import { Settings } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Chip, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DocumentCollection, DocumentTag, FileMetadata } from '../../types';

interface SearchInputProps {
  placeholder: string;
  onRunSearch: (query: string) => void;
  isFiltered: boolean;
  setIsFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  isGrouped: boolean;
  setIsGrouped: React.Dispatch<React.SetStateAction<boolean>>;
  library: LibraryProject;
  collection?: DocumentCollection;
  results: FileMetadata[];
  resultsLoading: boolean;
  disabled?: boolean;
  initialQuery: string | null;
  isLoading: boolean;
}

/**
 * Search Box with group & filter capabilities
 */
export const SearchInput = ({
  placeholder,
  onRunSearch,
  isFiltered,
  setIsFiltered,
  isGrouped,
  setIsGrouped,
  library,
  collection,
  results,
  resultsLoading,
  disabled,
  initialQuery,
  isLoading
}: SearchInputProps): JSX.Element => {
  const theme = useTheme();
  // #region state
  const [searchParams, setSearchParams] = useSearchParams(); // get current search params (to show preserved search in text box on refresh)
  const isShowingSearch = searchParams.get('isSearchActive') === 'true';
  const [input, setInput] = useState(initialQuery || '');
  const [searchQuery, setSearchQuery] = useState(input);
  const [tags, setTags] = useState<DocumentTag[]>([]);
  const [tagsToFilterBy, setTagsToFilterBy] = useState<DocumentTag[]>([]);
  const [hasSyncedTags, setHasSyncedTags] = useState<boolean>(false);
  const [showGroupAndFilter, setShowGroupAndFilter] = useState(isGrouped || isFiltered);
  // #endregion state

  // #region API handlers
  const { getData: getTagsForLibrary } = useGetApiViaCallback<DocumentTag[]>(
    `knowledge-finder/document/documentTags/${library.uuid}`
  );
  // #endregion API handlers

  // Search description
  const helperText = resultsLoading
    ? ''
    : `Returning ${results.length} ${isGrouped ? 'document' : 'document snippet'}${results.length === 1 ? '' : 's'} in ${collection ? collection.name : library.name} ${isFiltered ? 'with filters applied' : ''}`;

  // #region useEffect
  useEffect(() => {
    // Fetch the document tags for autocomplete for a specific library (if libraryId is provided)
    // and set the tags to filter by based on the URL parameters
    const getTags = async () => {
      const foundTags = await getTagsForLibrary();
      setTags(foundTags);
      // Get the tags from the URL
      const urlTags = searchParams.get('tags');
      if (urlTags) {
        // Split the tags by comma and find the corresponding tag objects
        const tagIds = urlTags.split(',');
        const selectedTags = foundTags.filter((tag) => tagIds.includes(tag.uuid));
        setTagsToFilterBy(selectedTags);
      }
      setHasSyncedTags(true);
    };
    if (library.uuid) {
      getTags();
    }
  }, [getTagsForLibrary, library, searchParams, setIsFiltered]);

  useEffect(() => {
    // Update the input to match the initial search query whenever it changes
    setInput(initialQuery || '');
  }, [initialQuery]);

  useEffect(() => {
    // Establish initial filter state based on whether a search is currently active or not
    if (!isShowingSearch) {
      setShowGroupAndFilter(false);
      setSearchQuery('');
      setIsFiltered(false);
      setIsGrouped(false);
    }
  }, [isShowingSearch, searchQuery, setIsFiltered, setIsGrouped]);

  useEffect(() => {
    // Update the 'tags' URL parameter based on the current tags to filter by
    if (!hasSyncedTags) return;
    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev.toString());

        if (tagsToFilterBy.length) {
          const tagIds = tagsToFilterBy.map((tag) => tag.uuid).join(',');
          params.set('tags', tagIds);
        } else {
          params.delete('tags');
        }

        return params;
      },
      { replace: true }
    );
  }, [hasSyncedTags, isFiltered, tagsToFilterBy, setSearchParams]);

  // #endregion useEffect

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" width="100%">
          <TextField
            fullWidth
            disabled={disabled}
            variant="standard"
            InputLabelProps={{
              shrink: false,
              sx: {
                ...theme.typography.h3,
                color: theme.palette.primary.contrastText,
                display: input ? 'none' : 'block', // hide the label text when there is a query
                '&.Mui-focused': {
                  display: 'none' // hide the label text when focused
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.contrastText
                },
                // fade in the text
                opacity: isLoading ? 0 : 1,
                transition: 'opacity 1200ms'
              }
            }}
            sx={{
              mb: theme.spacing(1),
              '& .MuiInput-underline:before': {
                borderBottomColor: theme.palette.primary.contrastText, // change color of underline
                lineHeight: 0 // this fixes the cutoff issue for the place holder label for windows. Do not remove.
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.contrastText // change color of underline when in focus
              },
              '& .MuiInputBase-input': {
                ...theme.typography.h3, // change the font of the input tex
                pb: theme.spacing(1)
              }
            }}
            label={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
            value={input}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                setSearchQuery(input);
                onRunSearch(input);
              }
            }}
          />
        </Box>
        {isShowingSearch && (
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              sx={{
                color:
                  showGroupAndFilter || isGrouped || isFiltered
                    ? theme.palette.primary.light
                    : theme.palette.primary.lightest,
                ':hover': { background: theme.palette.primary.lightest }
              }}
              onClick={() => setShowGroupAndFilter(!showGroupAndFilter)}
            >
              <Settings />
            </IconButton>
            <Typography sx={{ color: theme.palette.grey[500] }} variant="body1">
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
      {showGroupAndFilter && (
        <Box sx={{ pl: theme.spacing(4) }}>
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              onClick={() => setIsGrouped((prev) => !prev)}
              sx={{
                color: theme.palette.primary.lightest
              }}
            >
              <Checkbox
                sx={{
                  '&.Mui-checked': { color: theme.palette.primary.lightest }
                }}
                // on check --> this is the same as the old 'group by document setter'
                checked={isGrouped}
              />
            </IconButton>
            <Typography variant="subtitle2" sx={{ color: theme.palette.grey[500] }}>
              Group by document
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              onClick={() => setIsFiltered((prev) => !prev)}
              sx={{
                color: theme.palette.primary.lightest
              }}
            >
              <Checkbox
                sx={{
                  '&.Mui-checked': { color: theme.palette.primary.lightest }
                }}
                checked={isFiltered}
              />
            </IconButton>
            <Typography variant="subtitle2" sx={{ color: theme.palette.grey[500] }}>
              Apply Filters
            </Typography>
          </Box>
          <Autocomplete
            disabled={!isFiltered}
            multiple
            id="tags-filled"
            options={(tags ?? []).filter(
              (tag) => !tagsToFilterBy?.some((selectedTag) => selectedTag.name === tag.name)
            )}
            freeSolo
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            value={tagsToFilterBy ?? []}
            sx={{
              '.MuiAutocomplete-tag': {
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden'
              },
              '.MuiAutocomplete-clearIndicator': {
                color: theme.palette.primary.contrastText,
                fontSize: theme.typography.body1.fontSize,
                ': hover': {
                  color: theme.palette.primary.contrastText,
                  background: 'transparent'
                }
              }
            }}
            onChange={(_event, newValue) => {
              setTagsToFilterBy(newValue.filter((value): value is DocumentTag => typeof value !== 'string'));
            }}
            renderTags={(value, getTagProps) =>
              value.map((tag, index) => (
                <Chip
                  variant="filled"
                  size="small"
                  style={{
                    backgroundColor: theme.palette[getBrandedColorFromString(tag.name)].main,
                    fontFamily: theme.typography.body2.fontFamily,
                    marginBottom: theme.spacing(1)
                  }}
                  label={tag.name}
                  {...getTagProps({ index })}
                  key={tag.uuid} // Use the tag's uuid as the key
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                placeholder={
                  tagsToFilterBy.length ? ' type to search and add more filters' : 'type to search and add more filters'
                }
                {...params}
                fullWidth
                multiline
                rows={1}
                sx={{
                  zIndex: 1,
                  width: 'auto', // let it adjust based on content and available space
                  ml: theme.spacing(1),
                  minWidth: '100%',
                  maxWidth: {
                    xs: '90%'
                  },
                  '& label': { color: theme.palette.primary.contrastText },
                  '& label.Mui-focused': {
                    color: theme.palette.primary.contrastText
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme.palette.primary.contrastText
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: theme.palette.primary.contrastText
                  },
                  '&:hover .MuiInput-underline:before': {
                    borderBottomColor: theme.palette.primary.contrastText
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: 14,
                    lineHeight: '1.2'
                  }
                }}
                variant="standard"
              />
            )}
          />
        </Box>
      )}
    </>
  );
};
