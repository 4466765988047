import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Fade, Typography, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCollectionContext } from '../../stores/collection-context';
import { useDocumentContext } from '../../stores/document-context';
import { useLibraryContext } from '../../stores/library-context';
import { useSearchContext } from '../../stores/search-context';
import { FileListResult, FileMetadata } from '../../types';
import { SearchInput } from '../search/search-input';
import { SearchResults } from '../search/search-results';
import { TableView } from '../table-view';
import { ToggleViewArea } from '../toggle-view';
import { CardView } from './card-view';
import { NoCollections, NoDocuments } from './empty-views';

/**
 * Browse documents in a whole library
 */
export const LibraryBrowse = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAuth0();
  const { projectId, collectionId } = useParams<{ projectId: string; collectionId: string }>();
  const { library } = useLibraryContext();
  const { collection, handleAddCollection } = useCollectionContext();
  const { documents, setDocuments } = useDocumentContext();
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const { searchResults, setSearchQuery, setSearchResults } = useSearchContext();

  // if collection isn't but we have a collectionId, we're loading
  const [isCollectionLoading, setIsCollectionLoading] = useState(!collection && !!collectionId);

  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('search');
  const tagFilters = searchParams.get('tags');
  const isGroupedOnLoad = searchParams.get('isGrouped') === 'true';
  const isFilteredOnLoad = searchParams.get('isFiltered') === 'true';
  const isShowingSearch = searchParams.get('isSearchActive') === 'true';

  // #region vars
  // pull browse state from local storage to set initial grid state
  const browseState = JSON.parse(localStorage.getItem(`${user?.sub}-kf-${projectId}-browseState`) || 'false');
  // documents
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [isInTableView, setTableView] = useState(!!browseState);
  // search
  const [isGrouped, setIsGrouped] = useState(isGroupedOnLoad);
  const [isFiltered, setIsFiltered] = useState(isFilteredOnLoad);
  const [resultsLoading, setResultsLoading] = useState(true);
  // #endregion vars

  // #region API handlers
  // GET
  const { getData: getDocumentsForLibrary } = useGetApiViaCallback<FileListResult>(
    `knowledge-finder/library/${library.uuid}/documents`
  );
  const { getData: getDocumentsForCollection } = useGetApiViaCallback<FileMetadata[]>(
    `knowledge-finder/document/${library.uuid}/${collection?.uuid}/documents`
  );
  // POST
  const { postData: postSearchLibrary } = usePostApi<FileMetadata[]>(`knowledge-finder/library/semantic-search`);
  const { postData: postSearchCollection } = usePostApi<FileMetadata[]>(`knowledge-finder/collection/semantic-search`);
  // #endregion API handlers

  // #region useeffect

  // update search params when isGrouped changes
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, isGrouped: isGrouped.toString() }, { replace: true });
  }, [isGrouped, searchParams, setSearchParams]);

  // update search params when isFiltered changes
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, isFiltered: isFiltered.toString() }, { replace: true });
  }, [isFiltered, searchParams, setSearchParams]);

  //
  useEffect(() => {
    // if we have a collectionId but no collection, we're out of sync, still loading
    if ((collectionId && !collection) || collectionId !== collection?.uuid) {
      setIsCollectionLoading(true);
    } else {
      // we're not in a collection or if we have a collection & it matches the current collection id
      setIsCollectionLoading(false);
    }
  }, [collectionId, collection]);

  // load documents or search results
  useEffect(() => {
    setLoadingDocuments(true);
    const getAllDocuments = async () => {
      try {
        if (collection && !isCollectionLoading) {
          // get collection scoped docs
          const documentResults = await getDocumentsForCollection();
          setDocuments(documentResults);
        } else if (!isCollectionLoading) {
          // we're in a library & we're not waiting for a collection to load
          // fetch latest for the library
          const documentResults = await getDocumentsForLibrary();
          setDocuments(documentResults.items);
          setDocumentsCount(documentResults.total);
        }
      } finally {
        setLoadingDocuments(false);
      }
    };

    const debouncedSearch = debounce(
      async (query: string, isGrouped: boolean, isFiltered: boolean, tagFilters: string | null) => {
        try {
          setResultsLoading(true);
          if (!collection && !isCollectionLoading) {
            // library-wide search
            const searchResults = await postSearchLibrary({
              query,
              projectId: library.uuid,
              groupByDocument: isGrouped,
              // only apply tags if apply is checked & filters are present
              tagIds: tagFilters && isFiltered ? tagFilters.split(',') : []
            });
            setSearchResults(searchResults);
          } else if (collection && !isCollectionLoading) {
            // collection-scoped search
            const searchResults = await postSearchCollection({
              query,
              collectionId: collection.uuid,
              projectId: library.uuid,
              groupByDocument: isGrouped,
              // only apply tags if apply is checked & filters are present
              tagIds: tagFilters && isFiltered ? tagFilters.split(',') : []
            });
            setSearchResults(searchResults);
          }
        } finally {
          setResultsLoading(false);
        }
      },
      150
    ); // Debounce time

    if (query) {
      setSearchQuery(query);
      debouncedSearch(query, isGrouped, isFiltered, tagFilters);
    } else {
      setSearchResults([]);
      // reset search params if no query
      setSearchParams({}, { replace: true });
      getAllDocuments();
    }

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => debouncedSearch.cancel();
  }, [
    query,
    isGrouped,
    isFiltered,
    tagFilters,
    getDocumentsForCollection,
    getDocumentsForLibrary,
    collection,
    postSearchLibrary,
    library,
    postSearchCollection,
    setSearchParams,
    setDocuments,
    setDocumentsCount,
    setSearchResults,
    setSearchQuery,
    collectionId,
    isCollectionLoading
  ]);
  // #endregion useeffect

  const searchMessage =
    documentsCount > 0
      ? `Search the ${documentsCount} document${documentsCount === 1 ? '' : 's'} in this ${collection ? 'collection' : 'library'}`
      : 'Nothing to search yet';

  return (
    <Box sx={{ m: theme.spacing(7), mt: theme.spacing(2) }}>
      <SearchInput
        isLoading={loadingDocuments || isCollectionLoading}
        disabled={!query && documentsCount <= 0}
        placeholder={searchMessage}
        onRunSearch={(query) => {
          if (query === '') {
            setSearchParams({}, { replace: true });
          } else {
            const baseParams = { search: query, isSearchActive: 'true' };
            setSearchParams(tagFilters ? { ...baseParams, tags: tagFilters } : baseParams, {
              replace: true
            });
          }
        }}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
        isGrouped={isGrouped}
        setIsGrouped={setIsGrouped}
        library={library}
        collection={collection}
        results={searchResults}
        resultsLoading={resultsLoading}
        initialQuery={query}
      />
      {isShowingSearch ? (
        <SearchResults resultsLoading={resultsLoading} results={searchResults} theme={theme} isGrouped={isGrouped} />
      ) : (
        <Box>
          <Box width={350}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.gray.lighter }}
              title={isCollectionLoading ? '' : collection ? collection.description : library.description}
            >
              {isCollectionLoading
                ? ''
                : collection?.description
                  ? collection.description.length > 250
                    ? `${collection.description?.substring(0, 250)}...`
                    : collection.description
                  : library.description
                    ? library.description.length > 250
                      ? `${library.description.substring(0, 250)}...`
                      : library.description
                    : ''}
            </Typography>
          </Box>

          {documentsCount > 0 && !isCollectionLoading && (
            <Box
              sx={{
                mb: collection ? theme.spacing(3) : theme.spacing(20),
                mt: collection ? theme.spacing(20) : 0
              }}
            >
              <Fade in={documentsCount > 0}>
                <Box>
                  {collection && <Typography variant="h3">Browse</Typography>}
                  <ToggleViewArea
                    isInTableView={isInTableView}
                    onTableViewClicked={(t: boolean) => {
                      localStorage.setItem(`${user?.sub}-kf-${projectId}-browseState`, `${t}`);
                      setTableView(t);
                    }}
                  />
                </Box>
              </Fade>
            </Box>
          )}

          {isCollectionLoading || loadingDocuments ? (
            <Box display="flex" justifyContent="center" alignContent="center" mt={theme.spacing(20)}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {documentsCount <= 0 ? (
                <Fade in={documentsCount <= 0}>
                  <Box>
                    {collection ? (
                      <NoCollections theme={theme} projectId={library.uuid} collectionId={collection.uuid} />
                    ) : (
                      <NoDocuments theme={theme} handleAddCollection={handleAddCollection} />
                    )}
                  </Box>
                </Fade>
              ) : (
                <Fade in={documentsCount > 0}>
                  <Box>
                    {isInTableView ? (
                      <TableView documents={documents ?? []} navigate={navigate} collection={collection} />
                    ) : (
                      <CardView documents={documents ?? []} onlyShowLatest={!collection} />
                    )}
                  </Box>
                </Fade>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
