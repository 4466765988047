import { LoginButton } from '@/features/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowForwardIos } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  ButtonBase,
  Dialog,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavBar, useNavBarMenu } from '../stores/navbar-context';
import { NavBarProfile } from './nav-bar-profile';

const DialogTransition = forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement;
  }
>((props, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const NavBar = () => {
  const trigger = useScrollTrigger();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  const { title, color, productTitle, parentBreadcrumb, childBreadcrumb } = useNavBar();
  const { content, drawerOpen, setDrawerOpen } = useNavBarMenu();

  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const colorInUse = color?.dark || theme.palette.background.default;
  const textColor = theme.palette.getContrastText(colorInUse);

  const breadcrumbs = [parentBreadcrumb, childBreadcrumb];

  return (
    <Slide appear={false} in={!trigger} direction="down">
      <AppBar sx={{ backgroundColor: colorInUse }} elevation={0}>
        <Toolbar id="nav-bar" sx={{ justifyContent: 'space-between' }}>
          <IconButton
            sx={{ display: { xs: 'block', md: 'none' } }}
            color="inherit"
            edge="start"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon sx={{ color: textColor }} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <ButtonBase sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/v2/knowledge-finder')}>
              <Box>
                <Typography
                  sx={{
                    color: textColor,
                    textDecoration: 'none',
                    fontFamily: 'Aeonik',
                    fontSize: 24,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal'
                  }}
                >
                  {productTitle !== 'Knowledge Finder' ? (
                    <>
                      <span style={{ color: textColor }}>✦ Prism</span>
                      <span style={{ color: textColor }}>{` ${productTitle}`}</span>
                    </>
                  ) : (
                    '✦ Knowledge Finder'
                  )}
                </Typography>
              </Box>
            </ButtonBase>
            {breadcrumbs
              .filter((breadcrumb): breadcrumb is { name: string; route: string } => breadcrumb !== undefined)
              .map((breadcrumb, index) => (
                <React.Fragment key={index}>
                  <ArrowForwardIos sx={{ color: theme.palette.text.secondary }} />
                  <ButtonBase sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => navigate(breadcrumb.route)}>
                    <Box>
                      <Typography
                        sx={{
                          color: textColor,
                          textDecoration: 'none',
                          fontFamily: 'Aeonik',
                          fontSize: 24,
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          maxWidth: '300px', // Set a maximum width
                          whiteSpace: 'nowrap', // Keep text on a single line
                          overflow: 'hidden', // Hide overflow
                          textOverflow: 'ellipsis' // Add ellipsis for overflow
                        }}
                        title={breadcrumb.name} // Show full name on hover
                      >
                        {breadcrumb.name}
                      </Typography>
                    </Box>
                  </ButtonBase>
                </React.Fragment>
              ))}
          </Box>
          {title ? (
            <Typography sx={{ fontFamily: 'Canela Text', mr: 1, fontSize: isMdScreen ? 14 : 18 }} variant="body1">
              {title.split(':').map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: textColor
                  }}
                >
                  {part}
                  {index === 0 && ':'}
                </span>
              ))}
            </Typography>
          ) : isLoading ? (
            <Typography color="primary" variant="h6">
              Loading...
            </Typography>
          ) : isAuthenticated ? (
            <NavBarProfile />
          ) : (
            <LoginButton />
          )}
        </Toolbar>
        {isMdScreen && (
          <Dialog
            TransitionComponent={DialogTransition}
            hideBackdrop={true}
            sx={{
              '& .MuiDialog-paper': {
                backgroundColor: theme.palette.background.default,
                borderRadius: 0,
                margin: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0, // Locked to the left edge
                minWidth: { xs: '75%', sm: '300px', md: '300px' },
                maxWidth: {
                  xs: '100%', // take full width on extra small screens
                  sm: '50%', // take 50% of the width on small screens
                  md: '50%' // take 50% on medium screens and above
                },
                height: '100vh',
                maxHeight: '100vh',
                scrollbarWidth: 'none',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }
            }}
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            {content}
          </Dialog>
        )}
      </AppBar>
    </Slide>
  );
};
