export enum FeatureToggleExperiences {
  Onboarding = 'onboarding',
  /**
   * when configured, should map to a particular route declared in the frontend
   * to default to when the user isn't trying to route anywhere specific and
   * is hitting the default landing route
   */
  PreferredLanding = 'preferredLanding'
}

export type FeatureToggleExperienceSettings = {
  [FeatureToggleExperiences.PreferredLanding]?: string;
  [FeatureToggleExperiences.Onboarding]?: {
    enabled: boolean;
    screens: { [key: string]: boolean };
    configuration: {
      [key: string]: {
        id: string;
        route: string;
        dependsOnHavingSeen?: string[];
        steps: { title: string; content: string; position: string }[];
      };
    };
  };
};
