import { useNavBar } from '@/features/navbar';
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../components/page';

export default function VerificationPendingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setColor, setProductTitle } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.black);
    setProductTitle(' ');
  }, [setColor, setProductTitle, theme.palette.black]);

  return (
    <Page>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          maxWidth: 500,
          minHeight: 300,
          m: 'auto',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box sx={{ mt: theme.spacing(7), textAlign: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Verification Pending
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: theme.spacing(3) }}>
            Your account verification is pending. Please check your email inbox for the verification link. If you cannot
            find the email, or if you encounter any issues, please contact{' '}
            <a href="mailto:support@prism.bio" style={{ color: theme.palette.success.light }}>
              <b>support@prism.bio</b>
            </a>{' '}
            for assistance.
          </Typography>
        </Box>
      </Card>
      <Box sx={{ p: theme.spacing(2), textAlign: 'center' }}>
        <Button variant="clean-creative" onClick={() => navigate(`/pub/login`)}>
          <Typography>Back to Login</Typography>
        </Button>
      </Box>
    </Page>
  );
}
