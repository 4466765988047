import { ChatGPTMessage } from '@/features/chatbot';
import { Document, Packer, Paragraph, SectionType, TextRun } from 'docx';

export const downloadConversation = async ({
  messages,
  apiHandler,
  libraryId,
  collectionId,
  documentId
}: {
  messages: ChatGPTMessage[];
  apiHandler: (data: object) => Promise<unknown[]>;
  libraryId: string;
  collectionId?: string;
  documentId?: string;
}) => {
  await apiHandler({ messages: messages.slice(1), libraryId, collectionId, documentId });

  const content: (Paragraph | TextRun | (Paragraph | TextRun)[])[] = [];

  messages.slice(1).forEach((message) => {
    const splitMessages = message.content.split('\n');
    console.log('split messages: ', splitMessages);
    splitMessages.forEach((splitMessage, splitIndex) => {
      if (!splitMessage) return;
      if (splitIndex > 0) {
        content.push(
          new Paragraph({
            children: [new TextRun(splitMessage)]
          })
        );
      } else {
        content.push(
          new Paragraph({
            children: [
              new TextRun(
                `${splitIndex === 0 && message.role === 'user' ? 'User: ' : '✦ Knowledge Finder Agent: '}${splitMessage}`
              )
            ]
          })
        );
      }
      content.push(
        new Paragraph({
          children: [new TextRun({ break: 1 })]
        })
      );
    });
  });

  const doc = new Document({
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS
        },
        // @ts-expect-error types do work
        children: content.flat()
      }
    ]
  });

  const blob = await Packer.toBlob(doc);
  const now = new Date();
  const filename = `PrismKnowledgeFinder_Conversation-${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}-${now.getHours()}${now.getMinutes()}.docx`;

  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
};
