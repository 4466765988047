import { createParser, ParsedEvent, ReconnectInterval } from 'eventsource-parser';
import { TOOL_DETECTION_STRING } from '../../knowledge-finder-v2/types/constants';

export type ChatGPTAgent = 'user' | 'system' | 'assistant' | 'function';

export interface ChatGPTMessage {
  role: ChatGPTAgent;
  content: string;
}

export interface OpenAIPayload {
  model?: string;
  messages: ChatGPTMessage[];
  temperature: number;
  top_p: number;
  frequency_penalty?: number;
  presence_penalty?: number;
  max_tokens: number;
  stream: boolean;
  stop?: string[];
  user?: string;
  n?: number;
  functions?: object[];
}

export enum AgentToInteractWith {
  KnowledgeFinderChat = '/knowledge-finder/conversation/chat/stream',
  KnowledgeFinderGetPrompt = '/knowledge-finder/conversation/actions/getPrompt',
  VaultPublicChatWithOneArticle = '/vault-article-nyi',
  VaultPublicChatWithSearchResults = '/vault-search-nyi'
}

async function* streamAsyncIterator(stream: ReadableStream<Uint8Array>) {
  const reader = stream.getReader();

  try {
    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        break;
      }

      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

export async function PrismAgentStream({
  payload,
  authToken,
  projectId,
  agent,
  query,
  searchResults,
  extras
}: {
  payload: OpenAIPayload;
  authToken?: string;
  projectId?: string;
  agent: AgentToInteractWith;
  query?: string;
  searchResults?: string;
  extras?: object;
}) {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  let counter = 0;

  const requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };

  let res: Response | undefined;
  try {
    res = await fetch(
      `${import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'}${agent}`,
      {
        headers: requestHeaders,
        method: 'POST',
        body: JSON.stringify({
          ...payload,
          projectId: projectId,
          query,
          searchResults,
          ...extras
        })
      }
    );
    if (!res.ok) {
      if (res.status === 422) {
        throw new Error(`Server returned status 422: ${await res.text()}`);
      }
      throw new Error(`HTTP error! status: ${res.status}`);
    }
  } catch (error) {
    console.error('Network error:', error);
    // Handle network error as needed
    throw error;
  }

  let stream;
  try {
    stream = new ReadableStream({
      async start(controller) {
        const eventQueue: Uint8Array[] = []; // Queue to hold events
        let concatenatedData = ''; // To hold concatenated data for checking "<tool"
        let toolUseDetected = false; // Flag to indicate if tool use has been detected

        async function onParse(event: ParsedEvent | ReconnectInterval) {
          console.log('event here:', event);
          if (event.type === 'event') {
            const data = event.data;

            if (counter < 2 && (data.match(/\n/) || []).length) {
              // this is a prefix character (i.e., "\n\n"), do nothing
              return;
            }

            if (data === '[DONE]') {
              if (toolUseDetected) {
                // If tool use was detected, send the concatenatedData (minus "[DONE]")
                controller.enqueue(encoder.encode(concatenatedData));
                console.log('closing controller after reaching end of tool use');
                controller.close();
              } else {
                // If tool use was not detected, send all remaining events in the queue
                eventQueue.forEach((queue) => controller.enqueue(queue));
                console.log('closing controller after reaching end of markdown stream');
                controller.close();
              }
              return;
            }

            concatenatedData += data; // Always concatenate data
            counter++;

            if (!toolUseDetected) {
              if (concatenatedData.includes('<tool')) {
                // If "<tool" is detected, set the flag and clear the queue
                toolUseDetected = true;
                eventQueue.length = 0; // Clear the queue
                controller.enqueue(encoder.encode(TOOL_DETECTION_STRING));
              } else {
                // If "<tool" is not detected, continue as normal
                const queue = encoder.encode(data);
                eventQueue.push(queue); // Add new event to the queue
                counter++;

                // If the queue size is greater than the offset, dequeue and send an event
                if (eventQueue.length > 2) {
                  // Assuming the offset is 2
                  const toSend = eventQueue.shift(); // Remove the oldest event
                  if (toSend) {
                    controller.enqueue(toSend); // Send the dequeued event
                  }
                }
              }
            }
          }
        }

        // Stream response (SSE) from OpenAI may be fragmented into multiple chunks
        // This ensures we properly read chunks and invoke an event for each SSE event stream
        const parser = createParser(onParse);

        // Custom async iterator using the function we defined above
        if (res?.body) {
          for await (const chunk of streamAsyncIterator(res.body)) {
            parser.feed(decoder.decode(chunk));
          }
        }
      }
    });
  } catch (error) {
    console.error('Stream error:', error);
    // Handle stream error as needed
    throw error;
  }

  return stream;
}

export async function PrismAgentSync({
  payload,
  authToken,
  projectId,
  agent,
  searchResults,
  query,
  extras
}: {
  payload: OpenAIPayload;
  authToken?: string;
  projectId: string;
  agent: AgentToInteractWith;
  searchResults?: string;
  query?: string;
  extras?: object;
}) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'}${agent}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          projectId,
          ...payload,
          searchResults,
          query,
          ...extras
        })
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
}

export async function PrismAgentPrompt({
  payload,
  authToken,
  projectId,
  agent,
  searchResults,
  query,
  extras
}: {
  payload: OpenAIPayload;
  authToken?: string;
  projectId: string;
  agent: AgentToInteractWith;
  searchResults?: string;
  query?: string;
  extras?: object;
}) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'}${agent}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          projectId,
          ...payload,
          searchResults,
          query,
          ...extras
        })
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
}
