import { PrismBaseEntity } from './base';

export enum UserPermission {
  OWNER = 'owner',
  CONTRIBUTOR = 'contributor',
  VIEWER = 'viewer'
}

export interface User extends PrismBaseEntity {
  email: string;
  firstName: string;
  lastName?: string;
  toggles?: { [objectKey: string]: unknown };
}
