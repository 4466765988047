import { usePostApi } from '@/hooks/use-post-api';
import { Biotech } from '@mui/icons-material';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { jsonTreeTheme } from '../../types/styling';

export const InvestigationsInquire = () => {
  const theme = useTheme();

  // #region entity + ID input management
  const entities = [
    { value: 'Project', name: 'Project' },
    { value: 'DocumentCollection', name: 'Document Collection' },
    { value: 'Document', name: 'Document' }
  ];
  const [entity, setEntity] = useState<string>('');
  const [id, setId] = useState<string>('');
  const handleEntityChange = (event: SelectChangeEvent<string>) => {
    setEntity(event.target.value as string);
  };
  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value);
  };
  // #endregion entity + ID input management

  // #region API management
  const [jsonData, setJsonData] = useState<object | null>(null);
  const { postData } = usePostApi<object>('tatsu/investigations/inquire');
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const foundEntity = await postData({
      entity,
      id
    });
    setJsonData(foundEntity);
  };
  // #region API management

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(5),
        left: 0,
        right: 0,
        pl: theme.spacing(5),
        pr: theme.spacing(5)
      }}
    >
      <Typography variant="h4" sx={{ color: 'black', fontWeight: '400' }}>
        Inquire
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], mb: theme.spacing(5) }}>
        get details about a particular entity
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="entity-label">ENTITY</InputLabel>
            <Select
              labelId="entity-label"
              id="entity-select"
              value={entity}
              label="ENTITY"
              onChange={handleEntityChange}
            >
              {entities.map((entityItem) => (
                <MenuItem key={entityItem.value} value={entityItem.value}>
                  {entityItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="id-input"
            label="ID"
            variant="outlined"
            fullWidth
            value={id}
            onChange={handleIdChange}
            inputProps={{ style: { width: '100%' } }} // Ensures the input stretches
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <IconButton
            sx={{
              '&:hover': {
                // Override the background change on hover for the IconButton here
                backgroundColor: 'transparent', // Or any other color you want
                // You can also disable the ripple effect if you want
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              }
            }}
            onClick={() => handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>)}
          >
            <Biotech
              sx={{
                backgroundColor: theme.palette.green.light,
                color: theme.palette.green.main,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                width: '100px',
                height: '55px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)'
                }
              }}
              fontSize="large"
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {jsonData && (
            <Box>
              <JSONTree data={jsonData} theme={jsonTreeTheme} invertTheme={false} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
