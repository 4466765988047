import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

interface AuthBoundaryProps {
  children: React.ReactNode;
}

export const AuthBoundary = ({ children }: AuthBoundaryProps) => {
  const { isAuthenticated, user } = useAuth0();

  // Function to scramble user.sub and format as a UUID-like string
  const scrambleUserSubToUUIDLike = (userSub: string) => {
    const subPart = userSub.substring(0, Math.min(userSub.length, 20));
    const encoded = btoa(subPart);
    const uuidLike = encoded
      .replace(/=+$/, '') // Remove any trailing '='
      .replace(/\+/g, '0') // Replace '+' and '/' with URL-safe characters
      .replace(/\//g, '0')
      .padEnd(32, '0')
      .substring(0, 32) // Ensure 32 characters
      .match(/.{1,8}/g)
      ?.join('-'); // Insert hyphens to mimic UUID format
    return uuidLike || '';
  };

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      // Set the user context in Sentry with a scrambled UUID-like user ID
      Sentry.setUser({
        id: scrambleUserSubToUUIDLike(user.sub)
      });
    } else {
      // Clear the user context when the user is not authenticated
      Sentry.setUser(null);
    }
  }, [isAuthenticated, user?.sub]);

  return <>{children}</>;
};

export default AuthBoundary;
