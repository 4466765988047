import { FileSearchResult } from '@/features/knowledge-finder-v2';
import { FileWithPreview } from '@/types/files';
import * as webllm from '@mlc-ai/web-llm';
import { ChatCompletion } from 'openai/resources/chat/completions';
import { useCallback, useEffect, useState } from 'react';
import {
  AgentToInteractWith,
  ChatGPTMessage,
  PrismAgentPrompt,
  PrismAgentStream,
  PrismAgentSync
} from '../features/chatbot/api/prism-agent';
import { useAuthToken } from './use-auth-token';
import useUploadCsv from './use-upload-csv';

interface Chunk {
  choices: Array<{
    delta: {
      content: string;
    };
  }>;
}

export type SendMessageParams = {
  agent: AgentToInteractWith;
  message: string;
  role?: string;
  setFiles?: (files: FileWithPreview[]) => void;
  files?: FileWithPreview[];
  query?: string;
  setResults?: (results: FileSearchResult[]) => void;
  results?: string;
  model?: string;
  vaultItem?: string;
  extras?: object; // any distinct properties that should be passed to the backend
};

export function useAgentChat({
  stream = false,
  projectId = '',
  chatId,
  welcomeMessage,
  localModelEngine
}: {
  stream?: boolean;
  projectId?: string;
  chatId?: string;
  welcomeMessage?: string;
  localModelEngine?: webllm.EngineInterface;
}) {
  const [loading, setLoading] = useState(false);
  const [thinking, setThinking] = useState(false);
  const [messages, setMessages] = useState([] as ChatGPTMessage[]);
  const [error, setError] = useState<Error>();
  const [usageExceeded, setUsageExceededError] = useState<string | null>(null); // New state for server-specific errors

  useEffect(() => {
    if (welcomeMessage && messages.length <= 1) {
      setMessages([{ role: 'assistant', content: welcomeMessage } as ChatGPTMessage]);
    }
  }, [messages.length, welcomeMessage]);

  const { upload } = useUploadCsv(projectId, chatId);

  const fetchToken = useAuthToken();

  const sendMessage = useCallback(
    async ({ agent, message, role, setFiles, files, results, model, query, extras }: SendMessageParams) => {
      setLoading(true);

      const oldMessages = messages.length >= 1 ? messages : [];
      const newMessages = [...oldMessages, { role: role ?? 'user', content: message } as ChatGPTMessage];
      setMessages(newMessages);
      try {
        let token;
        try {
          token = await fetchToken(true);
        } catch (err) {
          console.warn('attempting auth-less chat');
        }

        if (files && files?.length > 0) {
          const filesCopy = files.slice();
          setFiles?.([]);
          await upload(filesCopy);
        }

        const agentConfig = {
          payload: {
            model: model ?? import.meta.env.VITE_OPENAI_DEFAULT_MODEL ?? 'gpt-4-turbo-preview',
            messages: newMessages,
            temperature: 0.7,
            max_tokens: 1000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
            stream
          },
          authToken: token,
          projectId,
          agent,
          searchResults: results,
          query,
          extras
        };

        if (stream) {
          if (localModelEngine) {
            const prompt = await PrismAgentPrompt(agentConfig);
            console.log('prompt received here, will call local model: ', prompt);
            const response = (await localModelEngine.chatCompletion(
              prompt as webllm.ChatCompletionRequestStreaming
            )) as AsyncIterable<Chunk>;
            console.log('local model response here: ', response);
            await handleAsyncIterableStreamResponse(response, newMessages, setMessages);
          } else {
            const response = await PrismAgentStream(agentConfig);
            await handleStreamResponse(response, newMessages, setMessages);
          }
        } else {
          setThinking(true);
          const response = await PrismAgentSync(agentConfig);
          await handleSyncResponse(response, setMessages, setThinking);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('422')) {
          setUsageExceededError('Invalid input data. Please review your request.');
        } else {
          setError(err);
        }
      } finally {
        setLoading(false);
        setThinking(false);
      }
    },
    [messages, stream, fetchToken, upload, projectId, localModelEngine]
  );

  return {
    loading,
    thinking,
    messages,
    error,
    sendMessage,
    setMessages,
    usageExceeded,
    setUsageExceededError
  };
}

const handleAsyncIterableStreamResponse = async (
  asyncIterable: AsyncIterable<Chunk>, // Use the Chunk interface here
  newMessages: ChatGPTMessage[],
  setMessages: (arg0: ChatGPTMessage[]) => void
) => {
  let messageContent = '';
  try {
    for await (const chunk of asyncIterable) {
      console.log('chunk here from local model: ', chunk);
      if (chunk.choices[0].delta.content) {
        messageContent += chunk.choices[0].delta.content;
      }
      // Update the last message or add a new one if necessary
      if (newMessages.length > 0 && newMessages[newMessages.length - 1].role === 'assistant') {
        newMessages[newMessages.length - 1].content = messageContent;
      } else {
        newMessages.push({ role: 'assistant', content: messageContent });
      }
      setMessages([...newMessages]); // Update the state to reflect changes
    }
  } catch (error) {
    console.error('Error handling async iterable stream response:', error);
  }
};

const handleStreamResponse = async (
  stream: ReadableStream<Uint8Array>,
  newMessages: ChatGPTMessage[],
  setMessages: (arg0: ChatGPTMessage[]) => void
) => {
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  let done = false;
  let buffer = '';

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);
    buffer += chunkValue;

    let parsedValue;
    try {
      parsedValue = JSON.parse(buffer);
    } catch (error) {
      // Ignore SyntaxError as it likely means the function call or message is split across chunks.
      if (!(error instanceof SyntaxError)) {
        throw error;
      }
    }

    if (parsedValue) {
      newMessages.push(parsedValue);
      setMessages([...newMessages]);
      buffer = ''; // Clear buffer after processing function call or message.
    } else {
      if (newMessages.length > 0 && newMessages[newMessages.length - 1].role === 'assistant') {
        newMessages[newMessages.length - 1].content = buffer;
        setMessages([...newMessages]); // Update UI immediately.
      } else {
        newMessages.push({ role: 'assistant', content: buffer });
        setMessages([...newMessages]); // Update UI immediately.
      }
    }
  }
};

const handleSyncResponse = async (
  response: ChatCompletion | null,
  setMessages: React.Dispatch<React.SetStateAction<ChatGPTMessage[]>>,
  setThinking: (arg0: boolean) => void
) => {
  if (response && response.choices?.[0]?.message?.content?.length) {
    const cleanedText = response.choices[0].message.content.replace(/^\s+/gm, '');
    const words = cleanedText.split(' ');

    // Use a recursive function to handle the word-by-word message update
    const updateMessageWordByWord = (index = 0, currentText = '') => {
      if (index < words.length) {
        const word = words[index];
        currentText += word + ' ';

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          if (updatedMessages.length && updatedMessages[updatedMessages.length - 1].role === 'assistant') {
            updatedMessages[updatedMessages.length - 1].content = currentText;
          } else {
            updatedMessages.push({ role: 'assistant', content: currentText });
          }
          return updatedMessages;
        });

        setTimeout(() => updateMessageWordByWord(index + 1, currentText), 10);
      } else {
        // Once all words have been processed, reset the loading state
        setThinking(false);
      }
    };

    // Start the recursive update process
    updateMessageWordByWord();
  }
};
