import { SNACKBAR_HANG_TIME } from '@/types/constants';
import { ErrorBundaryTypes } from '@/types/errors';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Snackbar } from '@mui/material';
import { useContext, useState } from 'react';
import { ErrorContext } from './error-context';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const { error } = useContext(ErrorContext);

  const [openSessionExpiredSnackbar, setOpenSessionExpiredSnackbar] = useState(false);

  const { logout } = useAuth0();

  let dialogContent = null; // Initially, there's no dialog content

  if (error) {
    switch (error.type) {
      case ErrorBundaryTypes.SessionExpired:
        // Automatically open the Snackbar when the error occurs
        if (!openSessionExpiredSnackbar) {
          setOpenSessionExpiredSnackbar(true);
        }

        dialogContent = (
          <Snackbar
            open={openSessionExpiredSnackbar}
            autoHideDuration={SNACKBAR_HANG_TIME.SLOW}
            onClose={() => {
              setOpenSessionExpiredSnackbar(false);
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => {
                setOpenSessionExpiredSnackbar(false);
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
              severity="error"
              sx={{ fontFamily: 'Inter' }}
            >
              Redirecting to log in.
            </Alert>
          </Snackbar>
        );
        break;
      // You can add more cases in the future as needed
      default:
        break;
    }
  }

  return (
    <>
      {children}
      {dialogContent}
    </>
  );
};
