import { UserPermission } from '@/types/user';
import { Add, FileCopyOutlined, Settings } from '@mui/icons-material';
import { Box, IconButton, List, ListItemButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentCollection } from '../../types';

interface SideNavContentProps {
  libraryPermission?: UserPermission;
  privateCollections: DocumentCollection[];
  sharedCollections: DocumentCollection[];
  publicCollections: DocumentCollection[];
  onCollectionAdd: (isPublic: boolean) => Promise<void>;
  projectId: string;
  currentCollection?: DocumentCollection;
}

/**
 * Side nav showing all collections
 */
export const LibrarySideNav = ({
  libraryPermission,
  privateCollections,
  sharedCollections,
  publicCollections,
  onCollectionAdd,
  projectId,
  currentCollection
}: SideNavContentProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const canCreate = libraryPermission === UserPermission.OWNER || libraryPermission === UserPermission.CONTRIBUTOR;

  return (
    <Box>
      <Box
        sx={{
          mt: theme.spacing(3),
          mb: theme.spacing(3),
          width: '100%',
          height: 'calc(100% - 65px)',
          overflow: 'scroll',
          // hide scrollbar by default
          scrollbarWidth: 'none', // For Firefox
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Safari and Opera
          },
          '&-ms-overflow-style': 'none', // For Internet Explorer and Edge
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 50
        }}
      >
        <SideNavCollection
          onCollectionAdd={() => onCollectionAdd(true)}
          canCreate={canCreate}
          collections={publicCollections}
          libraryId={projectId}
          collectionType="Official"
          currentCollection={currentCollection}
        />
        <SideNavCollection
          collections={sharedCollections}
          libraryId={projectId}
          collectionType="Shared"
          currentCollection={currentCollection}
        />
        <SideNavCollection
          onCollectionAdd={() => onCollectionAdd(false)}
          canCreate={canCreate}
          collections={privateCollections}
          libraryId={projectId}
          collectionType="Personal"
          currentCollection={currentCollection}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: theme.spacing(1),
          width: '100%',
          m: theme.spacing(1),
          pt: theme.spacing(1),
          background: theme.palette.background.default
        }}
      >
        {libraryPermission === UserPermission.OWNER && (
          <Box
            onClick={() => {
              if (location.pathname === `/v2/knowledge-finder/${projectId}/settings`) {
                navigate(-1);
              } else {
                navigate(`/v2/knowledge-finder/${projectId}/settings`);
              }
            }}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="button"
              sx={{
                color:
                  location.pathname === `/v2/knowledge-finder/${projectId}/settings`
                    ? theme.palette.primary.light
                    : undefined,
                mr: theme.spacing(1)
              }}
            >
              Library
            </Typography>
            <IconButton
              size="small"
              disabled={libraryPermission !== UserPermission.OWNER}
              sx={{
                color:
                  location.pathname === `/v2/knowledge-finder/${projectId}/settings`
                    ? theme.palette.primary.light
                    : undefined,
                pointerEvents: 'none'
              }}
            >
              <Settings />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

/**
 * List buttons for a list of collections
 */
const SideNavCollection = ({
  collections,
  libraryId,
  canCreate,
  collectionType,
  onCollectionAdd,
  currentCollection
}: {
  collections: DocumentCollection[];
  libraryId: string;
  canCreate?: boolean;
  collectionType: 'Official' | 'Personal' | 'Shared';
  onCollectionAdd?: () => Promise<void>;
  currentCollection?: DocumentCollection;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [hoverId, setHoveredId] = useState<string | undefined>(undefined);
  return (
    <Box sx={{ mb: theme.spacing(2), mt: theme.spacing(2) }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ ml: theme.spacing(3), mr: theme.spacing(3), pt: theme.spacing(1), pb: theme.spacing(1) }}
      >
        <Typography variant="sideNavHeader">{collectionType} Collections</Typography>
        {canCreate && onCollectionAdd && (
          <IconButton
            onClick={onCollectionAdd}
            size="small"
            sx={{
              position: 'absolute',
              right: theme.spacing(2),
              background: theme.palette.primary.lightest,
              color: theme.palette.primary.main,
              ':hover': {
                background: theme.palette.primary.lightest,
                boxShadow: `0em 0em 0.4em 0em ${theme.palette.primary.lightest}`
              }
            }}
          >
            <Add />
          </IconButton>
        )}
      </Box>

      <List component="div" disablePadding sx={{}}>
        {collections.map((collection) => (
          <ListItemButton
            onMouseEnter={() => setHoveredId(collection.uuid)}
            onMouseLeave={() => setHoveredId(undefined)}
            key={collection.uuid}
            onClick={() => navigate(`/v2/knowledge-finder/${libraryId}/collections/${collection.uuid}`)}
            sx={{
              background:
                currentCollection?.uuid === collection.uuid
                  ? theme.palette.background.paper
                  : theme.palette.background.default,
              '&:hover': {
                background: theme.palette.background.paper,
                cursor: 'pointer',
                '& .MuiTypography-root': {
                  color: theme.palette.primary.medium
                }
              }
            }}
          >
            <Typography
              sx={{
                pl: theme.spacing(6),
                pr: theme.spacing(2),
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '250px',
                display: 'inline-block',
                verticalAlign: 'middle',
                color:
                  currentCollection?.uuid === collection.uuid
                    ? theme.palette.primary.medium
                    : theme.palette.text.secondary
              }}
              variant="chat"
            >
              {collection.name}
            </Typography>
            {
              // show upload and settings buttons only if user is owner or contributor
              (collection.uuid === hoverId || collection.uuid === currentCollection?.uuid) &&
                (collection.permission === UserPermission.OWNER ||
                  collection.permission === UserPermission.CONTRIBUTOR) && (
                  <Box position="absolute" sx={{ right: 0 }}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (location.pathname.endsWith('/upload')) {
                          navigate(`/v2/knowledge-finder/${libraryId}/collections/${collection.uuid}`);
                        } else {
                          navigate(`/v2/knowledge-finder/${libraryId}/collections/${collection.uuid}/upload`);
                        }
                      }}
                      sx={{
                        color:
                          location.pathname.endsWith('/upload') && collection.uuid === currentCollection?.uuid
                            ? theme.palette.primary.medium
                            : theme.palette.primary.lightest,

                        ':hover': {
                          color: theme.palette.primary.medium
                        }
                      }}
                    >
                      <FileCopyOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (location.pathname.endsWith(`/collections/${collection.uuid}/settings`)) {
                          navigate(`/v2/knowledge-finder/${libraryId}/collections/${collection.uuid}`);
                        } else {
                          navigate(`/v2/knowledge-finder/${libraryId}/collections/${collection.uuid}/settings`);
                        }
                      }}
                      sx={{
                        color:
                          location.pathname.endsWith(`/collections/${collection.uuid}/settings`) &&
                          collection.uuid === currentCollection?.uuid
                            ? theme.palette.primary.medium
                            : theme.palette.primary.lightest,
                        ':hover': {
                          color: theme.palette.primary.medium
                        }
                      }}
                    >
                      <Settings />
                    </IconButton>
                  </Box>
                )
            }
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
