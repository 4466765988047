import { SaveSuccessSnackbar } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import { usePostApi } from '@/hooks/use-post-api';
import { AddCircle, DeleteForever, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { get, set } from 'lodash';
import { useEffect, useState } from 'react';
import { JSONTree, LabelRenderer } from 'react-json-tree';
import { EditSequenceDialog } from './edit-sequence';
import { EditStepDialog } from './edit-step';

export const OrchestrationsManageOnboarding = () => {
  const theme = useTheme();

  // #region state vars
  const [jsonData, setJsonData] = useState<object | null>(null);
  const [editRootDialogOpen, setEditRootDialogOpen] = useState(false);
  const [editStepDialogOpen, setEditStepDialogOpen] = useState(false);
  const [currentDotPath, setCurrentDotPath] = useState<string>();
  const [actionSuccess, setActionSuccess] = useState('');
  const [stepData, setStepData] = useState({ title: '', content: '', position: 'center' });
  const [rootData, setRootData] = useState<{
    name: string;
    route: string;
    dependsOnHavingSeen?: string[];
  }>({ name: '', route: 'fake-route', dependsOnHavingSeen: [] });
  // #end region

  // #region API management
  const [onboardingJsonString] = useGetApi<object>(`tatsu/orchestrations/onboardingJson`);
  const { postData: postUpdateOnboardingJson } = usePostApi<void>(`tatsu/orchestrations/onboardingJson`);
  const { postData: postResetOnboarding } = usePostApi<void>(`feature-toggle/actions/toggleOnboardingExperience`);
  // #region API management

  // #region event handlers
  const exampleStep = {
    title: 'Onboarding Step',
    content: 'Bold: **Emphasis**<br><br>Action: <action>Action</action><br><br>Icon: <icon>RocketLaunch</icon>',
    position: 'center'
  };
  useEffect(() => {
    if (onboardingJsonString) {
      setJsonData(onboardingJsonString);
    }
  }, [onboardingJsonString]);

  // #region step node event handlers
  const handleStepNodeClick = (fullPath: string[]) => {
    const orderedDotPath = fullPath.reverse().join('.');
    const stepConfiguration = get(jsonData, orderedDotPath);
    setCurrentDotPath(orderedDotPath);
    setStepData(stepConfiguration);
    setEditStepDialogOpen(true);
  };
  const handleAddStep = (fullPath: string[]) => {
    const orderedDotPath = fullPath.reverse().join('.');
    const steps = get(jsonData, orderedDotPath);
    steps.push(exampleStep);
    const newJsonData = { ...jsonData }; // Create a shallow copy of jsonData
    set(newJsonData, orderedDotPath, steps);
    setJsonData(newJsonData);
  };
  const handleRemoveStep = (fullPath: string[]) => {
    const orderedDotPath = fullPath.reverse().join('.');
    const lastDotIndex = orderedDotPath.lastIndexOf('.');
    const parentPath = orderedDotPath.substring(0, lastDotIndex);
    const stepIndex = parseInt(orderedDotPath.substring(lastDotIndex + 1), 10);
    const steps = get(jsonData, parentPath, []).slice();
    if (steps.length > stepIndex) {
      steps.splice(stepIndex, 1);
    }
    const newJsonData = { ...jsonData }; // Create a shallow copy of jsonData
    set(newJsonData, parentPath, steps);
    setJsonData(newJsonData);
  };
  const handleRemoveRootNode = (fullPath: string[]) => {
    const newJsonData: { [key: string]: unknown } = { ...jsonData };
    delete newJsonData[fullPath[0] as string];
    setJsonData(newJsonData);
  };
  const handleDialogStepSave = () => {
    if (currentDotPath) {
      const newJsonData = { ...jsonData }; // Create a shallow copy of jsonData
      set(newJsonData, currentDotPath, stepData);
    }
    setEditStepDialogOpen(false);
  };
  const handleDialogStepCancel = () => {
    setEditStepDialogOpen(false);
    setCurrentDotPath('');
    setStepData({ title: '', content: '', position: 'center' });
  };
  // #endregion step node event handlers

  // #region root node event handlers
  const handleDialogRootSave = () => {
    const existingRootData = get(jsonData, currentDotPath as string);
    const newJsonData: { [key: string]: unknown } = { ...jsonData }; // Create a shallow copy of jsonData
    newJsonData[rootData.name] = {
      ...existingRootData,
      route: rootData.route,
      dependsOnHavingSeen: rootData.dependsOnHavingSeen
    };
    if (currentDotPath !== rootData.name) {
      // data is moving to a new node, delete the old node
      delete newJsonData[currentDotPath as string];
    }
    setJsonData(newJsonData);
    setCurrentDotPath('');
    setEditRootDialogOpen(false);
  };
  const handleDialogRootCancel = () => {
    setEditRootDialogOpen(false);
    setCurrentDotPath('');
    setRootData({ name: '', route: 'fake-route', dependsOnHavingSeen: [] });
  };
  const handleRootNodeClick = (fullPath: string[]) => {
    const orderedDotPath = fullPath.reverse().join('.');
    const rootConfiguration = get(jsonData, orderedDotPath);
    setCurrentDotPath(orderedDotPath);
    setRootData({
      name: fullPath[0],
      route: rootConfiguration.route,
      dependsOnHavingSeen: rootConfiguration.dependsOnHavingSeen
    });
    setEditRootDialogOpen(true);
  };
  // #endregion root node event handlers

  const labelRenderer: LabelRenderer = (keyPath) => {
    const keyName = keyPath[0];
    const handleClickEditStepNodeClick = () => {
      handleStepNodeClick([...keyPath] as string[]);
    };
    const handleClickAddStep = () => {
      handleAddStep([...keyPath] as string[]);
    };
    const handleClickRemoveStep = () => {
      handleRemoveStep([...keyPath] as string[]);
    };
    const handleClickEditRootNode = () => {
      handleRootNodeClick([...keyPath] as string[]);
    };
    const handleClickRemoveRootNode = () => {
      handleRemoveRootNode([...keyPath] as string[]);
    };

    return (
      <Box sx={{ pt: theme.spacing(1) }}>
        <Typography
          variant="button"
          sx={{
            color: theme.palette.primary.contrastText,
            fontSize: 12,
            fontWeight: 600
          }}
        >
          {keyPath.length === 3 && keyPath[1] === 'steps'
            ? `Edit Step ${Number.parseInt(keyName as string, 10) + 1}`
            : keyName}
        </Typography>
        {keyPath.length === 1 && (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickEditRootNode();
              }}
              size="small"
              sx={{
                marginLeft: theme.spacing(1.5)
              }}
            >
              <Edit sx={{ color: theme.palette.yellow.light }} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickRemoveRootNode();
              }}
              size="small"
              sx={{
                marginLeft: theme.spacing(3)
              }}
            >
              <DeleteForever sx={{ color: theme.palette.red.light }} />
            </IconButton>
          </>
        )}
        {keyPath.length === 3 && keyPath[1] === 'steps' && (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickEditStepNodeClick();
              }}
              size="small"
              sx={{
                marginLeft: theme.spacing(1.5)
              }}
            >
              <Edit sx={{ color: theme.palette.yellow.light }} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickRemoveStep();
              }}
              size="small"
              sx={{
                marginLeft: theme.spacing(3)
              }}
            >
              <DeleteForever sx={{ color: theme.palette.red.light }} />
            </IconButton>
          </>
        )}
        {keyPath.length === 2 && keyName === 'steps' && (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClickAddStep();
              }}
              size="small"
              sx={{
                marginLeft: theme.spacing(1.5)
              }}
            >
              <AddCircle sx={{ color: theme.palette.yellow.light }} />
            </IconButton>
          </>
        )}
      </Box>
    );
  };
  // #end region event handlers

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(5),
        left: 0,
        right: 0,
        bottom: 0,
        pl: theme.spacing(5),
        pr: theme.spacing(5),
        overflowY: 'scroll',
        mb: 10
      }}
    >
      <Typography variant="h4" sx={{ color: 'black', fontWeight: '400' }}>
        Manage Onboarding
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], mb: theme.spacing(3) }}>
        Configure onboarding screens for users
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          background: theme.palette.background.default,
          pb: theme.spacing(3),
          pt: theme.spacing(3)
        }}
      >
        <Button
          sx={{ color: theme.palette.yellow.light }}
          variant="clean-creative"
          onClick={() => {
            const newJsonData = { ...jsonData }; // Create a shallow copy of jsonData
            const timestampKey = `sequence_${Date.now()}`; // Unique key based on timestamp
            set(newJsonData, timestampKey, {
              route: `fake-route-${timestampKey}`,
              dependsOnHavingSeen: [],
              steps: [exampleStep]
            });
            setJsonData(newJsonData);
          }}
        >
          Add New Onboarding Sequence
        </Button>
        <Typography pt={theme.spacing(1)}>|</Typography>
        <Button
          variant="clean-creative"
          onClick={async () => {
            await postUpdateOnboardingJson({ onboardingJsonString: JSON.stringify(jsonData) });
            setActionSuccess('Onboarding configuration JSON updated successfully');
          }}
        >
          Save Latest Onboarding State
        </Button>
        <Typography pt={theme.spacing(1)}>|</Typography>
        <Button
          variant="destructive-light"
          onClick={async () => {
            await postResetOnboarding({ enabled: true });
            setActionSuccess('Onboarding reset successfully');
          }}
        >
          Reset My Onboarding
        </Button>
      </Box>
      {jsonData && (
        <Box>
          <JSONTree
            data={jsonData}
            theme={{
              scheme: 'custom',
              author: 'custom',
              base00: theme.palette.background.default,
              base01: theme.palette.blue.light,
              base02: theme.palette.blue.main,
              base03: theme.palette.blue.light,
              base04: theme.palette.purple.light,
              base05: theme.palette.purple.main,
              base06: theme.palette.purple.light,
              base07: theme.palette.violet.light,
              base08: theme.palette.red.light,
              base09: theme.palette.orange.light,
              base0A: theme.palette.yellow.light,
              base0B: theme.palette.green.light,
              base0C: theme.palette.green.main,
              base0D: theme.palette.blue.light,
              base0E: theme.palette.purple.light,
              base0F: theme.palette.pink.light
            }}
            invertTheme={false}
            hideRoot={true}
            labelRenderer={labelRenderer}
          />
        </Box>
      )}
      <EditSequenceDialog
        rootData={rootData}
        setRootData={setRootData}
        open={editRootDialogOpen}
        onClose={handleDialogRootCancel}
        onSave={handleDialogRootSave}
        dependsOnChoices={jsonData ? Object.keys(jsonData).filter((key) => key !== rootData.name) : []}
      />
      <EditStepDialog
        open={editStepDialogOpen}
        onClose={handleDialogStepCancel}
        onSave={handleDialogStepSave}
        stepData={stepData}
        setStepData={setStepData}
      />
      <SaveSuccessSnackbar saveSuccess={actionSuccess} setSaveSuccess={setActionSuccess} />
    </Box>
  );
};
