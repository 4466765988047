import { ThemeOptions, createTheme } from '@mui/material';

// custom color palette
const customColors = {
  green: { main: '#195D41', medium: '#359C71', light: '#5EC59B', lighter: '#AFBCB0', lightest: '#DBE7DC' }, //5EC59B
  red: { main: '#663F3C', dark: '#663F3C', light: '#c04435' },
  orange: { main: '#845B1F', dark: '#845B1F', light: '#bc812c' },
  blue: { main: '#1C5388', dark: '#1C5388', light: '#2776c1' },
  purple: { main: '#493C66', dark: '#493C66', light: '#962976', lightest: '#be93f6' },
  yellow: { main: '#a17f1a', light: '#d8aa22', dark: '#a17f1a' },
  violet: { main: '#581845', light: '#581845', dark: '#962976' },
  pink: { main: '#900C3F', light: '#900C3F ', dark: '#d1115b' },
  gray: { main: '#323232', medium: '#4B4B4B', light: '#7F7F7F', lighter: '#B2B2B2', lightest: '#B6B6B6' },
  black: { main: '#000000', dark: '#000000', light: '#000000', contrastText: '#fff' }
};

const sharedPalette = {
  info: {
    main: '#1C5388'
  },
  warning: {
    main: '#845B1F'
  },
  error: {
    main: '#5D2119'
  },
  success: {
    main: '#195D41'
  },
  ...customColors
};

const darkPalette = {
  secondary: {
    main: '#323232', // dark gray
    light: '#B6B6B6' // light gray
  },
  background: {
    default: '#323232', // dark gray
    paper: '#4B4B4B' // lighter gray
  },
  text: {
    primary: '#fff', // white
    secondary: '#B2B2B2' // light gray
  }
};

export const luxuryThemeOptions: (primaryScheme: keyof typeof customColors) => ThemeOptions = (primaryScheme) => ({
  palette: {
    primary: customColors[primaryScheme],
    ...darkPalette,
    ...sharedPalette
  },
  spacing: 8,
  borderRadius: 45,
  sharperBorderRadius: 5,
  shape: {
    borderRadius: 6
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    h1: {
      fontFamily: 'Aeonik Light',
      fontSize: 96
    },
    h2: {
      fontFamily: 'Canela Text Thin',
      fontSize: 46
    },
    h3: {
      fontFamily: 'Canela Text Thin',
      fontSize: 32
    },
    h4: {
      fontFamily: 'Canela Text Light',
      fontSize: 32
    },
    h5: {
      fontFamily: 'Aeonik',
      fontSize: 24
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      textTransform: 'uppercase',
      letterSpacing: '0.25em',
      fontWeight: 500
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: 17
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: 12
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: 15,
      textTransform: 'uppercase'
    },
    button: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      letterSpacing: '0.25em',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: 12.8,
      letterSpacing: '0.38em',
      textTransform: 'uppercase',
      fontWeight: 500
    },
    subtitle2: {
      fontFamily: 'Montserrat',
      fontSize: 12.8,
      textTransform: 'uppercase',
      fontWeight: 500
    },
    code: {},
    chat: { fontFamily: 'Inter', fontSize: 14 },
    sideNavHeader: {
      fontFamily: 'Montserrat',
      fontSize: 13,
      letterSpacing: '0.25em',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    // default
    fontFamily: 'Canela Text'
  },
  // custom component styles, extend component capabilities
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          fontSize: 14,
          letterSpacing: '0.25em',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          borderRadius: 45
        }
      },
      variants: [
        {
          props: { variant: 'destructive' },
          style: {
            backgroundColor: 'none',
            color: '#8b1614',
            padding: '0',
            '&:hover': {
              textDecoration: 'underline',
              background: 'none'
            }
          }
        },
        {
          props: { variant: 'destructive-light' },
          style: {
            fontSize: '14px',
            backgroundColor: 'none',
            color: customColors.red.light,
            padding: '0',
            '&:hover': {
              textDecoration: 'underline',
              background: 'none'
            }
          }
        },
        {
          props: { variant: 'creative' },
          style: {
            backgroundColor: '#d3eeee',
            color: '#00a797',
            '&:hover': {
              backgroundColor: '#c8edea'
            }
          }
        },
        {
          props: { variant: 'clean-creative' },
          style: {
            fontSize: '14px',
            backgroundColor: 'none',
            color: customColors.green.medium,
            '&:hover': {
              textDecoration: 'underline',
              background: 'none'
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          height: 26,
          width: 26,
          fontSize: 18
        },
        sizeMedium: {
          height: 45,
          width: 45,
          fontSize: 28
        },
        sizeLarge: {
          width: 63,
          height: 63,
          fontSize: 36
        },
        root: {
          color: primaryScheme === 'green' ? customColors[primaryScheme].lightest : customColors[primaryScheme].light,
          '&:hover': {
            color: customColors[primaryScheme].main
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          fontFamily: 'Aeonik',
          fontWeight: 'bold'
        }
      }
    }
  }
});

export const kfLuxuryTheme = createTheme(luxuryThemeOptions('green'));
