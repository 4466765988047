import { PaletteColor } from '@mui/material';
import { ReactNode, createContext, useContext } from 'react';

// #region NavBar Context
type NavBarContextType = {
  title?: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  productTitle?: string;
  setProductTitle: React.Dispatch<React.SetStateAction<string>>;
  color?: PaletteColor;
  setColor: React.Dispatch<React.SetStateAction<PaletteColor>>;
  parentBreadcrumb?: { name: string; route: string };
  setParentBreadcrumb: React.Dispatch<React.SetStateAction<{ name: string; route: string } | undefined>>;
  childBreadcrumb?: { name: string; route: string };
  setChildBreadcrumb: React.Dispatch<React.SetStateAction<{ name: string; route: string } | undefined>>;
};
const NavBarContext = createContext<NavBarContextType | undefined>(undefined);
export const NavBarProvider = NavBarContext.Provider;
export function useNavBar(): NavBarContextType {
  const context = useContext(NavBarContext);
  if (!context) {
    throw new Error('useNavBar must be used within a NavBarProvider');
  }
  return context;
}
// #endregion NavBar Context

// #region NavBaMenu Context
type NavBarMenuContextType = {
  content: ReactNode;
  setContent: React.Dispatch<React.SetStateAction<ReactNode>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const NavBarMenuContext = createContext<NavBarMenuContextType | undefined>(undefined);
export const NavBarMenuProvider = NavBarMenuContext.Provider;
export function useNavBarMenu(): NavBarMenuContextType {
  const context = useContext(NavBarMenuContext);
  if (!context) {
    throw new Error('useNavBarMenu must be used within a NavBarMenuProvider');
  }
  return context;
}
// #endregion NavBaMenu Context
