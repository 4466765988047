import theme from '@/styles/theme';

export const jsonTreeTheme = {
  scheme: 'custom',
  author: 'custom',
  base00: theme.palette.background.paper,
  base01: theme.palette.blue.dark,
  base02: theme.palette.blue.main,
  base03: theme.palette.blue.light,
  base04: theme.palette.purple.dark,
  base05: theme.palette.purple.main,
  base06: theme.palette.purple.light,
  base07: theme.palette.violet.dark,
  base08: theme.palette.red.dark,
  base09: theme.palette.orange.dark,
  base0A: theme.palette.yellow.dark,
  base0B: theme.palette.green.dark,
  base0C: theme.palette.green.main,
  base0D: theme.palette.blue.dark,
  base0E: theme.palette.purple.dark,
  base0F: theme.palette.pink.dark
};
