import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  lighten
} from '@mui/material';
import { RowMetadata } from './types';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  theme: Theme;
  rowToDelete: RowMetadata | null;
}

export const DeleteConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  rowToDelete,
  theme
}: DeleteConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the file{' '}
          <Typography component="span" sx={{ color: lighten(theme.palette.error.main, 0.35) }}>
            {rowToDelete?.name}
          </Typography>
          ? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button onClick={onConfirm} sx={{ color: lighten(theme.palette.error.main, 0.35) }}>
          Delete
        </Button>
        <Button onClick={onClose} sx={{ color: theme.palette.primary.light }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
