import { Box, Checkbox, Theme, Typography } from '@mui/material';
import { useState } from 'react';

export const AdvancedSettingsToggle = ({ onToggle, theme }: { onToggle: (open: boolean) => void; theme: Theme }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    onToggle(newIsOpen);
  };

  return (
    <Box onClick={handleToggle} style={{ cursor: 'pointer' }} sx={{ pt: theme.spacing(3) }}>
      <Typography variant="button" sx={{ color: theme.palette.secondary.light, fontSize: '1rem' }}>
        ADVANCED SETTINGS {isOpen ? '▼' : '►'}
      </Typography>
    </Box>
  );
};

export const IsPublicToggle = ({
  isVisible,
  isPublic,
  onToggle,
  theme
}: {
  isVisible: boolean;
  isPublic: boolean;
  onToggle: (isPublic: boolean) => void;
  theme: Theme;
}) => {
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked);
  };

  if (!isVisible) return null;

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        checked={isPublic}
        onChange={handleToggle}
        size="small"
        sx={{
          '.Mui-disabled': {
            '& .MuiSvgIcon-root': { color: theme.palette.grey[500] }
          },
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.light
          }
        }}
      />
      <Typography variant="button">Official Collection</Typography>
    </Box>
  );
};
