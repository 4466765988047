// src/hooks/useRouteChange.ts
import { useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';

export function useRouteChange(onRouteChange: (location: Location) => void): void {
  const location = useLocation();
  const onRouteChangeRef = useRef(onRouteChange);

  // Update the ref to the current function on each render
  useEffect(() => {
    onRouteChangeRef.current = onRouteChange;
  });

  useEffect(() => {
    // Call the current version of onRouteChange function with the current location
    onRouteChangeRef.current(location);
  }, [location]); // Only re-run on location changes
}
