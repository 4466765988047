import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/system';
import { SetStateAction } from 'react';

// Dialog to confirm the deletion of an entity (file, collection, library)
export const EditDeleteDialog = ({
  openConfirmDeleteModal,
  setOpenConfirmDeleteModal,
  postDeleteEntity,
  handleClose,
  confirmDeleteMsg
}: {
  openConfirmDeleteModal: boolean;
  setOpenConfirmDeleteModal: React.Dispatch<SetStateAction<boolean>>;
  confirmDeleteMsg?: string;
  postDeleteEntity: () => void;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const handleConfirmDelete = async () => {
    // delete logic
    postDeleteEntity();
    // close the modal
    setTimeout(() => {
      setOpenConfirmDeleteModal(false);
    }, 250);
    // close the panel
    handleClose();
  };
  return (
    <Dialog open={openConfirmDeleteModal} onClose={() => setOpenConfirmDeleteModal(false)}>
      <Box sx={{ background: theme.palette.background.paper }}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDeleteMsg}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="destructive-light" onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
          <Button
            variant="clean-creative"
            onClick={() => setTimeout(() => setOpenConfirmDeleteModal(false), 100)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
