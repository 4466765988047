/* eslint-disable @typescript-eslint/no-unused-vars */
import { DataGrid } from '@/components';
import { usePostApi } from '@/hooks/use-post-api';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import { useAuth0 } from '@auth0/auth0-react';
import { SaveAlt } from '@mui/icons-material';
import { Box, Icon, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { unparse } from 'papaparse';
import { useEffect, useState } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { DocumentCollection, FileMetadata } from '../../types';
import { getTableColumns } from './table-config';
import { CustomColumnMenu } from './table-date-menu-controls';

/**
 * Table view for KF documents
 */
export const TableView = ({
  documents,
  navigate,
  collection
}: {
  documents: (FileMetadata & { [key: string]: unknown })[];
  navigate: NavigateFunction;
  collection?: DocumentCollection;
}) => {
  const theme = useTheme();

  const { collectionId, projectId } = useParams<{ collectionId: string; projectId: string }>();
  const { user } = useAuth0();

  const [metadataFilledDocs, setMetadataFilledDocs] =
    useState<(FileMetadata & { [key: string]: unknown })[]>(documents);
  const [hoveredRow, setHoveredRow] = useState('');

  useEffect(() => {
    if (collection?.flexibleMetaschema) {
      const metadataFilledDocs = documents.map((doc) => {
        const metadataFilledDoc = { ...doc };
        Object.entries(collection.flexibleMetaschema).forEach(([, value]) => {
          metadataFilledDoc[value.label] = doc?.flexibleMetaschema?.[value.label];
        });
        return metadataFilledDoc;
      });
      setMetadataFilledDocs(metadataFilledDocs);
    }
  }, [collection, documents]);

  let gridStateKey = `${user?.sub}-kf-${projectId}-gridState`;
  let columnWidthKey = `${user?.sub}-kf-${projectId}-columnWidths`;

  if (collectionId && collection && collectionId === collection.uuid && !!collection.flexibleMetaschema) {
    /**
     * if there's custom metaschema, make the table state unique to the
     * collection else fallback to library level
     */
    gridStateKey += `-${collectionId}`;
    columnWidthKey += `-${collectionId}`;
  }

  // pull grid state from local storage to set initial grid state
  const dataGridInitialState = JSON.parse(localStorage.getItem(gridStateKey) || '{}');
  // pull column width state from local storage to set initial column widths
  const dataGridInitialColumnWidths = JSON.parse(localStorage.getItem(columnWidthKey) || '{}');
  const columns = getTableColumns({
    collectionId,
    projectId: projectId as string,
    hoveredRow,
    currentDateFormat: dataGridInitialState?.columns?.lookup?.displayDate?.description || 'day',
    savedColumnWidths: dataGridInitialColumnWidths,
    theme,
    navigate,
    metadataKeys: collection?.flexibleMetaschema?.map((meta) => meta.label) || []
  });

  const tableStyles = {
    // cell height
    '.MuiDataGrid-cell': {
      height: 50,
      ...theme.typography.body2,
      padding: 0
    },
    // column header font & spacing
    '.MuiDataGrid-columnHeader': {
      pl: 0,
      ...theme.typography.caption
    },
    // styles for the button hover effect
    '.MuiDataGrid-pinnedColumnHeaders': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minWidth: 0
    },
    '.MuiDataGrid-pinnedColumns': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '& .MuiDataGrid-cell': {
        padding: 0
      }
    },
    '.MuiDataGrid-row.Mui-hovered': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
      color: theme.palette.secondary.main
    },

    // style for cell selection
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '& .MuiButtonBase-root': {
      ':hover': {
        backgroundColor: theme.palette.primary.lightest
      },
      mr: 2
    },
    '& .MuiDataGrid-columnSeparator': {
      mr: 2
    },
    maxWidth: '100%',
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    zIndex: 0,
    ...getHideScrollBarProps()
  };

  const { postData: trackDownloadDocumentMetadata } = usePostApi<void>(`knowledge-finder/collection/download`);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          component="button"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            pb: theme.spacing(5),
            '&:hover': {
              textDecoration: 'none'
            }
          }}
          onClick={async () => {
            await trackDownloadDocumentMetadata({
              libraryId: projectId,
              collectionId: collectionId
            });

            const slimmedDocInfo = metadataFilledDocs.map((doc) => {
              /**
               * rid stuff that only Prism cares about
               */
              const {
                uuid,
                createdAt,
                updatedAt,
                project,
                uploadedBy,
                embeddings,
                uploadStatus,
                previewUrl,
                downloadUrl,
                flexibleMetaschema,
                collections,
                ...slimmedDoc
              } = doc;
              // @ts-expect-error swapping for output only
              slimmedDoc.tags = doc?.tags?.map((tag) => tag.name).join(', ');
              return slimmedDoc;
            });
            const csv = unparse(slimmedDocInfo);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            const now = new Date();
            downloadLink.download = `PrismKnowledgeFinder_DocumentMetadata-${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}-${now.getHours()}${now.getMinutes()}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
          }}
        >
          <Icon
            sx={{
              fontSize: '1.5rem',
              color: theme.palette.green.light,
              mr: theme.spacing(1),
              '&:hover': {
                textDecoration: 'none'
              }
            }}
          >
            <SaveAlt fontSize="small" />
          </Icon>
          <Typography
            variant="button"
            sx={{
              color: theme.palette.green.light,
              fontSize: '.8rem',
              fontWeight: 600
            }}
          >
            Export Document Metadata
          </Typography>
        </Box>
      </Box>
      <DataGrid
        initialState={{ ...dataGridInitialState, pinnedColumns: { right: ['actions'] } }}
        onStateChange={(newState) => {
          const { columns } = newState;
          const serializedState = JSON.stringify(newState);

          // Extract and save column widths separately
          const columnWidths = Object.fromEntries(
            Object.entries(columns?.lookup).map(([colName, colProps]) => [
              colName,
              (colProps as Partial<GridColDef>).width
            ])
          );
          // save column widths to local storage
          localStorage.setItem(columnWidthKey, JSON.stringify(columnWidths));

          // save latest table state to local storage
          localStorage.setItem(gridStateKey, serializedState);
        }}
        columns={columns}
        rows={metadataFilledDocs}
        checkboxSelection={false}
        rowHeight={50}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 50}
        onRowClick={(row) => {
          collectionId
            ? navigate(`/v2/knowledge-finder/${projectId}/collections/${collectionId}/documents/${row.row.uuid}`)
            : // if in the library context, default to the first collection found in the document
              navigate(
                `/v2/knowledge-finder/${projectId}/collections/${row.row.collections[0].uuid}/documents/${row.row.uuid}`
              );
        }}
        slots={{
          columnMenu: (props) => CustomColumnMenu(props)
        }}
        slotProps={{
          row: {
            onMouseEnter: (e) => {
              setHoveredRow(e.currentTarget.getAttribute('data-id') ?? '');
            },
            onMouseLeave: () => {
              setHoveredRow('');
            }
          }
        }}
        sx={tableStyles}
      />
    </Box>
  );
};
