import { SaveSuccessSnackbar } from '@/components';
import { EditDeleteDialog } from '@/components/edit/edit-delete';
import { DocumentTag } from '@/features/knowledge-finder-v2';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import DeleteIcon from '@mui/icons-material/Delete';
import StyleIcon from '@mui/icons-material/Style';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';

type Tag = {
  createdAt: string;
  count: number;
} & DocumentTag;

export const OrchestrationsDeleteTags = () => {
  const theme = useTheme();

  // #region state vars
  const [tags, setTags] = useState<Tag[]>([]);
  const [libraryId, setLibraryId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tagId, setTagId] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');
  // #end region

  // #region API management
  const { postData: postDeleteTag } = usePostApi<void>(`tatsu/orchestrations/delete/documentTag`);

  const { getData: getTags } = useGetApiViaCallback<Tag[]>(`tatsu/orchestrations/documentTags/${libraryId}`);
  // #region API management

  // #region event handlers
  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLibraryId(event.target.value);
  };

  const handleSubmit = async () => {
    if (libraryId) {
      const tags = await getTags();
      setTags(tags);
    }
  };
  // #end region event handlers

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(5),
        left: 0,
        right: 0,
        bottom: 0,
        pl: theme.spacing(5),
        pr: theme.spacing(5),
        overflowY: 'scroll',
        mb: 10
      }}
    >
      <Typography variant="h4" sx={{ color: 'black', fontWeight: '400' }}>
        Delete Library Tags
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], mb: theme.spacing(3) }}>
        View and delete tags from a library
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
        <Grid item xs={12} sm={6}>
          <TextField
            id="id-input"
            label="Library ID"
            variant="outlined"
            fullWidth
            value={libraryId}
            onChange={handleIdChange}
            inputProps={{ style: { width: '100%' } }} // Ensures the input stretches
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <IconButton
            sx={{
              '&:hover': {
                // Override the background change on hover for the IconButton here
                backgroundColor: 'transparent', // Or any other color you want
                // You can also disable the ripple effect if you want
                '& .MuiTouchRipple-root': {
                  display: 'none'
                }
              }
            }}
            onClick={handleSubmit}
          >
            <StyleIcon
              sx={{
                backgroundColor: theme.palette.green.light,
                color: theme.palette.green.main,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                width: '100px',
                height: '55px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)'
                }
              }}
              fontSize="large"
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        {tags &&
          Object.values(tags).map((tag, i) => (
            <Grid item key={i} sm={6} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    Name: <strong>{tag.name}</strong>
                  </Typography>
                  <Typography variant="body2">
                    Date: <strong>{tag.createdAt}</strong>
                  </Typography>
                  <Typography variant="body2">
                    Count: <strong>{tag.count}</strong>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="destructive-light"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setTagId(tag.uuid);
                    }}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        <Grid item xs={12}></Grid>
      </Grid>
      <EditDeleteDialog
        openConfirmDeleteModal={isDeleteModalOpen}
        setOpenConfirmDeleteModal={setIsDeleteModalOpen}
        postDeleteEntity={async () => {
          if (libraryId && tagId) {
            // delete tag
            await postDeleteTag({
              projectId: libraryId,
              documentTagId: tagId
            });
            // drop tag from ui
            setTags(tags.filter((tag) => tag.uuid !== tagId));
            // show delete msg
            setDeleteSuccess('Tag deleted successfully');
          }
          return;
        }}
        handleClose={() => {}}
        confirmDeleteMsg="Are you sure you want to delete this tag? This action cannot be undone."
      />
      <SaveSuccessSnackbar saveSuccess={deleteSuccess} setSaveSuccess={setDeleteSuccess} />
    </Box>
  );
};
