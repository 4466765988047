import { Box, CircularProgress, Fade, Modal } from '@mui/material';
import { memo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const ResultLoading = () => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      maxHeight={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(255,255,255,255)"
      zIndex={1}
    >
      <CircularProgress />
    </Box>
  );
};

const MOBILE_PREVIEW_MULTIPLIER = 1.5;
const DESKTOP_PREVIEW_MULTIPLIER = 2.5;

export const FilePreview = memo(
  ({
    id,
    objectURL,
    pageNumber = 1,
    isSmallScreen,
    isPreviewOpen,
    setIsPreviewOpen
  }: {
    id: string;
    objectURL: string | null;
    pageNumber: number;
    isSmallScreen: boolean;
    isPreviewOpen: boolean;
    setIsPreviewOpen: (value: boolean) => void;
  }) => {
    const [docSize, setDocSize] = useState({ width: 300, height: 300 * (9 / 16) });
    const [orientationBasedStyles, setOrientationBasedStyles] = useState({});

    const PREVIEW_MULTIPLIER = isSmallScreen ? MOBILE_PREVIEW_MULTIPLIER : DESKTOP_PREVIEW_MULTIPLIER;

    const handleLoadSuccess = ({ width, height }: { width: number; height: number }) => {
      const aspectRatio = width / height;
      const maxWidth = 300; // adjust as needed
      const maxHeight = 300; // adjust as needed

      let computedWidth, computedHeight;

      if (aspectRatio > 1) {
        // landscape
        computedWidth = maxWidth;
        computedHeight = maxWidth / aspectRatio;
      } else {
        // portrait
        computedHeight = maxHeight;
        computedWidth = maxHeight * aspectRatio;
      }
      // preview should be a big version of the view that appears in the card
      // we can adjust and use different sizing for landscape and portrait but think this looks pretty good for both
      setOrientationBasedStyles({
        width: computedWidth * PREVIEW_MULTIPLIER,
        height: computedHeight * PREVIEW_MULTIPLIER
      });

      setDocSize({ width: computedWidth, height: computedHeight });
    };

    return (
      <Box key={id} display="flex" sx={{ maxWidth: '100%' }}>
        <Box
          key={id}
          onClick={() => setIsPreviewOpen(true)}
          sx={{
            cursor: 'pointer',
            height: docSize.height,
            width: docSize.width,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {objectURL ? (
            <Document
              key={id}
              loading={<ResultLoading />}
              file={objectURL}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Page
                sx={{}}
                loading={<Box />}
                pageNumber={pageNumber}
                onLoadSuccess={handleLoadSuccess}
                width={docSize.width}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          ) : (
            <Box />
          )}
        </Box>
        <Modal open={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} closeAfterTransition>
          <Fade in={isPreviewOpen}>
            <Box
              sx={{
                overflow: 'hidden',
                outline: 'none',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                ...orientationBasedStyles
              }}
              className="document-view-box"
            >
              {objectURL ? (
                <Document
                  key={`preview${id}`}
                  loading={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  file={objectURL}
                >
                  <Page
                    loading={<Box />}
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Document>
              ) : (
                <Box />
              )}
            </Box>
          </Fade>
        </Modal>
      </Box>
    );
  }
);
