import { useGetApi } from '@/hooks/use-get-api';
import { LibraryProject, LibraryProjectUserAccess } from '@/types/projects';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const LibraryCard = ({ library }: { library: LibraryProject }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [projectAccess] = useGetApi<LibraryProjectUserAccess>(`project/${library.uuid}`);

  return (
    <Box
      width={350}
      height={400}
      onClick={() => navigate(`/v2/knowledge-finder/${library.uuid}`)}
      sx={{
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        // change color of all text w/in when any part of box is hovered over
        '&:hover': {
          borderTop: `1px solid ${theme.palette.primary.contrastText}`,
          '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText
          }
        }
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ pt: theme.spacing(2), pb: theme.spacing(2), color: theme.palette.gray.lightest }}
      >
        {library.isPublic ? 'Public' : 'Private'} Library
      </Typography>

      <Typography
        variant="h4"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          height: 80
        }}
      >
        {library.name}
      </Typography>

      <Box>
        <Box mt={theme.spacing(1)} mb={theme.spacing(1)} sx={{ color: theme.palette.gray.lightest }}>
          <Typography variant="subtitle2">Access: {projectAccess?.permission}</Typography>
          <Typography variant="subtitle2">
            Owner: {library.createdBy.firstName} {library.createdBy.lastName}
          </Typography>
          <Typography variant="subtitle2">Created: {library.createdAt.split('T')[0]}</Typography>
        </Box>
        <Typography
          sx={{
            mt: theme.spacing(4),
            color: theme.palette.gray.lightest,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: 100,
            display: '-webkit-box',
            WebkitLineClamp: '4',
            WebkitBoxOrient: 'vertical'
          }}
          variant="body1"
          title={library.description || 'No description.'}
        >
          {library.description
            ? library.description.length > 250
              ? `${library.description.substring(0, 250)}...`
              : library.description
            : 'No description.'}
        </Typography>
      </Box>
    </Box>
  );
};
