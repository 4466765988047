import { useNavBar } from '@/features/navbar';
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../components/page';

export default function SessionExpiredPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setColor, setProductTitle } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.black);
    setProductTitle(' ');
  }, [setColor, setProductTitle, theme.palette.black]);

  return (
    <Page>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          maxWidth: 400,
          minHeight: 300,
          m: 'auto',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box sx={{ mt: theme.spacing(7), textAlign: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Session Expired
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: theme.spacing(2) }}>
            Your session has ended. Please log in again to continue.
          </Typography>
        </Box>
        <Box sx={{ p: theme.spacing(2), textAlign: 'center' }}>
          <Button onClick={() => navigate(`/pub/login`)} variant="clean-creative">
            Back to Login
          </Button>
        </Box>
      </Card>
    </Page>
  );
}
