// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;
import { useEffect, useState } from 'react';
import useInjectScript from './use-inject-script';

export type CallbackDoc = {
  downloadUrl?: string;
  uploadState?: string;
  description: string;
  driveSuccess: boolean;
  embedUrl: string;
  iconUrl: string;
  id: string;
  isShared: boolean;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  rotation: number;
  rotationDegree: number;
  serviceId: string;
  sizeBytes: number;
  type: string;
  url: string;
};

export type PickerCallback = {
  action: string;
  docs: CallbackDoc[];
};

export type authResult = {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  authuser: string;
  prompt: string;
};

// see options here: https://developers.google.com/drive/picker/reference#view-id
export type ViewIdOptions = 'PDFS' | 'PRESENTATIONS';

export type PickerConfiguration = {
  clientId: string;
  developerKey: string;
  viewId?: ViewIdOptions;
  viewMimeTypes?: string;
  setIncludeFolders?: boolean;
  setSelectFolderEnabled?: boolean;
  disableDefaultView?: boolean;
  token?: string;
  setOrigin?: string;
  multiselect?: boolean;
  disabled?: boolean;
  appId?: string;
  supportDrives?: boolean;
  showUploadView?: boolean;
  showUploadFolders?: boolean;
  setParentFolder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customViews?: any[];
  locale?: string;
  customScopes?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callbackFunction: (data: PickerCallback) => any;
};

export const defaultConfiguration: PickerConfiguration = {
  clientId: import.meta.env.VITE_GOOGLE_PICKER_CLIENT_ID || '',
  developerKey: import.meta.env.VITE_GOOGLE_PICKER_API_KEY || '',
  viewId: 'PDFS',
  callbackFunction: () => null
};

export default function useGoogleDrivePicker(): [
  (config: PickerConfiguration) => boolean | undefined,
  authResult | undefined
] {
  const defaultScopes = ['https://www.googleapis.com/auth/drive.readonly'];
  const [loaded, error] = useInjectScript('https://apis.google.com/js/api.js');
  const [loadedGsi, errorGsi] = useInjectScript('https://accounts.google.com/gsi/client');
  const [pickerApiLoaded, setpickerApiLoaded] = useState(false);
  const [openAfterAuth, setOpenAfterAuth] = useState(false);
  const [config, setConfig] = useState<PickerConfiguration>(defaultConfiguration);
  const [authRes, setAuthRes] = useState<authResult>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let picker: any;

  const onPickerApiLoad = () => {
    setpickerApiLoaded(true);
  };

  // load the Drive picker api
  const loadApis = () => {
    window.gapi.load('auth');
    window.gapi.load('picker', { callback: onPickerApiLoad });
  };

  // get the apis from googleapis
  useEffect(() => {
    if (loaded && !error && loadedGsi && !errorGsi && !pickerApiLoaded) {
      loadApis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error, loadedGsi, errorGsi, pickerApiLoaded]);

  const createPicker = ({
    token,
    appId = '',
    supportDrives = false,
    developerKey,
    viewId = 'PDFS',
    disabled,
    multiselect,
    setOrigin,
    showUploadView = false,
    showUploadFolders,
    setParentFolder = '',
    viewMimeTypes,
    customViews,
    locale = 'en',
    setIncludeFolders,
    setSelectFolderEnabled,
    disableDefaultView = false,
    callbackFunction
  }: PickerConfiguration) => {
    if (disabled) return false;

    const view = new google.picker.DocsView(google.picker.ViewId[viewId]);
    if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
    if (setIncludeFolders) view.setIncludeFolders(true);
    if (setSelectFolderEnabled) view.setSelectFolderEnabled(true);

    const uploadView = new google.picker.DocsUploadView();
    if (viewMimeTypes) uploadView.setMimeTypes(viewMimeTypes);
    if (showUploadFolders) uploadView.setIncludeFolders(true);
    if (setParentFolder) uploadView.setParent(setParentFolder);
    if (setParentFolder) view.setParent(setParentFolder);

    picker = new google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token)
      .setDeveloperKey(developerKey)
      .setLocale(locale)
      .setCallback(callbackFunction);

    if (setOrigin) {
      picker.setOrigin(setOrigin);
    }

    if (!disableDefaultView) {
      picker.addView(view);
    }

    if (customViews) {
      customViews.map((view) => picker.addView(view));
    }

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    if (showUploadView) picker.addView(uploadView);

    if (supportDrives) {
      picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES);
    }

    picker.build().setVisible(true);
    return true;
  };

  // use effect to open picker after auth
  useEffect(() => {
    if (openAfterAuth && config.token && loaded && !error && loadedGsi && !errorGsi && pickerApiLoaded) {
      createPicker(config);
      setOpenAfterAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAfterAuth, config.token, loaded, error, loadedGsi, errorGsi, pickerApiLoaded]);

  // open the picker
  const openPicker = (config: PickerConfiguration) => {
    // global scope given conf
    setConfig(config);

    // if we didnt get token generate token.
    if (!config.token) {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        scope: (config.customScopes ? [...defaultScopes, ...config.customScopes] : defaultScopes).join(' '),
        callback: (tokenResponse: authResult) => {
          setAuthRes(tokenResponse);
          createPicker({ ...config, token: tokenResponse.access_token });
        }
      });

      client.requestAccessToken();
    }

    // if we have token and everything is loaded open the picker
    if (config.token && loaded && !error && pickerApiLoaded) {
      return createPicker(config);
    }
  };

  return [openPicker, authRes];
}
