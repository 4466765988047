import { createContext, useContext } from 'react';
import { FileMetadata } from '../types';

type SearchContextType = {
  searchResults: FileMetadata[];
  setSearchResults: React.Dispatch<React.SetStateAction<FileMetadata[]>>;
  searchQuery: string | undefined;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const SearchContext = createContext<SearchContextType | undefined>(undefined);
export const SearchProvider = SearchContext.Provider;
export function useSearchContext(): SearchContextType {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
}
