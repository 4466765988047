// capabilities we want to show-off
export enum TatsuDemonstrations {
  Pioneer = 'Pioneer' // NYI
}

// capabilities that help us interrogate our own platform
export enum TatsuInvestigations {
  Inquire = 'Inquire', // see specific data points based on IDs
  Pulse = 'Pulse' // get a general sense of the health of the platform
}

// capabilities that help us effectively manage change in our platform
export enum TatsuOrchestrations {
  DeleteTags = 'Delete Document Tags', // delete tags at the library level
  ManageUsers = 'Manage Users', // configure user access and feature toggles
  ManageOnboarding = 'Manage Onboarding' // configure onboarding steps
}
