import { Add, UploadFile } from '@mui/icons-material';
import { Box, IconButton, Theme, Typography, lighten } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NoCollections = ({
  theme,
  projectId,
  collectionId
}: {
  theme: Theme;
  projectId: string;
  collectionId: string;
}) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" alignItems="center" sx={{ mt: theme.spacing(20) }}>
      <Typography variant="h2">Add documents</Typography>
      <IconButton
        size="large"
        sx={{
          ml: theme.spacing(1)
        }}
        onClick={() => navigate(`/v2/knowledge-finder/${projectId}/collections/${collectionId}/upload`)}
      >
        <UploadFile />
      </IconButton>
    </Box>
  );
};

export const NoDocuments = ({
  theme,
  handleAddCollection
}: {
  theme: Theme;
  handleAddCollection: (isPublic: boolean) => Promise<void>;
}) => {
  return (
    <Box display="flex" alignItems="center" sx={{ mt: theme.spacing(20) }}>
      <Typography variant="h2">Create new collection</Typography>
      <IconButton
        size="large"
        sx={{
          ml: theme.spacing(2),
          background: theme.palette.primary.lightest,
          color: theme.palette.primary.main,
          ':hover': {
            background: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
            boxShadow: `0em 0em 0.4em 0em ${lighten(theme.palette.green.light, 0.2)}`
          }
        }}
        onClick={() => handleAddCollection(false)}
      >
        <Add />
      </IconButton>
    </Box>
  );
};

export const NoSearchResults = ({ theme }: { theme: Theme }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ mt: theme.spacing(20) }}>
      <Typography variant="h2">No Results</Typography>
    </Box>
  );
};
