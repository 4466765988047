import { UserPermission } from '@/types/user';
import { createContext, useContext } from 'react';
import { DocumentCollection } from '../types';

type CollectionContextType = {
  collection?: DocumentCollection | undefined;
  collectionPermission?: UserPermission | undefined;
  setCollection: React.Dispatch<React.SetStateAction<DocumentCollection | undefined>>;
  handleAddCollection: (isPublic: boolean) => Promise<void>;
};
const CollectionContext = createContext<CollectionContextType | undefined>(undefined);
export const CollectionProvider = CollectionContext.Provider;
export function useCollectionContext(): CollectionContextType {
  const context = useContext(CollectionContext);
  if (!context) {
    throw new Error('useCollectionContext must be used within a CollectionProvider');
  }
  return context;
}
