import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Box, IconButton, useTheme } from '@mui/material';

/**
 * toggles between table and card view for KF
 */
export const ToggleViewArea = ({
  isInTableView,
  onTableViewClicked
}: {
  isInTableView: boolean;
  onTableViewClicked: (t: boolean) => void;
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="left" onChange={() => onTableViewClicked(!isInTableView)}>
      <IconButton
        disableRipple
        sx={{
          width: 'unset',
          padding: 0,
          color: theme.palette.primary.lightest,
          '&.Mui-disabled': {
            color: theme.palette.primary.light
          }
        }}
        onClick={() => onTableViewClicked(false)}
        disabled={!isInTableView}
      >
        <GridViewIcon />
      </IconButton>
      <IconButton
        disableRipple
        sx={{
          width: 'unset',
          color: theme.palette.primary.lightest,
          '&.Mui-disabled': {
            color: theme.palette.primary.light
          }
        }}
        onClick={() => onTableViewClicked(true)}
        disabled={isInTableView}
      >
        <TableRowsIcon />
      </IconButton>
    </Box>
  );
};
