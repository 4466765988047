import { NavBar, NavBarMenuProvider, NavBarProvider } from '@/features/navbar';
import theme from '@/styles/theme';
import { CssBaseline, Toolbar } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface LayoutProvidersProps {
  children: ReactNode;
}

// establish all shared provider contexts that outlets need influence over
function LayoutProviders({ children }: LayoutProvidersProps) {
  const [navTitle, setNavTitle] = useState('');
  const [productTitle, setProductTitle] = useState('');

  const [menuContent, setMenuContent] = useState<ReactNode>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navColor, setNavColor] = useState(theme.palette.black);

  const [parentBreadcrumb, setParentBreadcrumb] = useState<{ name: string; route: string }>();
  const [childBreadcrumb, setChildBreadcrumb] = useState<{ name: string; route: string }>();

  return (
    // nav bar provider
    <NavBarProvider
      value={{
        title: navTitle,
        setTitle: setNavTitle,
        productTitle,
        setProductTitle,
        setColor: setNavColor,
        color: navColor,
        parentBreadcrumb,
        setParentBreadcrumb,
        childBreadcrumb,
        setChildBreadcrumb
      }}
    >
      <NavBarMenuProvider
        value={{
          content: menuContent,
          setContent: setMenuContent,
          drawerOpen,
          setDrawerOpen
        }}
      >
        {children}
      </NavBarMenuProvider>
    </NavBarProvider>
  );
}

export default function Layout() {
  return (
    <LayoutProviders>
      <CssBaseline />
      <NavBar />
      <Toolbar />
      <Outlet />
    </LayoutProviders>
  );
}
