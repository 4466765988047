import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  useTheme
} from '@mui/material';

interface RootData {
  name: string;
  route: string;
  dependsOnHavingSeen?: string[];
}

interface EditSequenceDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  rootData: RootData;
  dependsOnChoices?: string[];
  setRootData: (rootData: RootData) => void;
}

export const EditSequenceDialog: React.FC<EditSequenceDialogProps> = ({
  open,
  onClose,
  onSave,
  rootData,
  setRootData,
  dependsOnChoices
}) => {
  const theme = useTheme();

  const { name: rootName, route, dependsOnHavingSeen } = rootData;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRootData({ ...rootData, name: e.target.value });
  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRootData({ ...rootData, route: e.target.value });
  const handleDependsOnChange = (e: React.ChangeEvent<{ value: unknown }>) =>
    setRootData({ ...rootData, dependsOnHavingSeen: e.target.value as string[] });

  // #region shared styles
  const textInputProps = {
    disableUnderline: true,
    style: {
      backgroundColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: `1px solid ${theme.palette.text.primary}`
    }
  };
  const textInputLabelProps = {
    shrink: true,
    style: {
      transition: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
      transform: 'translate(0, -6px) scale(0.75)',
      ...theme.typography.h6
    }
  };
  const textSx = {
    mb: theme.spacing(1),
    mt: theme.spacing(1),
    color: theme.palette.text.primary,
    '& .MuiInputBase-inputMultiline': {
      color: theme.palette.text.primary,
      resize: 'vertical'
    },
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&:hover:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&.Mui-focused:before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&.Mui-focused:after': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      color: theme.palette.text.primary,
      backgroundColor: 'transparent'
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      '&.Mui-focused': {
        color: theme.palette.text.primary
      }
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.text.primary
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1
    }
  };
  // #endregion shared styles

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Sequence</DialogTitle>
      <DialogContent sx={{ pb: theme.spacing(3), height: '95vh', width: '700px' }}>
        <TextField
          autoFocus
          id="name"
          label="Name"
          type="text"
          variant="filled"
          fullWidth
          value={rootName}
          onChange={handleNameChange}
          InputProps={textInputProps}
          InputLabelProps={textInputLabelProps}
          sx={textSx}
        />
        <TextField
          autoFocus
          id="route"
          label="Route"
          type="text"
          variant="filled"
          fullWidth
          value={route}
          onChange={handleRouteChange}
          InputProps={textInputProps}
          InputLabelProps={textInputLabelProps}
          sx={textSx}
        />
        <TextField
          select
          SelectProps={{ multiple: true }}
          label="Depends On"
          value={dependsOnHavingSeen ?? []}
          onChange={handleDependsOnChange}
          variant="filled"
          fullWidth
          InputProps={textInputProps}
          InputLabelProps={textInputLabelProps}
          sx={textSx}
        >
          {dependsOnChoices?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            background: theme.palette.background.paper,
            pb: theme.spacing(3),
            pl: theme.spacing(6),
            pr: theme.spacing(6),
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000
          }}
        >
          <Button variant="clean-creative" onClick={onSave}>
            Update
          </Button>
          <Button variant="destructive-light" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
