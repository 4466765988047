import { useNavBar, useNavBarMenu } from '@/features/navbar';
import theme from '@/styles/theme';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import Close from '@mui/icons-material/Close';
import { AppBar, Box, Button, PaletteColor, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { StyledTextField } from './search-layout';

/**
 * Button linking to prism home page for bottom of side nav
 */
const SideNavPrismButton = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 20,
        height: isSmallScreen ? '140px' : '80px',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        pb: isSmallScreen ? theme.spacing(5) : 0,
        boxShadow: '0 -2px 2px -2px #ccc',
        backgroundColor: theme.palette.background.default
      }}
    >
      <a href="https://www.prism.bio">
        <Button
          sx={{
            color: theme.palette.green.dark,
            backgroundColor: theme.palette.green.main,
            borderRadius: theme.borderRadius,
            minWidth: '165px',
            fontWeight: 450
          }}
        >
          Try Prism
        </Button>
      </a>
    </Box>
  );
};

/**
 * Wraps side nav content (ex. vault map)
 * - optionally shows filter at the top
 */
const SideNavContent = ({
  filter,
  setFilter,
  children
}: {
  filter?: string;
  setFilter?: (s: string) => void;
  children?: ReactNode;
}) => {
  const [input, setInput] = useState('');
  const showFilter = setFilter && filter !== undefined;

  return (
    <Box sx={{ mt: 3 }}>
      {showFilter && (
        <StyledTextField
          fullWidth
          label="Filter"
          variant="standard"
          size="small"
          sx={{
            input: { fontSize: 12 },
            label: { fontSize: 12, paddingLeft: '9%' },
            paddingLeft: '9%',
            paddingRight: '9%'
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
          value={input}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              setFilter(input);
            }
          }}
        />
      )}
      <Box
        sx={{
          mt: 2,
          maxHeight: '90vh',
          overflowY: 'scroll',
          pb: '10vh',
          ...getHideScrollBarProps()
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

/**
 * Full responsive side nav layout
 * - optionally shows filter and "Try Prism" button
 */
export const SideNavLayout = ({
  children,
  showPrismButton = false,
  filter,
  setFilter
}: {
  children?: ReactNode;
  showPrismButton?: boolean;
  filter?: string;
  setFilter?: (s: string) => void;
}): JSX.Element => {
  const { setDrawerOpen } = useNavBarMenu();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box sx={{ height: '100%' }}>
      {isSmallScreen && (
        <Box
          sx={{
            position: 'fixed',
            top: theme.spacing(5),
            left: theme.spacing(5),
            color: theme.palette.blue.dark,
            zIndex: 10
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <Close style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          paddingTop: isSmallScreen ? theme.spacing(6) : 0,
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {isSmallScreen && <Box sx={{ backgroundColor: '#fff' }}></Box>}
        <Box sx={{ mt: isSmallScreen ? 2 : 0, mr: 3, ml: 3 }}>
          <SideNavContent setFilter={setFilter} filter={filter}>
            {children}
          </SideNavContent>
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>{showPrismButton && <SideNavPrismButton />}</Box>
    </Box>
  );
};

/**
 * Styled top nav that allows different colored top navigation bars on different pages
 *
 */
export const StyledTopNavWrapper = ({
  title = '',
  topNavColor = theme.palette.green,
  children,
  topLeftButton = null,
  topRightButton = null,
  productTitle = ''
}: {
  title?: string;
  topNavColor?: PaletteColor;
  children?: ReactNode;
  topLeftButton?: React.ReactNode;
  topRightButton?: React.ReactNode;
  productTitle?: string;
}): JSX.Element => {
  const { setTitle, setColor, setProductTitle } = useNavBar();

  useEffect(() => {
    setTitle(title);
    setColor(topNavColor);
    setProductTitle(productTitle);
  }, [title, setTitle, topNavColor, setColor, productTitle, setProductTitle]);

  return (
    <Box>
      <AppBar sx={{ backgroundColor: topNavColor.main, height: '100%', zIndex: -1 }} elevation={0}></AppBar>
      {topLeftButton && (
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(1), // Adjust to match AppBar's height
            left: theme.spacing(1),
            zIndex: 1400 // AppBar's zIndex is 1300 by default in MUI
          }}
        >
          {topLeftButton}
        </Box>
      )}
      {topRightButton && (
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(1), // Adjust to match AppBar's height
            right: theme.spacing(1),
            zIndex: 1400 // AppBar's zIndex is 1300 by default in MUI
          }}
        >
          {topRightButton}
        </Box>
      )}
      <Box
        sx={{
          position: 'fixed',
          top: 70,
          bottom: 0,
          left: 0,
          right: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderTopRightRadius: 45,
          overflow: 'hidden',
          background: '#F8F8F8'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
