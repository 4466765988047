export type DateFormats = 'year' | 'month' | 'day';
export const DATE_FORMAT_OPTIONS = ['year', 'month', 'day'];

/**
 * translates a typical UTC string into a more readable format
 * e.g.
 *  2023-10-27T21:28:15.000Z -> 2023-10-27
 *  if dateFormat is 'month' -> 2023-10
 *
 */
export const formatDateString = (dateString: string, dateFormat: DateFormats = 'day') => {
  const allFormatOptions = {
    year: ['year'],
    month: ['year', 'month'],
    day: ['year', 'month', 'day']
  };
  const selectedFormatOptions = Object.fromEntries(allFormatOptions[dateFormat].map((key) => [key, 'numeric']));
  const dateStr = new Date(dateString)
    .toLocaleDateString(undefined, selectedFormatOptions)
    .split('T')[0]
    .replace(/\//g, '-');

  return dateStr;
};

export const formatUTCAsWrittenMonthDayYear = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
};
