import axios from 'axios';
import { useCallback, useState } from 'react';
import { useAuthToken } from './use-auth-token';

type ReturnType<T> = {
  usePostFileApi: (data: object, file: File) => Promise<T>;
  data: T | null;
  error: Error | null | unknown;
};

export const usePostFileApi = <T,>(endpoint: string): ReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null | unknown>(null);

  const fetchToken = useAuthToken();

  const usePostFileApi = useCallback(
    async (body: object, file: File) => {
      try {
        const token = await fetchToken();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('body', JSON.stringify(body));
        const response = await axios
          .create()
          .post(
            `${
              import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
            }/${endpoint}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
              }
            }
          );

        setData(response.data);
        return response.data;
      } catch (err) {
        setError(err);
        throw err;
      }
    },
    [endpoint, fetchToken]
  );

  return { usePostFileApi, data, error };
};
