import { Route, Routes } from 'react-router-dom';
import { DocumentUpload, KFLuxury } from '../components';
import { LibraryBrowse } from '../components/browsing';
import { FileView } from '../components/file-view';
import { CollectionSettings, DocumentSettings, LibrarySettings } from '../components/settings';

export const KFV2Routes = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<KFLuxury />}
        children={
          <>
            <Route path=":projectId" element={<LibraryBrowse />} />
            <Route path=":projectId/settings" element={<LibrarySettings />} />
            <Route path=":projectId/collections/:collectionId" element={<LibraryBrowse />} />
            <Route path=":projectId/collections/:collectionId/settings" element={<CollectionSettings />} />
            <Route path=":projectId/collections/:collectionId/upload" element={<DocumentUpload />} />
            <Route path=":projectId/collections/:collectionId/documents/:documentId" element={<FileView />} />
            <Route
              path=":projectId/collections/:collectionId/documents/:documentId/settings"
              element={<DocumentSettings />}
            />
          </>
        }
      />
    </Routes>
  );
};
