import { createContext, useContext } from 'react';
import { FeatureToggleExperienceSettings, FeatureToggleFlagSettings } from '../types';

// #region FeatureToggle Context
type FeatureToggleContextType = {
  experiences?: FeatureToggleExperienceSettings;
  flags?: FeatureToggleFlagSettings;
};
const FeatureToggleContext = createContext<FeatureToggleContextType | undefined>(undefined);
export const FeatureToggleProvider = FeatureToggleContext.Provider;
export function useFeatureToggle(): FeatureToggleContextType {
  const context = useContext(FeatureToggleContext);
  if (context === undefined) {
    throw new Error('useFeatureToggle must be used within a FeatureToggleProvider');
  }
  return context;
}
// #endregion FeatureToggle Context
