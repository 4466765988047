import {
  FeatureToggleExperienceSettings,
  FeatureToggleFlagSettings,
  FeatureToggleProvider
} from '@/features/feature-toggle';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { useAuth0 } from '@auth0/auth0-react';
import { SetStateAction, useEffect, useState } from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';
import { LoadingScreen } from './loading-screen';
import { OnboardingDialog } from './onboarding-dialog';

export const RouteGuard = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  const [onboardingSteps, setOnboardingSteps] = useState<{ title: string; content: string; position?: string }[]>([]);
  const [onboardingId, setOnboardingId] = useState<string>();
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);

  const [userExists, setUserExists] = useState<boolean | null>(null);
  const [isCheckingUser, setIsCheckingUser] = useState<boolean>(true);
  const [featureToggleConfig, setFeatureToggleConfig] = useState<{
    experiences: FeatureToggleExperienceSettings;
    flags: FeatureToggleFlagSettings;
  }>();

  const { getData: getMe } = useGetApiViaCallback<{ emailVerified: boolean }>('auth/me');
  const { getData: getFeatureToggles } = useGetApiViaCallback<{
    experiences: FeatureToggleExperienceSettings;
    flags: FeatureToggleFlagSettings;
  }>('feature-toggle/configurationForUser');

  useEffect(() => {
    const checkIfUserExists = async () => {
      try {
        const response = await getMe();
        setUserExists(response.emailVerified); // use email verified from the backend to determine
        if (response.emailVerified) {
          // if they're verified, load up feature toggles for awareness downstream to drive user-specific experiences and functionality
          const featureToggleResponse = await getFeatureToggles();
          setFeatureToggleConfig(featureToggleResponse);
        }
        setIsCheckingUser(false);
      } catch (error) {
        console.error('Failed to check user:', error);
      }
    };

    if (isAuthenticated) {
      checkIfUserExists();
    }
  }, [getFeatureToggles, getMe, isAuthenticated]);

  /**
   * Onboarding logic for whether or not to present this ot the user at this route
   */
  useEffect(() => {
    const nullifyOnboarding = () => {
      setOnboardingId('');
      setOnboardingSteps([]);
      setOnboardingOpen(false);
    };

    const handleOnboarding = (
      key: SetStateAction<string | undefined>,
      routeObj: {
        id?: string;
        route?: string;
        dependsOnHavingSeen?: string[];
        steps: { title: string; content: string; position?: string }[];
      }
    ) => {
      const hasDependencies = routeObj.dependsOnHavingSeen;
      const hasSeenAllDependencies =
        hasDependencies &&
        routeObj.dependsOnHavingSeen?.every(
          (depKey) => featureToggleConfig?.experiences?.onboarding?.screens?.[depKey]
        );

      if (!hasDependencies || hasSeenAllDependencies) {
        setOnboardingId(key);
        setOnboardingSteps(routeObj.steps);
        setOnboardingOpen(true);
      } else {
        nullifyOnboarding();
      }
    };

    if (!featureToggleConfig?.experiences?.onboarding?.enabled) {
      nullifyOnboarding();
      return;
    }

    Object.entries(featureToggleConfig?.experiences?.onboarding?.configuration || {}).some(([key, routeObj]) => {
      if (matchPath({ path: `/v2/knowledge-finder/${routeObj.route}`, end: true }, location.pathname)) {
        if (!featureToggleConfig?.experiences?.onboarding?.screens?.[key]) {
          handleOnboarding(key, routeObj);
          return true;
        }
      } else {
        nullifyOnboarding();
      }
      return false;
    });
  }, [featureToggleConfig?.experiences?.onboarding, location]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/landing" replace state={{ from: location.pathname }} />;
  }

  if (isCheckingUser) {
    return <LoadingScreen />;
  }

  if (!userExists) {
    // user won't exist if they're not verified as they won't be created in the DB
    return <Navigate to={`/pub/verification-pending`} state={{ from: location.pathname }} />;
  }

  return (
    <FeatureToggleProvider
      value={{
        experiences: featureToggleConfig?.experiences || {},
        flags: featureToggleConfig?.flags || {}
      }}
    >
      {children}
      {onboardingId && (
        <OnboardingDialog
          steps={onboardingSteps}
          id={onboardingId}
          open={onboardingOpen}
          setOpen={setOnboardingOpen}
          setFeatureToggles={setFeatureToggleConfig}
        />
      )}
    </FeatureToggleProvider>
  );
};
