import { getHideScrollBarProps } from '@/utils/theme-utils';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Fade, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';

const transitionDuration = 1200;
const panelTransition = `width ${transitionDuration}ms`;

const PrismIcon = ({ onClick }: { onClick: () => void }) => (
  <span
    onClick={() => onClick()}
    style={{ fontSize: '32px', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
  >
    ✦
  </span>
);

const SideNavPanel = ({
  isOpen,
  setIsOpen,
  width,
  children
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  width: string | number;
  children: ReactNode;
}) => {
  return (
    <Box
      width={width}
      height="100%"
      sx={{ position: 'relative', transition: panelTransition, display: { md: 'block', xs: 'none' } }}
    >
      <Box position="absolute" sx={{ top: 0, bottom: 0, left: 0, right: 0 }}>
        {isOpen && (
          // fade in content to prevent shifty text wrapping
          <Fade in={isOpen} timeout={1200} style={{ transitionDelay: `${transitionDuration / 2}ms` }}>
            <Box>{children}</Box>
          </Fade>
        )}
        <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
          <IconButton disableRipple onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const ChatPanel = ({
  isOpen,
  setIsOpen,
  width,
  children
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  width: string | number;
  children: ReactNode;
}) => {
  return (
    <Box width={width} height="100%" sx={{ position: 'relative', transition: panelTransition }}>
      <Box position="absolute" sx={{ top: 0, bottom: 0, left: 0, right: 0 }}>
        <Box>
          <Fade in={isOpen} timeout={transitionDuration} style={{ transitionDelay: `${transitionDuration / 2}ms` }}>
            <Box display={isOpen ? 'block' : 'none'}>{children}</Box>
          </Fade>
          <Fade in={!isOpen} style={{ transitionDelay: `${transitionDuration}ms` }}>
            <Box display={isOpen ? 'none' : 'block'} sx={{ pt: isOpen ? 0 : 2 }}>
              <PrismIcon onClick={() => setIsOpen(!isOpen)} />
            </Box>
          </Fade>
        </Box>
        <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
          <IconButton disableRipple onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const MiddlePanel = ({ width, children }: { width: string | number; children: ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      width={width}
      position="relative"
      sx={{ transition: panelTransition, background: theme.palette.background.paper }}
    >
      <Box
        position="absolute"
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'scroll',
          ...getHideScrollBarProps()
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const closedPanelWidth = '50px';
const openPanelWidth = '320px';
export const ThreePanelLayout = ({
  sideNavChildren,
  middlePanelChildren,
  chatPanelChildren
}: {
  sideNavChildren: ReactNode;
  middlePanelChildren: ReactNode;
  chatPanelChildren: ReactNode;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [sideNavOpen, setSideNavOpen] = useState(true);
  const [chatOpen, setChatOpen] = useState(true);

  // #region panel width calculations
  // if mobile, we're using the menu button for the sidenav (for now)
  const sideNavWidth = isMobile ? '0px' : sideNavOpen ? openPanelWidth : closedPanelWidth;
  // if just chat is open, it should be 40% of the screen
  // if chat and sidenav are open, chat is 300px
  const chatPanelWidth = chatOpen && !sideNavOpen ? '40%' : chatOpen && sideNavOpen ? openPanelWidth : closedPanelWidth;

  // main panel takes up rest of available screen
  const mainPanelWidth = `calc(100% -  ${sideNavWidth} - ${chatPanelWidth})`;
  // #end region panel width calculations

  return (
    <Box display="flex" sx={{ height: { sm: 'calc(100vh - 64px)', xs: 'calc(100vh - 48px)' } }}>
      <SideNavPanel width={sideNavWidth} isOpen={sideNavOpen} setIsOpen={setSideNavOpen}>
        {sideNavChildren}
      </SideNavPanel>
      <MiddlePanel width={mainPanelWidth}>{middlePanelChildren}</MiddlePanel>
      <ChatPanel width={chatPanelWidth} isOpen={chatOpen} setIsOpen={setChatOpen}>
        {chatPanelChildren}
      </ChatPanel>
    </Box>
  );
};
