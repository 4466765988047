import { getBrandedColorFromString } from '@/utils/color-utils';
import OpenIcon from '@mui/icons-material/OpenInFull';
import { Box, Chip, IconButton, Popover, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Tag } from '../prism-card';

const MAX_CHIP_ROWS = 2;

export const ExpandingChipsCellRender = ({
  value,
  cellWidth,
  cellHeight,
  maxChipRows = MAX_CHIP_ROWS
}: {
  value: Tag[];
  cellWidth: number;
  cellHeight: number;
  maxChipRows?: number;
}) => {
  const [visibleChips, setVisibleChips] = useState<ReactNode[]>([]);
  const [overflowChips, setOverflowChips] = useState<ReactNode[]>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const theme = useTheme();

  useEffect(() => {
    const chips = value?.map((tag: Tag) => (
      <Chip
        key={tag.uuid}
        variant="filled"
        size="small"
        sx={{
          height: 20,
          mt: 0.5,
          mr: 0.1,
          ml: 0.1,
          backgroundColor: theme.palette[getBrandedColorFromString(tag.name)].main,
          fontFamily: theme.typography.body2.fontFamily,
          fontSize: 10
        }}
        label={tag.name}
      />
    ));

    const chipsPerRow = Math.floor(cellWidth / 132); // Adjust this based on your styling
    const maxVisibleChips = maxChipRows * chipsPerRow;
    setVisibleChips(chips?.slice(0, maxVisibleChips));
    setOverflowChips(chips);
  }, [value, cellWidth, cellHeight, maxChipRows, theme]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal'
      }}
    >
      <Box>
        {overflowChips?.length !== visibleChips?.length && (
          <IconButton
            size="medium"
            onClick={(event) => {
              event.stopPropagation();
              setPopoverOpen(true);
              setAnchorEl(event.currentTarget);
            }}
          >
            <OpenIcon />
          </IconButton>
        )}
        {visibleChips}
      </Box>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ p: 2, background: theme.palette.background.default, maxWidth: 250 }}>{overflowChips}</Box>
      </Popover>
    </Box>
  );
};
