import { usePostApi } from '@/hooks/use-post-api';
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Typography, lighten, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProjectResponse {
  uuid: string;
}

export const CreateLibraryButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isCreating, setIsCreating] = useState(false);

  const { postData } = usePostApi<ProjectResponse>('project/library');

  const handleClick = async () => {
    try {
      setIsCreating(true);
      const project = await postData({
        name: 'New Library'
      });
      navigate(`/v2/knowledge-finder/${project.uuid}`);
    } catch (error) {
      console.error('Failed to make async request:', error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h2">Create</Typography>
      <IconButton
        size="large"
        sx={{
          ml: theme.spacing(2),
          background: theme.palette.primary.lightest,
          color: theme.palette.primary.main,
          ':hover': {
            background: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
            boxShadow: `0em 0em 0.4em 0em ${lighten(theme.palette.green.light, 0.2)}`
          }
        }}
        onClick={handleClick}
        disabled={isCreating}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};
