import { useNavBar } from '@/features/navbar';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { LibraryProject, LibraryProjectUserAccess } from '@/types/projects';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LibraryProvider } from '../stores/library-context';
import { LibraryFrontDesk } from './front-desk';
import { LibrarySelection } from './library-selection';

/**
 * Home for knowledge finder w new designs
 */
export const KFLuxury = () => {
  const theme = useTheme();
  const { projectId } = useParams<{ projectId: string }>();
  // #region vars
  const [library, setLibrary] = useState<LibraryProject | undefined>(undefined);
  const [projectUserAccess, setProjectUserAccess] = useState<LibraryProjectUserAccess | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldReinitCollections, setShouldReinitCollections] = useState<boolean>(true);
  // #endregion vars

  const { getData: getProjectAccess } = useGetApiViaCallback<LibraryProjectUserAccess>(`project/${projectId}`);

  // #region nav bar
  const { setColor, setProductTitle, setParentBreadcrumb } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.primary);
    setProductTitle('Knowledge Finder');
  }, [setColor, setProductTitle, theme]);

  useEffect(() => {
    if (library) {
      setParentBreadcrumb({ name: library.name, route: `/v2/knowledge-finder/${library.uuid}` });
    } else {
      setParentBreadcrumb(undefined);
    }
  }, [library, setParentBreadcrumb]);
  // #end region nav bar

  useEffect(() => {
    const getProject = async () => {
      setIsLoading(true);
      try {
        const projectAccess = await getProjectAccess();
        setProjectUserAccess(projectAccess);
        setLibrary(projectAccess.project);
      } catch (e) {
        console.error(`Error getting project: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (projectId) {
      getProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  /**
   * when a library is set to undefined, set it to the project from the projectUserAccess
   */
  useEffect(() => {
    if (library === undefined) {
      setLibrary(projectUserAccess?.project);
    }
  }, [library, projectUserAccess]);

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : projectId && projectUserAccess ? (
        <LibraryProvider
          value={{
            currentUser: projectUserAccess?.user,
            library: library as LibraryProject,
            setLibrary: setLibrary,
            libraryPermission: projectUserAccess?.permission,
            shouldReinitCollections,
            setShouldReinitCollections
          }}
        >
          <LibraryFrontDesk />
        </LibraryProvider>
      ) : (
        <LibrarySelection />
      )}
    </Box>
  );
};
