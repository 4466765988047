import { LoadingScreen } from '@/components/loading-screen';
import { FeatureToggleExperiences, useFeatureToggle } from '@/features/feature-toggle';
import { useNavBar } from '@/features/navbar';
import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LandingRedirectComponent = () => {
  const theme = useTheme();
  const { experiences } = useFeatureToggle();
  const navigate = useNavigate();

  const { setColor, setProductTitle } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.black);
    setProductTitle(' ');
  }, [setColor, setProductTitle, theme.palette.black]);

  useEffect(() => {
    if (window.location.pathname.length > 1) {
      /**
       * if there's more than just the root found in the pathname,
       * follow that through
       */
      navigate(location.pathname);
    } else {
      /**
       * if there's no pathname, redirect to the preferred landing page
       * set in configuration
       */
      const defaultRedirect = experiences?.[FeatureToggleExperiences.PreferredLanding];
      if (defaultRedirect) {
        navigate(defaultRedirect as string);
      } else {
        /**
         * default landing when not being redirected and has no preferred landing
         */
        navigate('/v2/knowledge-finder');
      }
    }
  }, [experiences, navigate]);

  return <LoadingScreen />;
};
