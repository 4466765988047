import { Box, TextField, Theme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface RenderTextFieldProps {
  label: string | React.ReactNode;
  value?: string | undefined;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  theme: Theme;
  placeholder?: string;
  maxLength?: number;
}

export const ReusableSettingsTextField = ({
  label,
  value,
  name,
  onChange,
  theme,
  placeholder = '',
  maxLength = 255
}: RenderTextFieldProps) => {
  const textFieldRef = useRef<HTMLDivElement>(null);
  const [charsPerLine, setCharsPerLine] = useState(100);

  /**
   * dynamically calculate when a new line should appear based
   * on screen size and best estimate for text size
   */
  useEffect(() => {
    const calculateCharsPerLine = () => {
      const textField = textFieldRef.current;
      if (textField) {
        const style = window.getComputedStyle(textField);
        const textFieldWidth = textField.clientWidth;
        const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
        const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
        const scrollBarWidth = textField.offsetWidth - textField.clientWidth;
        const contentWidth = textFieldWidth - padding - border - scrollBarWidth;

        const sampleText = 'abcdefghijklmnopqrstuvwxyz';
        const sampleElement = document.createElement('div');
        sampleElement.style.font = style.font; // Use computed style for font
        sampleElement.style.position = 'absolute';
        sampleElement.style.visibility = 'hidden';
        sampleElement.style.whiteSpace = 'nowrap'; // Ensure text doesn't wrap
        sampleElement.innerText = sampleText;
        document.body.appendChild(sampleElement);
        const averageCharWidth = sampleElement.offsetWidth / sampleText.length;
        document.body.removeChild(sampleElement);

        // Add a small value to the content width to make wrapping more conservative
        const conservativeBuffer = 40; // Adjust this value as needed
        const newContentWidth = contentWidth + conservativeBuffer;
        const newCharsPerLine = Math.floor(newContentWidth / averageCharWidth);
        setCharsPerLine(newCharsPerLine);
      }
    };

    calculateCharsPerLine();
    window.addEventListener('resize', calculateCharsPerLine);

    return () => {
      window.removeEventListener('resize', calculateCharsPerLine);
    };
  }, []);

  const calculateRows = (description: string = '') => {
    return Math.max(1, Math.ceil(description.length / charsPerLine));
  };

  return (
    <Box ref={textFieldRef}>
      <TextField
        label={label}
        value={value}
        multiline
        rows={calculateRows(value)}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        variant="filled"
        fullWidth
        inputProps={{ maxLength }}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: 'transparent',
            paddingLeft: 0,
            paddingRight: 0,
            borderBottom: `1px solid ${theme.palette.text.primary}`
          }
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            transition: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.secondary.light,
            transform: 'translate(0, -6px) scale(0.75)',
            ...theme.typography.h6
          }
        }}
        sx={{
          mb: theme.spacing(1),
          mt: theme.spacing(1),
          color: theme.palette.text.primary,
          '& .MuiInputBase-inputMultiline': {
            color: theme.palette.text.primary,
            resize: 'vertical'
          },
          '& .MuiFilledInput-root': {
            '&:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&:hover:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&.Mui-focused:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&.Mui-focused:after': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            color: theme.palette.text.primary,
            backgroundColor: 'transparent'
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.primary,
            '&.Mui-focused': {
              color: theme.palette.text.primary
            }
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary
          },
          '& .MuiInputBase-input::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1
          }
        }}
      />
    </Box>
  );
};
