import { useNavBar } from '@/features/navbar';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react'; // Import useState
import { Page } from '../components/page';

export default function EmailVerifiedPage() {
  const theme = useTheme();
  const [countdown, setCountdown] = useState(5); // Initialize countdown state with 5 seconds
  const { setColor, setProductTitle } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.black);
    setProductTitle(' ');
  }, [setColor, setProductTitle, theme.palette.black]);

  useEffect(() => {
    // Function to update countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Set a timer for 5 seconds to redirect
    const timer = setTimeout(() => {
      window.location.href = '/v2/knowledge-finder'; // route em to the base dash
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup function to clear the timer and interval if the component unmounts
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Page>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          maxWidth: 600,
          minHeight: 300,
          m: 'auto',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box sx={{ mt: theme.spacing(7), textAlign: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Email Verified
          </Typography>
          <Typography variant="subtitle1" sx={{ pl: theme.spacing(3), pr: theme.spacing(3), mt: theme.spacing(5) }}>
            Thank you for confirming your account.
          </Typography>
          <Typography variant="subtitle1" sx={{ pl: theme.spacing(3), pr: theme.spacing(3), mt: theme.spacing(3) }}>
            You will now be redirected in {countdown} seconds.
          </Typography>
        </Box>
      </Card>
    </Page>
  );
}
