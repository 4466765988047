import * as Sentry from '@sentry/react';

const sentryConfig: Sentry.BrowserOptions = {
  dsn: 'https://87638184416c0695311270edddc9b1e4@o1022556.ingest.sentry.io/4505794773450752',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.05, // monitor 5% of the transactions for performance
  enabled: !!import.meta.env.VITE_SENTRY_ENABLED,
  enableTracing: !!import.meta.env.VITE_SENTRY_ENABLED,
  environment: import.meta.env.VITE_ENVIRONMENT,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    if (event.request?.url) {
      // Use URL object to manipulate the URL
      const url = new URL(event.request.url);
      // Remove query string
      url.search = '';
      // Update the event's request URL without query parameters
      event.request.url = url.toString();
    }
    return event;
  }
};

export default sentryConfig;
