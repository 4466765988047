import { ExpandingChipsCellRender } from '@/components/data-grid';
import { UploadProcessType } from '@/types/data-uploads';
import { DateFormats, formatDateString } from '@/utils/date-utils';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { NavigateFunction } from 'react-router-dom';

function formatBytes(bytes: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    unit: 'byte'
  });

  return formatter.format(bytes);
}

export const getTableColumns = ({
  hoveredRow,
  currentDateFormat,
  savedColumnWidths,
  theme,
  navigate,
  projectId,
  collectionId,
  metadataKeys
}: {
  hoveredRow: string;
  currentDateFormat: DateFormats;
  savedColumnWidths: { [key: string]: number };
  theme: Theme;
  navigate: NavigateFunction;
  projectId: string;
  collectionId?: string;
  metadataKeys?: string[];
}) => {
  const metadataColumns =
    metadataKeys?.map((key) => ({
      field: key,
      title: key,
      width: 150,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Tooltip title={<Typography variant="body2">{cell.value || 'N/A'}</Typography>} placement="top" arrow>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
              variant="body2"
            >
              {cell.value || 'N/A'}
            </Typography>
          </Tooltip>
        );
      }
    })) || [];

  const columns = [
    {
      field: 'title',
      title: 'File',
      width: 200,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block',
              maxWidth: '100%'
            }}
            variant="body2"
          >
            {cell.row.displayName ? cell.row.displayName : cell.row.title}
          </Typography>
        );
      }
    },
    // if they're using custom metadata, probably wanna see this before any others
    ...metadataColumns,
    {
      field: 'displayDate',
      title: 'Date',
      description: currentDateFormat,
      width: 90,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
            variant="body2"
          >
            {cell.value ? `${formatDateString(cell.value, currentDateFormat)}` : ''}
          </Typography>
        );
      }
    },
    {
      field: 'uploadedBy',
      title: 'By',
      width: 110,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
            variant="body2"
          >
            {`${cell.value.firstName} ${cell.value.lastName}`}
          </Typography>
        );
      }
    },
    {
      field: 'contact',
      title: 'Contact',
      width: 130,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block',
              maxWidth: '100%'
            }}
            variant="body2"
          >
            {cell.value}
          </Typography>
        );
      }
    },
    {
      field: 'uploadStatus',
      title: 'Size',
      width: 60,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
            variant="body2"
          >
            {`${formatBytes(
              cell.value.find(
                (status: { processType: UploadProcessType }) => status.processType === UploadProcessType.INITIAL_UPLOAD
              ).dataCount
            )}`}
          </Typography>
        );
      },
      sortComparator: (a: GridRenderCellParams['value'], b: GridRenderCellParams['value']) => {
        // todo: cache this lookup if it becomes a performance issue
        const aSize = a.find(
          (status: { processType: UploadProcessType }) => status.processType === UploadProcessType.INITIAL_UPLOAD
        ).dataCount;
        const bSize = b.find(
          (status: { processType: UploadProcessType }) => status.processType === UploadProcessType.INITIAL_UPLOAD
        ).dataCount;
        return aSize - bSize;
      }
    },
    {
      field: 'content',
      title: 'Summary',
      width: 150,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
            variant="body2"
          >
            {cell.value || 'No summary available.'}
          </Typography>
        );
      }
    },
    {
      headerClassName: 'h3',
      field: 'tags',
      title: 'Tags',
      width: 220,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <ExpandingChipsCellRender
          value={params.value}
          cellWidth={params.colDef.computedWidth}
          cellHeight={params.row.height}
          maxChipRows={1}
        />
      )
    },
    {
      field: 'actions',
      title: ' ',
      width: 10,
      minWidth: 0,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (hoveredRow === params.id.toString()) {
          return (
            <IconButton
              sx={{ color: theme.palette.background.paper }}
              onClick={(event) => {
                event.stopPropagation();
                collectionId
                  ? navigate(
                      `/v2/knowledge-finder/${projectId}/collections/${collectionId}/documents/${params.row.uuid}/settings`
                    )
                  : // if in the library context, default to the first collection found in the document
                    navigate(
                      `/v2/knowledge-finder/${projectId}/collections/${params.row.collections[0].uuid}/documents/${params.row.uuid}/settings`
                    );
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          );
        } else return null;
      }
    }
  ];

  const finalColumns = columns.map((col) => ({ ...col, width: savedColumnWidths[col.field] || col.width }));
  return finalColumns;
};
