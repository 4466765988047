import { FeatureToggleExperienceSettings, FeatureToggleFlagSettings } from '@/features/feature-toggle';
import { usePostApi } from '@/hooks/use-post-api';
import { CheckBoxOutlineBlank, Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { CustomMarkdownRenderer } from './custom-markdown-renderer';

interface OnboardingDialogProps {
  id: string;
  steps: {
    content: string;
    title: string;
    position?: string;
  }[];
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setFeatureToggles: React.Dispatch<
    SetStateAction<
      | {
          experiences: FeatureToggleExperienceSettings;
          flags: FeatureToggleFlagSettings;
        }
      | undefined
    >
  >;
}

/**
 * supports dialogs with one or more steps
 */
export const OnboardingDialog: React.FC<OnboardingDialogProps> = ({ id, steps, open, setOpen, setFeatureToggles }) => {
  const theme = useTheme();
  const [currentStep, setCurrentStep] = useState(0);

  // #region Dialog handlers
  const handleClose = () => setOpen(false);
  const nextStep = () => setCurrentStep((prev) => Math.min(steps.length - 1, prev + 1));
  // #endregion Dialog handlers

  // #region API handlers
  const { postData: postProgressOnboarding } = usePostApi<void>(`feature-toggle/actions/updateOnboardingProgression`);
  const { postData: postDisableOnboarding } = usePostApi<void>(`feature-toggle/actions/toggleOnboardingExperience`);
  // #endregion API handlers

  return (
    <Box>
      <Dialog
        sx={{
          width: '500px',
          mx: 'auto', // Centers the dialog by default
          ...(steps[currentStep].position === 'left' && { marginLeft: '10%', marginRight: 'auto' }),
          ...(steps[currentStep].position === 'right' && { marginRight: '10%', marginLeft: 'auto' }),
          '& .MuiPaper-root': {
            // Targeting the Paper component inside the Dialog
            borderRadius: 0
          }
        }}
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            '&:hover': {
              color: theme.palette.green.medium,
              backgroundColor: 'transparent'
            },
            mr: 0
          }}
          onClick={() => {
            handleClose();
          }}
          aria-label="close"
        >
          <Close />
        </IconButton>
        <DialogTitle variant="h3" sx={{ p: theme.spacing(5), pt: theme.spacing(7), textAlign: 'center', fontSize: 36 }}>
          {steps[currentStep].title || `Step ${currentStep + 1} of ${steps.length}`}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ color: theme.palette.gray.lightest }}>
            <CustomMarkdownRenderer content={steps[currentStep].content} />
          </Box>
        </DialogContent>
        {currentStep === steps.length - 1 && (
          <Button
            variant="clean-creative"
            color="primary"
            sx={{ fontSize: 20, pb: theme.spacing(3) }}
            onClick={async () => {
              await postProgressOnboarding({ onboardingStepId: id });
              setFeatureToggles((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  experiences: {
                    ...prev.experiences,
                    onboarding: {
                      ...prev.experiences.onboarding,
                      enabled: prev.experiences?.onboarding?.enabled as boolean,
                      screens: prev.experiences?.onboarding?.screens
                        ? { ...prev.experiences?.onboarding?.screens, [id]: true }
                        : { [id]: true },
                      configuration: prev.experiences?.onboarding?.configuration || {}
                    }
                  }
                };
              });
              handleClose();
            }}
          >
            OK
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button
            variant="clean-creative"
            color="primary"
            sx={{ fontSize: 20, pb: theme.spacing(3) }}
            onClick={() => nextStep()}
          >
            CONTINUE
          </Button>
        )}
        <Box
          onClick={async () => {
            await postDisableOnboarding({ enabled: false });
            setFeatureToggles((prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                experiences: {
                  ...prev.experiences,
                  onboarding: {
                    ...prev.experiences.onboarding,
                    enabled: false,
                    screens: prev.experiences?.onboarding?.screens || {},
                    configuration: prev.experiences?.onboarding?.configuration || {}
                  }
                }
              };
            });
            handleClose();
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            pb: theme.spacing(3)
          }}
        >
          <IconButton
            size="small"
            sx={{
              marginRight: theme.spacing(1)
            }}
          >
            <CheckBoxOutlineBlank sx={{ color: theme.palette.primary.light }} />
          </IconButton>
          <Typography
            variant="button"
            sx={{
              color: theme.palette.primary.contrastText,
              fontSize: 12,
              fontWeight: 600
            }}
          >
            I don't need help
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};
