import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  lighten,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TatsuDemonstrations, TatsuInvestigations, TatsuOrchestrations } from '../types/functionality';
import { FnTypes, QueryParams } from '../types/query-params';

export const TatsuSideNavContent = () => {
  const theme = useTheme();

  // #region search param management
  const [searchParams, setSearchParams] = useSearchParams();
  const [chosenType, setChosenType] = useState<string | null>(searchParams.get(QueryParams.Type) || null);
  const [chosenFunction, setChosenFunction] = useState<string | null>(searchParams.get(QueryParams.Function) || null);
  const handleSearchParamUpdate = (type: FnTypes, fn: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(QueryParams.Type, type);
    newParams.set(QueryParams.Function, fn);
    setSearchParams(newParams);
  };
  useEffect(() => {
    setChosenType(searchParams.get(QueryParams.Type));
    setChosenFunction(searchParams.get(QueryParams.Function));
  }, [searchParams]);
  // #endregion search param management

  const demonstrations = Object.values(TatsuDemonstrations);
  const investigations = Object.values(TatsuInvestigations);
  const orchestrations = Object.values(TatsuOrchestrations);

  return (
    <Box
      sx={{
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
      }}
    >
      <Accordion sx={{ backgroundColor: theme.palette.background.default }} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMore style={{ color: theme.palette.primary.contrastText }} />}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '1rem',
              fontWeight: '800',
              color: chosenType === FnTypes.Orchestration ? lighten(theme.palette.red.light, 0.2) : 'black'
            }}
          >
            Orchestrations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {orchestrations.map((orchestration, i) => (
              <ListItemButton
                key={i}
                onClick={() => handleSearchParamUpdate(FnTypes.Orchestration, orchestration)}
                sx={{
                  backgroundColor:
                    chosenType === FnTypes.Orchestration && chosenFunction === orchestration
                      ? lighten(theme.palette.background.default, 0.1)
                      : '',
                  color:
                    chosenType === FnTypes.Orchestration && chosenFunction === orchestration
                      ? lighten(theme.palette.red.light, 0.2)
                      : '',
                  paddingLeft: theme.spacing(3),
                  marginTop: theme.spacing(1)
                }}
              >
                <ListItemText primary={orchestration} />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: theme.palette.background.default }} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMore style={{ color: theme.palette.primary.contrastText }} />}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '1rem',
              fontWeight: '800',
              color: chosenType === FnTypes.Investigation ? lighten(theme.palette.red.light, 0.2) : 'black'
            }}
          >
            Investigations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {investigations.map((investigation, i) => (
              <ListItemButton
                key={i}
                onClick={() => handleSearchParamUpdate(FnTypes.Investigation, investigation)}
                sx={{
                  backgroundColor:
                    chosenType === FnTypes.Investigation && chosenFunction === investigation
                      ? lighten(theme.palette.background.default, 0.1)
                      : '',
                  color:
                    chosenType === FnTypes.Investigation && chosenFunction === investigation
                      ? lighten(theme.palette.red.light, 0.2)
                      : '',
                  paddingLeft: theme.spacing(3),
                  marginTop: theme.spacing(1)
                }}
              >
                <ListItemText primary={investigation} />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: theme.palette.background.default }} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMore style={{ color: theme.palette.primary.contrastText }} />}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '1rem',
              fontWeight: '800',
              color: chosenType === FnTypes.Demonstration ? lighten(theme.palette.red.light, 0.2) : 'black'
            }}
          >
            Demonstrations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {demonstrations.map((demonstration, i) => (
              <ListItemButton
                key={i}
                onClick={() => handleSearchParamUpdate(FnTypes.Demonstration, demonstration)}
                sx={{
                  backgroundColor:
                    chosenType === FnTypes.Demonstration && chosenFunction === demonstration
                      ? lighten(theme.palette.background.default, 0.1)
                      : '',
                  color:
                    chosenType === FnTypes.Demonstration && chosenFunction === demonstration
                      ? lighten(theme.palette.red.light, 0.2)
                      : '',
                  paddingLeft: theme.spacing(3),
                  marginTop: theme.spacing(1)
                }}
              >
                <ListItemText primary={demonstration} />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
