import { CircularProgress, Grid, Theme } from '@mui/material';
import { FileMetadata } from '../../types';
import { DocumentCard } from '../browsing/document-card';
import { NoSearchResults } from '../browsing/empty-views';
import { ResultSnippet } from './result-snippet';

export const SearchResults = ({
  results,
  theme,
  isGrouped,
  resultsLoading
}: {
  results: FileMetadata[];
  theme: Theme;
  isGrouped: boolean;
  resultsLoading: boolean;
}) => {
  return (
    <Grid container sx={{ mt: theme.spacing(5) }}>
      {resultsLoading ? (
        <CircularProgress />
      ) : results.length > 0 ? (
        <>
          {results.map((result) =>
            isGrouped ? (
              <Grid key={result.uuid} minWidth={350} item sm={6} xs={12}>
                <DocumentCard document={result} />
              </Grid>
            ) : (
              <ResultSnippet key={result.uuid} result={result} objectURL={result.previewUrl} />
            )
          )}
        </>
      ) : (
        <NoSearchResults theme={theme} />
      )}
    </Grid>
  );
};
