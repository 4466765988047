/**
 * for any users that aren't SSOing or have a bogus name, we'll use their email
 */
export const getNameOrEmail = ({
  email,
  firstName,
  lastName
}: {
  email?: string;
  firstName?: string;
  lastName?: string;
}) => {
  if (firstName === 'First') {
    return email;
  }
  return `${firstName} ${lastName}`;
};
