type BrandedColorKey = 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'violet' | 'pink';

/**
 * given a string, deterministically returns one of our branded colors
 */
export const getBrandedColorFromString = (inputText: string): BrandedColorKey => {
  const colorKeys: BrandedColorKey[] = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'violet', 'pink'];

  let hash = 0;
  for (let i = 0; i < inputText.length; i++) {
    const char = inputText.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  const index = Math.abs(hash) % colorKeys.length;
  return colorKeys[index];
};
