import { usePostApi } from '@/hooks/use-post-api';
import DualRing from '@/styles/loaders/dual-ring';
import { SNACKBAR_HANG_TIME } from '@/types/constants';
import { User, UserPermission } from '@/types/user';
import { ArrowRightAlt } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { AxiosError } from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

export const AccessAndPermissionsToggle = ({
  onToggle,
  theme
}: {
  onToggle: (open: boolean) => void;
  theme: Theme;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    onToggle(newIsOpen);
  };

  return (
    <Box onClick={handleToggle} style={{ cursor: 'pointer' }} sx={{ pt: theme.spacing(3) }}>
      <Typography variant="button" sx={{ color: theme.palette.secondary.light, fontSize: '1rem' }}>
        ACCESS & PERMISSIONS {isOpen ? '▼' : '►'}
      </Typography>
    </Box>
  );
};

// Dialog to confirm removal of a user
export const RevokeAccessDialog = ({
  openConfirmRevokeUserAccessModal,
  setOpenConfirmRevokeUserAccessModal,
  revokeUserEmail,
  handleRemoveAccess,
  entityType
}: {
  openConfirmRevokeUserAccessModal: boolean;
  setOpenConfirmRevokeUserAccessModal: React.Dispatch<SetStateAction<boolean>>;
  revokeUserEmail: string;
  handleRemoveAccess: (email: string) => Promise<void>;
  entityType?: 'collection' | 'library';
}) => {
  const theme = useTheme();
  return (
    <Dialog open={openConfirmRevokeUserAccessModal} onClose={() => setOpenConfirmRevokeUserAccessModal(false)}>
      <Box sx={{ background: theme.palette.background.paper }}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to revoke access for{' '}
            <Typography component="span" sx={{ color: theme.palette.warning.contrastText }}>
              {revokeUserEmail}
            </Typography>{' '}
            from this {entityType}?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="destructive-light" onClick={() => handleRemoveAccess(revokeUserEmail)} color="error">
            Revoke Access
          </Button>
          <Button
            variant="clean-creative"
            onClick={() => setTimeout(() => setOpenConfirmRevokeUserAccessModal(false), 100)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

/**
 * Handles sharing collections and libraries
 * includes
 *  - text form for adding a user
 *  - send, edit, and remove user logic
 */
export const Share = ({
  initialAccessList,
  currentUser,
  inviteUserArgs,
  handleRemoveUserAccess,
  handleEditUserAccess,
  setSaveSuccess,
  entityType,
  isVisible
}: {
  setSaveSuccess: React.Dispatch<SetStateAction<string>>;
  currentUser: User;
  initialAccessList: {
    email: string;
    permission: UserPermission;
  }[];
  inviteUserArgs: object;
  handleRemoveUserAccess: (email: string) => void;
  handleEditUserAccess: (
    email: string,
    permission: UserPermission
  ) => Promise<{
    email: string;
    permission: UserPermission;
  }>;
  entityType: 'collection' | 'library';
  isVisible: boolean;
}) => {
  const theme = useTheme();
  const [userNotAllowed, setUserNotAllowed] = useState(false);

  const { postData: postInviteCollectionUser } = usePostApi<{ email: string; permission: UserPermission }>(
    'knowledge-finder/collection/invite'
  );
  const { postData: postInviteLibraryUser } = usePostApi<{ email: string; permission: UserPermission }>(
    'project/invite'
  );

  // #region individual email invite
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteInProgress, setInviteInProgress] = useState(false);

  const handleSendInvite = async () => {
    try {
      setInviteInProgress(true);
      // bail early if the email is already in the access list
      if (accessList?.find((user) => user.email === inviteEmail)) return;

      // bail early if inviting themselves
      if (inviteEmail.toLowerCase() === currentUser.email?.toLowerCase()) return;

      let inviteResult: { email: string; permission: UserPermission };
      if (entityType === 'collection') {
        inviteResult = await postInviteCollectionUser({ ...inviteUserArgs, inviteEmail });
      } else {
        inviteResult = await postInviteLibraryUser({ ...inviteUserArgs, inviteEmail });
      }
      if (!inviteResult) {
        if (!inviteEmail) {
          return;
        }
        // Unprocessable Entity
        setUserNotAllowed(true);
        return;
      }

      if (!initialAccessList?.find((user) => user.email === inviteResult.email)) {
        setAccessList((prev) => [...prev, { email: inviteResult.email, permission: inviteResult.permission }]);
      }

      setInviteEmail('');
      setSaveSuccess('Permissions updated successfully!');
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 422) {
        setUserNotAllowed(true);
      }
    } finally {
      setInviteInProgress(false);
    }
  };
  // #endregion individual email invite

  // #region individual role management
  const [accessList, setAccessList] = useState<{ email: string; permission: UserPermission }[]>([]);
  const [isGettingAccessList, setIsGettingAccessList] = useState(true);
  const [revokeUserEmail, setRevokeUserEmail] = useState('');
  const [openConfirmRevokeUserAccessModal, setOpenConfirmRevokeUserAccessModal] = useState(false);

  useEffect(() => {
    if (initialAccessList) {
      const filteredAccessList = initialAccessList.filter((user) => user.email !== currentUser.email);
      setAccessList(filteredAccessList);
    }
    setIsGettingAccessList(false);
  }, [currentUser, initialAccessList]);

  const handleRemoveAccess = async () => {
    try {
      handleRemoveUserAccess(revokeUserEmail);
      const filteredAccessList = accessList.filter((user) => user.email !== revokeUserEmail);
      setAccessList(filteredAccessList);

      setTimeout(() => {
        setOpenConfirmRevokeUserAccessModal(false);
        setSaveSuccess('Access revoked successfully!');
      }, 250);
    } finally {
      setRevokeUserEmail('');
    }
  };

  const handlePermissionChange = async (email: string, permission: UserPermission & 'remove') => {
    if (permission === 'remove') {
      setRevokeUserEmail(email);
      setOpenConfirmRevokeUserAccessModal(true);
    } else {
      const editResult = await handleEditUserAccess(email, permission);
      setAccessList(
        accessList.map((user) =>
          user.email === editResult.email ? { ...user, permission: editResult.permission } : user
        )
      );
      setSaveSuccess('Permissions updated successfully!');
    }
  };
  // #endregion individual role management

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        maxHeight: {
          sm: '15vh',
          md: '25vh'
        },
        width: '100%',
        overflow: 'auto',
        scrollbarWidth: 'none', // Hide the scrollbar for firefox
        '&::-webkit-scrollbar': {
          display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        '&-ms-overflow-style:': {
          display: 'none' // Hide the scrollbar for IE
        }
      }}
    >
      {entityType === 'collection' && !!userNotAllowed && (
        <Snackbar
          open={!!userNotAllowed}
          autoHideDuration={SNACKBAR_HANG_TIME.SLOW}
          onClose={() => setUserNotAllowed(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ paddingTop: 6, paddingRight: 15, maxWidth: '600px' }}
        >
          <Alert
            icon={<Typography>✦</Typography>}
            onClose={() => setUserNotAllowed(false)}
            severity="error"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2)
            }}
          >
            This user must be added to the library before they can receive collection access. Please contact the library
            owner to grant this email access to the library.
          </Alert>
        </Snackbar>
      )}
      <TextField
        value={inviteEmail || ''}
        name="send-invite"
        label="Send Invite"
        variant="filled"
        fullWidth
        placeholder="Enter an email..."
        InputLabelProps={{
          shrink: true,
          style: {
            transition: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.secondary.light,
            transform: 'translate(0, -6px) scale(0.75)',
            ...theme.typography.h6
          }
        }}
        onChange={(e) => setInviteEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action to avoid form submission or any unwanted behavior
            handleSendInvite();
          }
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: 'transparent',
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            alignItems: 'center' // Ensure vertical alignment is centered, especially important for non-multiline fields
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{ color: theme.palette.primary.light, fontSize: '3rem' }}
                edge="end"
                disabled={inviteInProgress}
                onClick={handleSendInvite}
              >
                <ArrowRightAlt />
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{
          mb: theme.spacing(1),
          mt: theme.spacing(2),
          color: theme.palette.text.primary,
          '& .MuiInputBase-input': {
            paddingLeft: 0
          },
          '& .MuiFilledInput-root': {
            '&:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&:hover:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&.Mui-focused:before': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            '&.Mui-focused:after': {
              borderBottom: `1px solid ${theme.palette.text.primary}`
            },
            color: theme.palette.text.primary,
            backgroundColor: 'transparent'
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.primary,
            '&.Mui-focused': {
              color: theme.palette.text.primary
            }
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary
          },
          '& .MuiInputBase-input::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1
          }
        }}
      />
      <Box sx={{ mt: theme.spacing(2), display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1">SHARED WITH</Typography>
        <Typography variant="subtitle1">ACCESS</Typography>
      </Box>
      {isGettingAccessList ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <DualRing />
        </Box>
      ) : (
        <List
          sx={{
            height: '300px',
            maxHeight: '300px',
            overflow: 'auto',
            scrollbarWidth: 'none', // Hide the scrollbar for firefox
            '&::-webkit-scrollbar': {
              display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
            },
            '&-ms-overflow-style:': {
              display: 'none' // Hide the scrollbar for IE
            }
          }}
        >
          {accessList.map((user) => (
            <ListItem key={user.email} sx={{ padding: '10px 0', display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{user.email}</Typography>
              <Box sx={{ minWidth: '120px', position: 'relative' }}>
                <Select
                  value={user.permission}
                  onChange={(e) => handlePermissionChange(user.email, e.target.value as UserPermission & 'remove')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: theme.palette.grey[700]
                      }
                    }
                  }}
                  sx={{
                    bgcolor: theme.palette.grey[800],
                    border: 'transparent',
                    height: '35px',
                    borderRadius: theme.sharperBorderRadius,
                    width: '120px',
                    fontSize: '0.9em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    '& .MuiSelect-icon': {
                      display: 'none',
                      position: 'absolute'
                    },
                    '& .MuiSelect-select': {
                      padding: '0px 40px', // Adjusting padding here for the selected item
                      overflow: 'hidden', // ensure text doesn't spill out
                      textOverflow: 'ellipsis' // handle longer text gracefully
                    }
                  }}
                >
                  <MenuItem value={UserPermission.VIEWER}>viewer</MenuItem>
                  <MenuItem value={UserPermission.CONTRIBUTOR}>editor</MenuItem>
                  <MenuItem value={UserPermission.OWNER}>owner</MenuItem>
                  <MenuItem
                    value="remove"
                    sx={{
                      color: theme.palette.red.light,
                      fontWeight: '800'
                    }}
                  >
                    REMOVE
                  </MenuItem>
                </Select>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
      <RevokeAccessDialog
        openConfirmRevokeUserAccessModal={openConfirmRevokeUserAccessModal}
        setOpenConfirmRevokeUserAccessModal={setOpenConfirmRevokeUserAccessModal}
        revokeUserEmail={revokeUserEmail}
        handleRemoveAccess={handleRemoveAccess}
        entityType={entityType}
      />
    </Box>
  );
};
