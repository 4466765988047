import { useRouteChange } from '@/hooks/use-route-change';
import { createContext, useContext } from 'react';
import { Location } from 'react-router-dom';
import { FileMetadata } from '../types';

type DocumentContextType = {
  documents: FileMetadata[];
  setDocuments: React.Dispatch<React.SetStateAction<FileMetadata[]>>;
  currentDocument: FileMetadata | undefined;
  setCurrentDocument: React.Dispatch<React.SetStateAction<FileMetadata | undefined>>;
};
const DocumentContext = createContext<DocumentContextType | undefined>(undefined);
export const DocumentProvider = DocumentContext.Provider;
export function useDocumentContext(): DocumentContextType {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDocumentContext must be used within a DocumentProvider');
  }

  const { setCurrentDocument, currentDocument, ...rest } = context;

  // Use the useRouteChange hook to clear the current document when the route changes, and the current document is not in the URL.
  useRouteChange((location: Location) => {
    if (!location.pathname.endsWith(`documents/${currentDocument?.documentId}`)) {
      setCurrentDocumentWithLog(undefined);
    }
  });

  // Create a wrapper function for setCurrentDocument that includes a console log.
  const setCurrentDocumentWithLog = (doc: React.SetStateAction<FileMetadata | undefined>) => {
    console.log('setCurrentDocumentWithLog:', doc);
    setCurrentDocument(doc);
  };

  // Return the modified context with the new setCurrentDocumentWithLog function.
  return {
    ...rest,
    currentDocument,
    setCurrentDocument: setCurrentDocumentWithLog
  };
}
