import { User } from '@/types/user';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
  darken
} from '@mui/material';
import { memo, useEffect, useState } from 'react';

interface EditUserDialogProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onSave: (user: User) => void;
  theme: Theme;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = memo(({ open, onClose, user, onSave, theme }) => {
  const [editedUser, setEditedUser] = useState(user);

  useEffect(() => {
    setEditedUser(user); // Update local state when the user prop changes
  }, [user]);

  const handleDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedUser((prev) => ({
      ...prev,
      toggles: { ...prev?.toggles, [name]: value },
      [name]: value,
      email: prev?.email || '',
      firstName: prev?.firstName || '',
      lastName: prev?.lastName || '',
      uuid: prev?.uuid || ''
    }));
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setEditedUser((prev) => ({
      ...prev,
      toggles: { ...(prev?.toggles ?? {}), [name]: checked },
      [name]: checked, // explicitly setting here for cleaner DTO handoff to BE
      email: prev?.email || '',
      firstName: prev?.firstName || '',
      lastName: prev?.lastName || '',
      uuid: prev?.uuid || ''
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Existing User</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Email"
          type="text"
          fullWidth
          variant="standard"
          name="email"
          disabled
          value={editedUser?.email || ''}
          sx={{
            '& .Mui-disabled': {
              color: darken(theme.palette.primary.contrastText, 0.3),
              '-webkit-text-fill-color': darken(theme.palette.primary.contrastText, 0.3)
            }
          }}
        />
        <TextField
          margin="dense"
          label="UUID"
          type="text"
          fullWidth
          variant="standard"
          name="uuid"
          disabled
          value={editedUser?.uuid || 'pending email verification and login'}
          sx={{
            '& .Mui-disabled': {
              color: darken(theme.palette.primary.contrastText, 0.3),
              '-webkit-text-fill-color': darken(theme.palette.primary.contrastText, 0.3)
            }
          }}
        />
        <TextField
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          variant="standard"
          name="firstName"
          value={editedUser?.firstName || ''}
          onChange={handleDetailChange}
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          variant="standard"
          name="lastName"
          value={editedUser?.lastName || ''}
          onChange={handleDetailChange}
        />
        <Typography sx={{ pt: 2, color: theme.palette.grey[500] }} variant="caption" display="block" gutterBottom>
          FLAGS
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!editedUser?.toggles?.['canChatWithOpenAI'] || false}
              onChange={handleToggleChange}
              name="canChatWithOpenAI"
              style={{ color: theme.palette.green.light }}
            />
          }
          label="OpenAI Chat Access"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!editedUser?.toggles?.['canAccessKnowledgeFinder'] || false}
              onChange={handleToggleChange}
              name="canAccessKnowledgeFinder"
              style={{ color: theme.palette.green.light }}
            />
          }
          label="KF Access"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!editedUser?.toggles?.['canUseLocalModels'] || false}
              onChange={handleToggleChange}
              name="canUseLocalModels"
              style={{ color: theme.palette.green.light }}
            />
          }
          label="Local Model Chat Access"
        />
        <Typography sx={{ pt: 2, color: theme.palette.grey[500] }} variant="caption" display="block" gutterBottom>
          EXPERIENCES
        </Typography>
        <TextField
          margin="dense"
          label="Preferred Landing"
          type="text"
          fullWidth
          variant="standard"
          name="preferredLanding"
          value={editedUser?.toggles?.['preferredLanding'] || ''}
          onChange={handleDetailChange}
        />
      </DialogContent>
      <DialogActions
        sx={{
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button variant="destructive-light" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="clean-creative" onClick={() => onSave(editedUser as User)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
