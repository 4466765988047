import { AppBar, Box, ButtonBase, CssBaseline, Toolbar, Typography, useTheme } from '@mui/material';

/**
 *
 * @returns
interface DocumentHeaderProps {
  collection: DocumentCollection | undefined;
  rows: ParsedDataRow[];
  projectId: string | undefined;
  collectionId: string | undefined;
  navigate: (path: string) => void;
  theme: Theme;
}

export const DocumentUploadHeader = ({
  collection,
  rows,
  projectId,
  collectionId,
  navigate,
  theme
}: DocumentHeaderProps) => {
 */

interface PublicAppBarProps {
  navigate: (path: string) => void;
}

export const PublicAppBar = ({ navigate }: PublicAppBarProps) => {
  const theme = useTheme();

  return (
    <Box>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: theme.palette.green.dark }} elevation={0}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <ButtonBase onClick={() => navigate('/landing')}>
              <Typography
                sx={{
                  fontFamily: 'Aeonik',
                  fontSize: 24
                }}
              >
                ✦ Knowledge Finder
              </Typography>
            </ButtonBase>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <ButtonBase onClick={() => navigate('/pub/login')}>
              <Typography
                sx={{
                  fontFamily: 'Aeonik',
                  fontSize: 24
                }}
              >
                Log In
              </Typography>
            </ButtonBase>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
