import { LibraryProject } from '@/types/projects';
import { User, UserPermission } from '@/types/user';
import { createContext, useContext } from 'react';

type LibraryContextType = {
  currentUser: User | undefined;
  library: LibraryProject;
  setLibrary: React.Dispatch<React.SetStateAction<LibraryProject | undefined>>;
  libraryPermission?: UserPermission;
  /**
   * these are used to do a full re-pull of collections whenever one is actively
   * managed by the end user. this is to handle all of the following cases:
   * - a collection has been removed
   * - a collection has moved from public to private
   * - a collection has moved from public to shared
   * - a collection has moved from shared to private
   * - a collection has moved from shared to public
   * - a collection has moved from private to public
   * - a collection has moved from private to shared
   */
  shouldReinitCollections: boolean;
  setShouldReinitCollections: React.Dispatch<React.SetStateAction<boolean>>;
};
const LibraryContext = createContext<LibraryContextType | undefined>(undefined);
export const LibraryProvider = LibraryContext.Provider;
export function useLibraryContext(): LibraryContextType {
  const context = useContext(LibraryContext);
  if (!context) {
    throw new Error('useLibraryContext must be used within a LibraryProvider');
  }
  return context;
}
