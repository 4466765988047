import { EditPage, SaveSuccessSnackbar } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import { usePostApi } from '@/hooks/use-post-api';
import { User, UserPermission } from '@/types/user';
import { formatDateString } from '@/utils/date-utils';
import { getNameOrEmail } from '@/utils/name-utils';
import { Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCollectionContext } from '../../stores/collection-context';
import { useLibraryContext } from '../../stores/library-context';
import { DocumentCollection } from '../../types';
import { AccessAndPermissionsToggle, Share } from './access-and-permissions';
import { AdvancedSettingsToggle, IsPublicToggle } from './advanced-settings';
import { CollectionsFlexibleMetaschemaTable } from './collection-flexible-metaschema-table';
import { ReusableSettingsTextField } from './reusable-settings-text-field';

export const CollectionSettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // #region state
  const { collectionId, projectId } = useParams<{
    collectionId: string;
    projectId: string;
  }>();
  const { currentUser, library, setShouldReinitCollections } = useLibraryContext();
  const { collection, collectionPermission, setCollection } = useCollectionContext();
  const [saveSuccess, setSaveSuccess] = useState('');
  const [state, setState] = useState(collection);
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] = useState(false);
  const [accessList, setAccessList] = useState<{ email: string; permission: UserPermission }[]>([]);
  // #endregion state

  // #region API handlers
  const { postData: postRemoveUserAccess } = usePostApi<DocumentCollection>(
    'knowledge-finder/delete/access/collection'
  );
  const { postData: postEditCollectionUserAccess } = usePostApi<{ email: string; permission: UserPermission }>(
    'knowledge-finder/collection/edit/access'
  );
  const { postData: postEditCollection } = usePostApi<DocumentCollection>(`knowledge-finder/collection/edit`);
  const { postData: postDeleteCollection } = usePostApi<void>('knowledge-finder/delete/collection');
  const [collectionAccessList] = useGetApi<{ email: string; permission: UserPermission }[]>(
    `knowledge-finder/collection/${projectId ?? library.uuid}/${collectionId ?? collection?.uuid}/access`
  );
  // #endregion API handlers

  // Add a function to handle the toggle change
  const handleIsPublicToggle = (isPublic: boolean) => {
    setState((prevState) => {
      if (!prevState) return undefined;
      return { ...prevState, isPublic };
    });
  };

  const handleMetadataSchemeChange = (metadata: { id: number; label: string; type?: string; prompt?: string }[]) => {
    setState((prevState) => {
      if (!prevState) return undefined;
      return { ...prevState, flexibleMetaschema: metadata };
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target as HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown };
    if (name) {
      setState((prevState) => {
        if (!prevState) return undefined;
        return { ...prevState, [name]: value };
      });
    }
  };

  useEffect(() => {
    if (collectionAccessList) {
      const filteredAccessList = collectionAccessList.filter((user) => user.email !== currentUser?.email);
      setAccessList(filteredAccessList);
    }
  }, [currentUser, collectionAccessList]);

  // Update state when collection changes, so that the form is reset to the most recently clicked collection
  useEffect(() => {
    console.log('collection state changed: ', collection);
    setState(collection);
  }, [collection]);

  return (
    <EditPage
      shouldPinBottom={isAdvancedSettingsVisible}
      header={'Collection Settings'}
      navigate={navigate}
      handleClose={() => {}}
      confirmDeleteMsg="Are you sure you want to delete this collection? This action cannot be undone."
      postDeleteEntity={async () => {
        await postDeleteCollection({
          documentCollectionId: collection?.uuid,
          projectId: library.uuid
        });
        setShouldReinitCollections(true);
        navigate(`/v2/knowledge-finder/${library.uuid}`);
      }}
      onSaveClicked={async () => {
        const updatedCollection = await postEditCollection({
          libraryId: library.uuid,
          collectionId: collection?.uuid,
          name: state?.name,
          description: state?.description,
          isPublic: state?.isPublic,
          flexibleMetaschema: state?.flexibleMetaschema ? JSON.stringify(state.flexibleMetaschema) : undefined
        });
        setSaveSuccess('Collection updated successfully!');
        setCollection((prevState) => {
          if (!prevState) return updatedCollection;
          return {
            ...prevState,
            name: updatedCollection.name,
            description: updatedCollection.description,
            updatedAt: updatedCollection.updatedAt,
            isPublic: updatedCollection.isPublic
          };
        });
        setState((prevState) => {
          if (!prevState) return updatedCollection;
          return {
            ...prevState,
            name: updatedCollection.name,
            description: updatedCollection.description,
            updatedAt: updatedCollection.updatedAt,
            isPublic: updatedCollection.isPublic
          };
        });
        setShouldReinitCollections(true);
        navigate(`/v2/knowledge-finder/${library.uuid}/collections/${collection?.uuid}`);
      }}
    >
      <Typography variant="subtitle2" sx={{ color: theme.palette.secondary.light, mt: theme.spacing(-1) }}>
        Created {formatDateString(state?.createdAt as string)} by{' '}
        {getNameOrEmail({
          email: state?.curatedBy.email,
          firstName: state?.curatedBy.firstName,
          lastName: state?.curatedBy.lastName
        })}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ color: theme.palette.secondary.light, mt: theme.spacing(-1), pb: theme.spacing(6) }}
      >
        Settings last saved {formatDateString(state?.updatedAt as string)}
      </Typography>
      <ReusableSettingsTextField
        label="Name"
        name="name"
        value={state?.name || undefined}
        onChange={handleInputChange}
        theme={theme}
      />
      <ReusableSettingsTextField
        label="Description"
        name="description"
        value={state?.description || undefined}
        onChange={handleInputChange}
        theme={theme}
        maxLength={5000}
      />
      <AccessAndPermissionsToggle onToggle={setIsShareVisible} theme={theme} />
      <Share
        initialAccessList={accessList}
        currentUser={currentUser as User}
        entityType="collection"
        inviteUserArgs={{
          collectionId: collection?.uuid,
          projectId: library.uuid
        }}
        handleRemoveUserAccess={async (email) => {
          await postRemoveUserAccess({
            collectionId: collection?.uuid,
            projectId: library.uuid,
            email
          });
          setShouldReinitCollections(true);
        }}
        handleEditUserAccess={async (email, permission) =>
          await postEditCollectionUserAccess({
            collectionId: collection?.uuid,
            projectId: library.uuid,
            email,
            permission
          })
        }
        setSaveSuccess={setSaveSuccess}
        isVisible={isShareVisible}
      />
      {collectionPermission === UserPermission.OWNER && (
        <>
          <AdvancedSettingsToggle onToggle={setIsAdvancedSettingsVisible} theme={theme} />
          <IsPublicToggle
            isVisible={isAdvancedSettingsVisible}
            isPublic={state?.isPublic || false} // Ensure you have a fallback value
            onToggle={handleIsPublicToggle}
            theme={theme}
          />
          <CollectionsFlexibleMetaschemaTable
            onChange={handleMetadataSchemeChange}
            isVisible={isAdvancedSettingsVisible}
            theme={theme}
            metadataScheme={state?.flexibleMetaschema || []}
          />
        </>
      )}
      <SaveSuccessSnackbar saveSuccess={saveSuccess} setSaveSuccess={setSaveSuccess} />
    </EditPage>
  );
};
