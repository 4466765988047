import ConstructionIcon from '@mui/icons-material/Construction';
import { Box, Container, Typography, lighten, useTheme } from '@mui/material';

export const UnderConstruction = ({ name }: { name?: string | null }) => {
  const theme = useTheme();

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginBottom: theme.spacing(25) }}>
      <Box>
        <ConstructionIcon style={{ fontSize: 100, color: lighten(theme.palette.red.dark, 0.6) }} />
        <Typography variant="h3" sx={{ color: 'black' }}>
          <span style={{ color: theme.palette.red.light, fontWeight: '400' }}>{name}</span> is under construction
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'black' }}>
          stay tuned
        </Typography>
      </Box>
    </Container>
  );
};
