import { Box, Button, CssBaseline, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingGif from '../assets/kf_landing.gif';
import { PublicAppBar } from './shared/public-app-bar';

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <CssBaseline />
      <Box
        sx={{
          height: '110vh',
          width: '100vw',
          m: 0,
          p: 0,
          textAlign: 'center',
          backgroundColor: '#292b28'
        }}
      >
        <PublicAppBar navigate={navigate} />
        <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText, fontSize: 83, mt: theme.spacing(9) }}>
          Knowledge Finder
        </Typography>
        <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], fontSize: 21, mb: theme.spacing(3) }}>
          An Intelligent Research Partner
        </Typography>
        <img src={LandingGif} alt="Landing GIF" style={{ width: '900px', height: 'auto' }} />
        <Box
          sx={{
            width: '900px',
            margin: 'auto',
            height: 'auto',
            display: 'flex',
            justifyContent: 'space-evenly',
            mt: theme.spacing(4)
          }}
        >
          <Button
            onClick={() => navigate('/pub/login')}
            sx={{
              width: '165px',
              backgroundColor: theme.palette.green.lightest,
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: '#c8edea'
              },
              borderRadius: 1.5
            }}
          >
            log in
          </Button>
          <Button
            onClick={() => navigate('/landing/info')}
            sx={{
              width: '165px',
              backgroundColor: theme.palette.green.lightest,
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: '#c8edea'
              },
              borderRadius: 1.5
            }}
          >
            learn more
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
