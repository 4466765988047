// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gapi: any;
import useGoogleDrivePicker, { ViewIdOptions } from '@/hooks/use-google-drive-picker';
import { usePostFileApi } from '@/hooks/use-post-file-api';
import { Button, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import GoogleDriveLogo from '../../../../assets/google_drive_logo.png';
import { UploadFileResponse } from './types';

interface GoogleDriveUploadButtonProps {
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  theme: Theme;
}

export const GoogleDriveUploadButton = ({ isUploading, setIsUploading, theme }: GoogleDriveUploadButtonProps) => {
  const [openPicker] = useGoogleDrivePicker();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { collectionId, projectId } = useParams<{ collectionId: string; projectId: string }>();

  const { usePostFileApi: uploadPdf } = usePostFileApi<UploadFileResponse>('knowledge-finder/document/upload/pdf');
  const { usePostFileApi: uploadPpt } = usePostFileApi<UploadFileResponse>('knowledge-finder/document/upload/ppt');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (fileType: ViewIdOptions) => {
    handleClose();
    setIsUploading(true);
    handleOpenPicker(fileType);
  };

  const handleSingleFileUpload = useCallback(
    async (file: File) => {
      try {
        const payload = {
          name: file.name,
          documentCollectionId: collectionId,
          projectId,
          isFromGoogleDrive: true
        };

        if (file.type === 'application/pdf') {
          await uploadPdf(payload, file);
        } else if (
          file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          file.type === 'application/vnd.ms-powerpoint'
        ) {
          await uploadPpt(payload, file);
        } else {
          throw new Error('Unsupported file type');
        }
      } catch (error) {
        console.error('Failed file upload: ', error);
      }
    },
    [collectionId, projectId, uploadPdf, uploadPpt]
  );

  const handleOpenPicker = (fileType: ViewIdOptions) => {
    gapi.load('client:auth2', () => {
      gapi.client
        .init({
          apiKey: import.meta.env.VITE_GOOGLE_PICKER_API_KEY || ''
        })
        .then(() => {
          let tokenInfo = gapi.auth.getToken();
          const pickerConfig = {
            clientId: import.meta.env.VITE_GOOGLE_PICKER_CLIENT_ID || '',
            developerKey: import.meta.env.VITE_GOOGLE_PICKER_API_KEY || '',
            viewId: fileType,
            // see mime types: https://developers.google.com/drive/api/guides/mime-types
            viewMimeTypes:
              'application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
            token: tokenInfo ? tokenInfo.access_token : null,
            showUploadView: false,
            showUploadFolders: false,
            supportDrives: false,
            multiselect: true,
            // @ts-expect-error allow for any datatype back from drive
            callbackFunction: (data) => {
              console.log('action here: ', data.action);
              if (data.action === 'picked') {
                if (!tokenInfo) {
                  tokenInfo = gapi.auth.getToken();
                }
                const fetchOptions = {
                  headers: {
                    Authorization: `Bearer ${tokenInfo.access_token}`
                  }
                };
                const driveFileUrl = 'https://www.googleapis.com/drive/v3/files';
                // @ts-expect-error allow for any datatype back from drive
                const promises = data.docs.map(async (item) => {
                  const response = await fetch(`${driveFileUrl}/${item.id}?alt=media`, fetchOptions);
                  const blob = await response.blob();
                  const file = new File([blob], item.name, {
                    type:
                      fileType === 'PDFS'
                        ? 'application/pdf'
                        : fileType === 'PRESENTATIONS'
                          ? 'application/vnd.ms-powerpoint'
                          : blob.type
                  });
                  await handleSingleFileUpload(file);
                });
                Promise.all(promises)
                  .then()
                  .catch((error) => {
                    console.error('An error occurred:', error);
                  })
                  .finally(() => {
                    setIsUploading(false);
                  });
              } else if (data.action === 'cancel') {
                setIsUploading(false);
              }
            }
          };
          openPicker(pickerConfig);
        });
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={
          isUploading ? null : (
            <img src={GoogleDriveLogo} style={{ paddingLeft: theme.spacing(1), width: '2.5vw', height: '2.5vh' }} />
          )
        }
        onClick={handleClick}
        disabled={isUploading}
        sx={{
          height: '5vh',
          width: '300px',
          borderRadius: theme.sharperBorderRadius,
          backgroundColor: theme.palette.primary.dark,
          '&:hover': {
            backgroundColor: theme.palette.primary.light
          },
          textTransform: 'none'
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: theme.palette.primary.contrastText, whiteSpace: 'nowrap', fontSize: 10, pr: theme.spacing(1) }}
        >
          {isUploading ? 'Uploading...' : 'Upload From Google Drive'}
        </Typography>
      </Button>
      <Menu id="file-type-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick('PDFS')}>PDFs</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('PRESENTATIONS')}>Presentations</MenuItem>
      </Menu>
    </>
  );
};
