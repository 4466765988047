import { Box, CircularProgress, GlobalStyles, Typography, useTheme } from '@mui/material';

interface LoadingScreenProps {
  message?: string; // Optional prop to customize the loading message
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ message = '✦ Loading...' }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        m: 0,
        p: 0
      }}
    >
      <GlobalStyles
        styles={{
          html: {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
            overflow: 'hidden'
          },
          body: {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            background: theme.palette.background.default
          },
          '#root': {
            width: '100%',
            height: '100%'
          }
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <CircularProgress sx={{ color: theme.palette.green.light }} />
      </Box>
      <Typography variant="h3" sx={{ color: theme.palette.primary.contrastText, mt: theme.spacing(5) }}>
        {message}
      </Typography>
    </Box>
  );
};
