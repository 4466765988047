import { DataGrid } from '@/components';
import { ParsedDataRow, ProcessStatus, UploadProcessType } from '@/types/data-uploads'; // Adjust import path as necessary
import { UserPermission } from '@/types/user';
import { formatDateString } from '@/utils/date-utils';
import { getHideScrollBarProps } from '@/utils/theme-utils';
import { DeleteForever } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Theme, Tooltip, Typography, lighten } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { RowMetadata } from './types';

interface DocumentsTableProps {
  permission?: UserPermission;
  theme: Theme;
  rows: ParsedDataRow[];
  onDeleteClick: (row: RowMetadata) => void; // Callback function to handle delete click
}

// #region data grid helpers
function formatBytes(bytes: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    unit: 'byte'
  });

  return formatter.format(bytes);
}

function getStatusColor(status: ProcessStatus, theme: Theme) {
  return status === 'completed'
    ? theme.palette.text.primary
    : status === 'failed'
      ? lighten(theme.palette.error.main, 0.5)
      : theme.palette.primary.light;
}

/**
 * shared function for rendering a cell with a dynamic message
 *
 * shows in this order:
 *   - error message if error state reached
 *   - stopped messaged if too long since last update
 *   - loading spinner if no data yet and is still a recent process
 *   - data count if completed
 */
const renderCellWithDynamicMessage = (
  getStatusColor: (status: ProcessStatus, theme: Theme) => string,
  theme: Theme
) => {
  return (params: GridRenderCellParams) => {
    const state = params as {
      value: { dataCount: number; errorMessage?: string; status: ProcessStatus; processType: UploadProcessType };
    };
    const latestUpdateTime = params.row.latestUpdateTime;

    if (state.value?.errorMessage) {
      return (
        <Typography
          variant="body2"
          sx={{
            color: getStatusColor(state.value?.status, theme),
            fontSize: CELL_FONT_SIZE,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          title={`${state.value.errorMessage}`}
        >
          error
        </Typography>
      );
    }

    if (
      state.value?.status !== ProcessStatus.COMPLETED &&
      latestUpdateTime &&
      differenceInMinutes(new Date(), parseISO(latestUpdateTime)) > 5
    ) {
      return (
        <Typography
          variant="body2"
          sx={{
            color: getStatusColor(ProcessStatus.FAILED, theme),
            fontSize: CELL_FONT_SIZE,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          stopped
        </Typography>
      );
    }

    if (!state.value) {
      return (
        <Typography
          variant="body2"
          sx={{
            fontSize: CELL_FONT_SIZE,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <CircularProgress size="1rem" sx={{ color: theme.palette.primary.light }} />
        </Typography>
      );
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: getStatusColor(state.value?.status, theme),
          fontSize: CELL_FONT_SIZE,
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        title={`${
          state.value.processType === UploadProcessType.INITIAL_UPLOAD
            ? state.value?.dataCount + ' bytes'
            : state.value?.dataCount
        }`}
      >
        {`${
          state.value.processType === UploadProcessType.INITIAL_UPLOAD
            ? formatBytes(state.value.dataCount)
            : state.value.dataCount
        }`}
      </Typography>
    );
  };
};
// #endregion data grid helpers

const CELL_FONT_SIZE = '0.8rem';
const HEADER_STYLES = {
  m: 1,
  fontSize: '.8rem'
};
const HEADER_WITH_HELP_STYLES = {
  ...HEADER_STYLES,
  cursor: 'help'
};
const BASE_COLUMN_PROPS: Partial<GridColDef> = { align: 'center', headerAlign: 'center', sortable: true };

export const DocumentUploadStatusTable = ({ rows, onDeleteClick, permission, theme }: DocumentsTableProps) => {
  const columns: GridColDef[] = useMemo(() => {
    const columnDefs = [
      {
        ...BASE_COLUMN_PROPS,
        field: 'name',
        headerName: 'File',
        width: 250,
        renderHeader: () => (
          <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_STYLES }}>
            FILE
          </Typography>
        ),
        renderCell: (params: GridRenderCellParams) => {
          const state = params as { value: string };
          return (
            <Typography
              variant="body2"
              sx={{ fontSize: CELL_FONT_SIZE, overflow: 'hidden', textOverflow: 'ellipsis' }}
              title={state.value as string}
            >
              {state.value}
            </Typography>
          );
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'createdAt',
        headerName: 'Date',
        width: 130,
        renderHeader: () => (
          <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_STYLES }}>
            DATE
          </Typography>
        ),
        renderCell: (params: GridRenderCellParams) => {
          const state = params as { value: Date | string };
          const fullDate = new Date(state.value).toLocaleString();
          const formattedDate = formatDateString(state.value as string);
          return (
            <Typography variant="body2" sx={{ fontSize: CELL_FONT_SIZE }} title={fullDate}>
              {formattedDate}
            </Typography>
          );
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'uploading',
        width: 130,
        renderHeader: () => (
          <Tooltip title="File is being transferred to our secure storage.">
            <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_WITH_HELP_STYLES }}>
              SIZE
            </Typography>
          </Tooltip>
        ),
        renderCell: renderCellWithDynamicMessage(getStatusColor, theme)
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'parsing',
        width: 130,
        renderHeader: () => (
          <Tooltip title="File is being divided into manageable sections for processing.">
            <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_WITH_HELP_STYLES }}>
              PAGES
            </Typography>
          </Tooltip>
        ),
        renderCell: renderCellWithDynamicMessage(getStatusColor, theme)
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'training',
        width: 130,
        renderHeader: () => (
          <Tooltip title="Extracting meaning from the contents of the file.">
            <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_WITH_HELP_STYLES }}>
              VECTORS
            </Typography>
          </Tooltip>
        ),
        renderCell: renderCellWithDynamicMessage(getStatusColor, theme)
      }
    ];
    // if an owner, allow a delete column to surface
    if (permission && permission === UserPermission.OWNER) {
      columnDefs.push({
        ...BASE_COLUMN_PROPS,
        field: 'delete',
        headerName: 'DELETE',
        width: 80,
        resizable: false,
        renderHeader: () => (
          <Tooltip title="Remove this file from the collection.">
            <Typography variant="body2" color="textSecondary" sx={{ ...HEADER_WITH_HELP_STYLES }}>
              DELETE
            </Typography>
          </Tooltip>
        ),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <IconButton onClick={() => onDeleteClick(params.row)} aria-label="delete">
              <DeleteForever />
            </IconButton>
          );
        }
      });
    }
    return columnDefs;
  }, [permission, onDeleteClick, theme]);

  return (
    <Box
      sx={{
        height: '600px',
        overflow: 'auto',
        ...getHideScrollBarProps()
      }}
    >
      <DataGrid
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        checkboxSelection={false}
        columns={columns}
        rows={rows}
        hideFooter={true}
        hideFooterRowCount={true}
        disableColumnMenu={true}
        autoHeight={false}
        hideBorder={true}
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none', // Remove the outline
            borderColor: 'inherit' // Use the original border color
          },
          ...getHideScrollBarProps()
        }}
      />
    </Box>
  );
};
