import { ThreePanelLayout } from '@/features/layout';
import { useNavBar } from '@/features/navbar';
import { useGetApi } from '@/hooks/use-get-api';
import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import { LibraryProject } from '@/types/projects';
import { UserPermission } from '@/types/user';
import { useEffect, useState } from 'react';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';
import { CollectionProvider } from '../stores/collection-context';
import { DocumentProvider } from '../stores/document-context';
import { useLibraryContext } from '../stores/library-context';
import { SearchProvider } from '../stores/search-context';
import { DocumentCollection, FileMetadata } from '../types';
import { ChatWithContext } from './chat/chat-with-context';
import { LibrarySideNav } from './side-nav/side-nav';

/**
 * Main component for knowledge finder logic when a library is selected
 */
export const LibraryFrontDesk = () => {
  const { collectionId, libraryId } = useParams<{ collectionId?: string; libraryId: string }>();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const { setChildBreadcrumb } = useNavBar();
  const { library, libraryPermission, shouldReinitCollections, setShouldReinitCollections } = useLibraryContext();

  // #region vars
  const [officialCollections, setOfficialCollections] = useState<DocumentCollection[]>([]);
  const [sharedCollections, setSharedCollections] = useState<DocumentCollection[]>([]);
  const [privateCollections, setPrivateCollections] = useState<DocumentCollection[]>([]);
  const [currentCollection, setCurrentCollection] = useState<DocumentCollection | undefined>();
  const [currentCollectionAccess, setCurrentCollectionAccess] = useState<UserPermission | undefined>();
  const [documents, setDocuments] = useState<FileMetadata[]>([]);
  const [searchResults, setSearchResults] = useState<FileMetadata[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [currentDocument, setCurrentDocument] = useState<FileMetadata | undefined>();
  // #endregion vars

  // #region API handlers
  // GET
  const { getData: getCollections } = useGetApiViaCallback<{
    officialCollections: DocumentCollection[];
    sharedCollections: DocumentCollection[];
    privateCollections: DocumentCollection[];
  }>(`knowledge-finder/library/v2/${libraryId ?? library.uuid}/collections`);
  const { getData: getDocumentCollectionInfo } = useGetApiViaCallback<{
    access: UserPermission;
    documentCollection: DocumentCollection;
    library: LibraryProject;
  }>(`knowledge-finder/collection/${libraryId ?? library.uuid}/${collectionId}`);

  // POST
  const { postData } = usePostApi<DocumentCollection>('knowledge-finder/collection');
  // #endregion API handlers

  // #region use effect
  // get all collections in the given library
  const [fetchedCollections] = useGetApi<{
    officialCollections: DocumentCollection[];
    sharedCollections: DocumentCollection[];
    privateCollections: DocumentCollection[];
  }>(`knowledge-finder/library/v2/${libraryId ?? library.uuid}/collections`);
  useEffect(() => {
    if (fetchedCollections) {
      setOfficialCollections(fetchedCollections.officialCollections);
      setSharedCollections(fetchedCollections.sharedCollections);
      setPrivateCollections(fetchedCollections.privateCollections);
    }
  }, [fetchedCollections]);

  useEffect(() => {
    const getCollectionsInfo = async () => {
      try {
        const collections = await getCollections();
        if (collections) {
          setOfficialCollections(collections.officialCollections);
          setSharedCollections(collections.sharedCollections);
          setPrivateCollections(collections.privateCollections);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (shouldReinitCollections) {
      getCollectionsInfo();
      setShouldReinitCollections(false);
    }
  }, [getCollections, setShouldReinitCollections, shouldReinitCollections]);

  // get current collection
  useEffect(() => {
    const getCurrentCollection = async () => {
      try {
        const { access, documentCollection } = await getDocumentCollectionInfo();
        if (documentCollection) {
          setCurrentCollection(documentCollection);
          setCurrentCollectionAccess(access);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (collectionId) {
      getCurrentCollection();
    } else {
      // clear collection when we route back to library
      setCurrentCollection(undefined);
    }
  }, [collectionId, getDocumentCollectionInfo]);

  useEffect(() => {
    if (currentCollection) {
      setChildBreadcrumb({
        name: currentCollection.name,
        route: `/v2/knowledge-finder/${library.uuid}/collections/${currentCollection.uuid}`
      });
    } else {
      setChildBreadcrumb(undefined);
    }
  }, [currentCollection, library.uuid, setChildBreadcrumb]);
  // #endregion use effect

  // #region handlers
  // handles new collection creation
  const handleAddCollection = async (isPublic: boolean) => {
    try {
      const collection = await postData({
        name: 'Untitled Collection',
        libraryId: library.uuid,
        isPublic
      });
      // update state
      if (isPublic) {
        setOfficialCollections([...officialCollections, { ...collection, permission: UserPermission.OWNER }]);
      } else {
        setPrivateCollections([...privateCollections, { ...collection, permission: UserPermission.OWNER }]);
      }
      // nav to collection upload
      navigate(`/v2/knowledge-finder/${library.uuid}/collections/${collection.uuid}`);
    } catch (err) {
      console.error('Collection creation failed:', err);
    }
  };
  // #endregion handlers

  return (
    <CollectionProvider
      value={{
        collection: currentCollection,
        setCollection: setCurrentCollection,
        collectionPermission: currentCollectionAccess,
        handleAddCollection
      }}
    >
      <SearchProvider value={{ searchResults, setSearchResults, searchQuery, setSearchQuery }}>
        <DocumentProvider value={{ documents, setDocuments, currentDocument, setCurrentDocument }}>
          <ThreePanelLayout
            sideNavChildren={
              <LibrarySideNav
                currentCollection={currentCollection}
                projectId={library.uuid}
                publicCollections={officialCollections}
                sharedCollections={sharedCollections}
                privateCollections={privateCollections}
                onCollectionAdd={(isPublic: boolean) => handleAddCollection(isPublic)}
                libraryPermission={libraryPermission ?? undefined}
              />
            }
            middlePanelChildren={outlet}
            chatPanelChildren={
              <ChatWithContext
                library={library}
                collection={currentCollection}
                documents={documents}
                document={currentDocument}
                searchResults={searchResults}
                query={searchQuery}
              />
            }
          />
        </DocumentProvider>
      </SearchProvider>
    </CollectionProvider>
  );
};
