import { LoadingScreen } from '@/components/loading-screen';
import { useNavBar } from '@/features/navbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
  const theme = useTheme();
  const { isLoading, loginWithRedirect } = useAuth0();
  // get redirect path to send user along after login
  const { state } = useLocation();

  const { setColor, setProductTitle } = useNavBar();
  useEffect(() => {
    setColor(theme.palette.black);
    setProductTitle(' ');
  }, [setColor, setProductTitle, theme.palette.black]);

  useEffect(() => {
    const logIn = () =>
      loginWithRedirect({
        appState: {
          returnTo:
            (state?.from && !state.from.startsWith('/landing/') ? state.from : '/v2/knowledge-finder') ||
            '/v2/knowledge-finder'
        }
      });
    logIn().catch((e) => console.error('Login error: ', e));
  }, [isLoading, loginWithRedirect, state]);

  return <LoadingScreen />;
}
