import * as MUIIcons from '@mui/icons-material';
import { useTheme } from '@mui/material';
import * as React from 'react';

export const DynamicIcon = ({ iconName }: { iconName: string }) => {
  const theme = useTheme();
  const IconComponent = React.lazy(() => Promise.resolve({ default: MUIIcons[iconName as keyof typeof MUIIcons] }));

  return (
    <React.Suspense fallback={<span>Loading...</span>}>
      <IconComponent style={{ verticalAlign: 'middle', fontSize: 'inherit', color: theme.palette.primary.lightest }} />
    </React.Suspense>
  );
};
