import { Page } from '@/components';
import { LoadingScreen } from '@/components/loading-screen';
import { usePostApi } from '@/hooks/use-post-api';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * listens to: https://docs.stripe.com/payments/checkout/custom-success-page
 */
export default function SubscribeSuccessPage() {
  const theme = useTheme();
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();

  const [tier, setTier] = useState<string>('visionary');
  const [expiration, setExpiration] = useState<Date>(new Date());
  const [trialEnd, setTrialEnd] = useState<Date>(new Date());

  const { postData: postConfirmSubscription } = usePostApi<{ expiration?: number; trialEnd?: number; tier?: string }>(
    `subscription/confirm`
  );

  useEffect(() => {
    const getSessionAndRedirect = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');
      if (sessionId) {
        const { tier, expiration, trialEnd } = await postConfirmSubscription({
          sessionId
        });
        if (trialEnd) {
          const calculatedTrialEnd = new Date(trialEnd * 1000);
          setTrialEnd(calculatedTrialEnd);
        }
        if (expiration) {
          const expirationDate = new Date(expiration * 1000);
          setExpiration(expirationDate);
        }
        if (tier) {
          setTier(tier);
        }
      }
    };

    if (!isLoading && user) {
      getSessionAndRedirect();
    }
  }, [postConfirmSubscription, user, isLoading]);

  return (
    <Box>
      {expiration ? (
        <Box
          sx={{
            background: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            m: 0,
            p: 0
          }}
        >
          <Page>
            <Card
              variant="outlined"
              sx={{
                border: 'none',
                maxWidth: 500,
                minHeight: 300,
                m: 'auto',
                backgroundColor: theme.palette.background.default
              }}
            >
              <Box sx={{ mt: theme.spacing(7), textAlign: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  THANK YOU FOR SUBSCRIBING TO{' '}
                  <span style={{ color: theme.palette.green.medium, fontWeight: 700 }}>PRISM'S KNOWLEDGE FINDER</span>
                </Typography>
                {!trialEnd && (
                  <Typography variant="subtitle1" sx={{ mt: theme.spacing(5) }}>
                    Your <span style={{ color: theme.palette.green.medium, fontWeight: 700 }}>{tier}</span> subscription
                    is now active and will be up for renewal on{' '}
                    <span style={{ color: theme.palette.green.medium, fontWeight: 700 }}>
                      {expiration.toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </span>
                    .
                  </Typography>
                )}
                {trialEnd && (
                  <Typography variant="subtitle1" sx={{ mt: theme.spacing(5) }}>
                    Your <span style={{ textDecoration: 'underline' }}> free trial</span> of the{' '}
                    <span style={{ color: theme.palette.green.medium, fontWeight: 700 }}>{tier}</span> subscription is
                    now active and will begin billing on{' '}
                    <span style={{ color: theme.palette.green.medium, fontWeight: 700 }}>
                      {(trialEnd || expiration).toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </span>
                    .
                  </Typography>
                )}
                <Typography variant="subtitle1" sx={{ mt: theme.spacing(5) }}>
                  If you have any questions or concerns, please contact{' '}
                  <a href="mailto:support@prism.bio" style={{ color: theme.palette.green.medium }}>
                    <b>support@prism.bio</b>
                  </a>{' '}
                  for assistance.
                </Typography>
              </Box>
            </Card>
            <Box sx={{ p: theme.spacing(8), textAlign: 'center' }}>
              <Button variant="clean-creative" onClick={() => navigate(`/v2/knowledge-finder`)}>
                <Typography>GO TO KNOWLEDGE FINDER</Typography>
              </Button>
            </Box>
          </Page>
        </Box>
      ) : (
        <LoadingScreen />
      )}
    </Box>
  );
}
