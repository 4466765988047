export enum FnTypes {
  Demonstration = 'demonstration',
  Investigation = 'investigation',
  Orchestration = 'orchestration'
}

export enum QueryParams {
  Type = 'type',
  Function = 'fn'
}
