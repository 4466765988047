import { useTheme } from '@mui/material/styles';
import MuiMarkdown from 'mui-markdown';
import { DynamicIcon } from './dynamic-icon';

export const CustomMarkdownRenderer = ({ content }: { content: string }) => {
  const theme = useTheme();

  return (
    <MuiMarkdown
      overrides={{
        action: {
          component: ({ children }) => (
            <strong
              style={{
                color: theme.palette.primary.medium,
                fontFamily: 'Montserrat',
                fontSize: 14,
                marginLeft: theme.spacing(0.5),
                letterSpacing: '0.25em',
                fontWeight: 700,
                textTransform: 'uppercase'
              }}
            >
              {children}
            </strong>
          )
        },
        strong: {
          component: ({ children }) => (
            <strong style={{ color: theme.palette.primary.contrastText, fontWeight: 400 }}>{children}</strong>
          )
        },
        icon: {
          component: ({ children }) => {
            if (children?.[0]) {
              return <DynamicIcon iconName={children[0]} />;
            } else {
              return <></>;
            }
          }
        }
      }}
    >
      {content}
    </MuiMarkdown>
  );
};
