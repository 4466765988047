import { NoteAddOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Theme, Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  isUploading: boolean;
  handleFileUpload: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  theme: Theme;
}

export const DocumentUploadDropzone = ({ isUploading, handleFileUpload, theme }: DropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const event = {
        target: {
          files: acceptedFiles
        }
      } as unknown as ChangeEvent<HTMLInputElement>;
      handleFileUpload(event);
    },
    [handleFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box>
      <Typography variant="h6" sx={{ color: theme.palette.grey[400], pt: theme.spacing(6), pb: theme.spacing(1) }}>
        Upload
      </Typography>
      <Box
        {...getRootProps()}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          position: 'relative',
          width: '100%',
          height: '15vh',
          border: `1.5px dashed ${theme.palette.grey[100]}`,
          borderTopLeftRadius: theme.sharperBorderRadius,
          borderTopRightRadius: theme.sharperBorderRadius,
          borderBottomLeftRadius: theme.sharperBorderRadius,
          borderBottomRightRadius: theme.sharperBorderRadius,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          }
        }}
      >
        <input
          {...getInputProps()}
          onChange={async (event) => {
            await handleFileUpload(event);
          }}
          accept=".pdf,.ppt,.pptx"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
            zIndex: 10
          }}
          id="raised-button-file"
          multiple
          type="file"
        />
        {isUploading ? (
          <CircularProgress sx={{ color: theme.palette.primary.light }} />
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h3" component="span">
              Drag files or click here to upload
            </Typography>
            <NoteAddOutlined
              sx={{
                ml: theme.spacing(1),
                pb: theme.spacing(1),
                fontSize: '3rem',
                color: theme.palette.primary.lightest
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
