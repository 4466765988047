import { Input, Theme } from '@mui/material';

type InputFieldProps = {
  input: string;
  setInput: (value: string) => void;
  handleSendMessage: (message: string) => void;
  disabled?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  theme: Theme;
};

/**
 * Styled input box for chat
 */
export const InputField = ({ input, setInput, handleSendMessage, disabled, inputRef, theme }: InputFieldProps) => (
  <Input
    inputRef={inputRef}
    disabled={disabled}
    disableUnderline={true}
    value={input}
    fullWidth
    onChange={(e) => setInput(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage(input);
      }
    }}
    inputProps={{
      style: {
        overflow: 'scroll',
        maxHeight: 'calc(100vh - 160px)',
        scrollbarWidth: 'none'
      }
    }}
    multiline
    sx={{
      flex: 1,
      backgroundColor: theme.palette.primary.lighter,
      borderRadius: theme.sharperBorderRadius,
      color: theme.palette.black.main,
      pl: theme.spacing(2),
      pr: theme.spacing(2)
    }}
  />
);
