import theme from '@/styles/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, SxProps, Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavBar } from '@/features/navbar';
import { PaletteColor, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Text field with custom colors from new theme
const useStyles = makeStyles({
  root: {
    '& label': { color: (props: { color: PaletteColor }) => props.color.main },
    '& label.Mui-focused': {
      color: (props: { color: PaletteColor }) => props.color.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: { color: PaletteColor }) => props.color.main
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: (props: { color: PaletteColor }) => props.color.main
    }
  }
});

export const StyledTextField = ({ color = theme.palette.blue, ...props }) => {
  const classes = useStyles({ color });

  return <TextField {...props} className={classes.root} />;
};

export const SearchBox = ({
  placeholder = 'Search the Vault',
  onRunSearch,
  customResultsHelper,
  sx = {}
}: {
  placeholder?: string;
  onRunSearch: (query: string) => void;
  customResultsHelper?: (query: string) => string;
  sx?: SxProps;
}): JSX.Element => {
  // get current search params (to show preserved search in text box on refresh)
  const [searchParams] = useSearchParams();
  const isShowingSearch = searchParams.get('isSearchActive') === 'true';
  const initialSearchQuery = searchParams.get('search') || '';
  const [input, setInput] = useState(initialSearchQuery);

  // stores current query for displaying helper text
  const [searchQuery, setSearchQuery] = useState(input);
  const resultsHelper = customResultsHelper ? customResultsHelper(searchQuery) : `Showing results for "${searchQuery}"`;
  const [helperText, setHelperText] = useState(searchQuery && input ? resultsHelper : '');

  useEffect(() => {
    setInput(initialSearchQuery || '');
  }, [initialSearchQuery]);

  useEffect(() => {
    if (!isShowingSearch) {
      setHelperText('');
    } else {
      setHelperText(resultsHelper);
    }
  }, [isShowingSearch, searchQuery, resultsHelper]);

  const { color } = useNavBar();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      width="100%"
      sx={{ mt: 0.5, ...sx }}
    >
      {initialSearchQuery && searchQuery && !isShowingSearch && (
        <Tooltip
          title="Back to results"
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Aeonik',
                fontSize: 10
              }
            }
          }}
        >
          <IconButton
            sx={{
              color: color?.dark,
              filter: 'drop-shadow(0px 0px 74px rgba(0, 50, 98, 0.22))',
              height: 30,
              width: 30,
              mt: 2,
              mr: 1,
              backgroundColor: theme.palette.background.default
            }}
            onClick={() => onRunSearch(input)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      <StyledTextField
        fullWidth
        color={color}
        size="small"
        sx={{
          marginLeft: searchQuery && !isShowingSearch ? 1 : 0, // provide some space if the back arrow is present
          width: 'auto', // let it adjust based on content and available space
          minWidth: {
            xs: '80%',
            sm: '85%',
            md: '90%',
            lg: '95%'
          },
          maxWidth: {
            xs: '90%'
          }
        }}
        label={placeholder}
        variant="standard"
        helperText={helperText}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
        value={input}
        onKeyPress={async (e: KeyboardEvent) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setSearchQuery(input);
            onRunSearch(input);
          }
        }}
        FormHelperTextProps={{
          sx: {
            color: 'gray',
            fontWeight: 100,
            fontStyle: 'italic'
          }
        }}
      />
    </Box>
  );
};
