import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/system';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    gradientContainer: {
      width: '80px',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white'
    },
    starIcon: {
      fontSize: '80px',
      background: `linear-gradient(90deg, 
          ${theme.palette.red.main} 0%, ${theme.palette.red.main} 12.5%, 
          ${theme.palette.orange.main} 12.5%, ${theme.palette.orange.main} 25%, 
          ${theme.palette.yellow.main} 25%, ${theme.palette.yellow.main} 37.5%, 
          ${theme.palette.green.main} 37.5%, ${theme.palette.green.main} 50%, 
          ${theme.palette.blue.main} 50%, ${theme.palette.blue.main} 62.5%, 
          ${theme.palette.purple.main} 62.5%, ${theme.palette.purple.main} 75%, 
          ${theme.palette.violet.main} 75%, ${theme.palette.violet.main} 87.5%, 
          ${theme.palette.pink.main} 87.5%, ${theme.palette.pink.main} 100%
      )`,
      //  blended here impl here:
      //         background: `linear-gradient(90deg,
      //           ${theme.palette.red.main},
      //           ${theme.palette.orange.main},
      //           ${theme.palette.yellow.main},
      //           ${theme.palette.green.main},
      //           ${theme.palette.blue.main},
      //           ${theme.palette.purple.main},
      //           ${theme.palette.violet.main},
      //           ${theme.palette.pink.main},
      //           ${theme.palette.red.main}
      //       )`,
      backgroundSize: '200% auto',
      animation: '$rainbow 10s linear infinite',
      backgroundClip: 'text',
      color: 'transparent',
      WebkitBackgroundClip: 'text'
    },
    '@keyframes rainbow': {
      '0%': {
        backgroundPosition: '200% 0'
      },
      '100%': {
        backgroundPosition: '0% 0'
      }
    }
  };
});

interface PrismLogoLoaderProps {
  style?: React.CSSProperties;
}

const PrismLogoLoader = ({ style }: PrismLogoLoaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.gradientContainer}>
      <span className={classes.starIcon} style={style}>
        ✦
      </span>
    </div>
  );
};

export default PrismLogoLoader;
