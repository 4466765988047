import axios from 'axios';
import { useCallback, useState } from 'react';
import { useAuthToken } from './use-auth-token';

type ReturnType<T> = { getData: () => Promise<T>; data: T | null; error: Error | null | unknown };

export const useGetApiViaCallback = <T,>(endpoint: string): ReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null | unknown>(null);

  const fetchToken = useAuthToken();

  const getData = useCallback(async () => {
    try {
      const token = await fetchToken();
      const response = await axios
        .create()
        .get(
          `${
            import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
          }/${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
    }
  }, [endpoint, fetchToken]);

  return { getData, data, error };
};
