import { useGetApiWithPolling } from '@/hooks/use-get-api-with-polling';
import { usePostApi } from '@/hooks/use-post-api';
import { usePostFileApi } from '@/hooks/use-post-file-api';
import { DataUploadStatus, ParsedDataRow, ProcessStatus, UploadProcessType } from '@/types/data-uploads';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCollectionContext } from '../../stores/collection-context';
import { DeleteConfirmationDialog } from './document-upload-delete-dialog';
import { DocumentUploadDropzone } from './document-upload-dropzone';
import { DocumentUploadHeader } from './document-upload-header';
import { DocumentUploadStatusHeader } from './document-upload-status-header';
import { DocumentUploadStatusTable } from './document-upload-status-table';
import { GoogleDriveUploadButton } from './google-drive-upload-button';
import { RowMetadata, UploadFileResponse } from './types';

export const DocumentUpload = () => {
  // #region state
  const theme = useTheme();
  const navigate = useNavigate();
  const { collection, collectionPermission: permission } = useCollectionContext();
  const { collectionId, projectId } = useParams<{ collectionId: string; projectId: string }>();
  const [isUploading, setIsUploading] = useState(false);
  const [deletedDocumentIds, setDeletedDocumentIds] = useState(new Set<string>());
  const [isModalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<RowMetadata | null>(null);
  // #endregion state

  // #region API defs
  const [dataUploadStatuses] = useGetApiWithPolling<DataUploadStatus[]>(
    `knowledge-finder/document/uploadStatus/${projectId}/${collectionId}`,
    null,
    isUploading
  );
  const { postData: postRemoveDocument } = usePostApi<void>('knowledge-finder/delete/document');
  const { usePostFileApi: uploadPdf } = usePostFileApi<UploadFileResponse>('knowledge-finder/document/upload/pdf');
  const { usePostFileApi: uploadPpt } = usePostFileApi<UploadFileResponse>('knowledge-finder/document/upload/ppt');
  // #endregion API defs

  // #region callbacks
  const handleFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        setIsUploading(true);
        if (event.target.files?.length) {
          for (const file of event.target.files) {
            const payload = {
              name: file.name,
              documentCollectionId: collectionId,
              projectId
            };
            if (file.type === 'application/pdf') {
              await uploadPdf(payload, file);
            } else if (
              file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
              file.type === 'application/vnd.ms-powerpoint'
            ) {
              await uploadPpt(payload, file);
            } else {
              throw new Error('Unsupported file type');
            }
          }
        }
      } catch (error) {
        console.error('failed initial file handoff: ', error);
      } finally {
        setIsUploading(false);
      }
    },
    [collectionId, projectId, uploadPdf, uploadPpt]
  );

  const handleDeleteClick = (row: RowMetadata) => {
    setRowToDelete(row);
    setModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (rowToDelete) {
        await postRemoveDocument({
          documentId: rowToDelete.id,
          documentCollectionId: rowToDelete.collectionId,
          projectId: rowToDelete.projectId
        });
        setDeletedDocumentIds((prevDeletedDocumentIds) => {
          const updatedSet = new Set(prevDeletedDocumentIds);
          updatedSet.add(rowToDelete.id);
          return updatedSet;
        });
      }
    } finally {
      setModalOpen(false);
    }
  };
  // #endregion callbacks

  // manage all the data for the table via the polling API response
  const rows = useMemo<ParsedDataRow[]>(
    () =>
      dataUploadStatuses
        // if there's a deleted row ID, filter out the row with that ID
        ?.filter((status) => (deletedDocumentIds ? !deletedDocumentIds.has(status.document.uuid) : true))
        .map((file) => {
          const uploadingNode = file.statuses.find(
            (status) => status?.processType === UploadProcessType.INITIAL_UPLOAD
          );
          const parsingNode = file.statuses.find(
            (status) => status?.processType === UploadProcessType.CHUNK_EXTRACTION
          );
          const trainingNode = file.statuses?.find(
            (status) => status.processType === UploadProcessType.SEMANTIC_EMBEDDING
          );

          let latestUpdateTime: string | undefined;

          // first handle state purely based on the upload process
          if (!parsingNode?.status || !trainingNode?.status) {
            if (uploadingNode?.updatedAt) latestUpdateTime = uploadingNode?.updatedAt;
          }

          // second handle state purely based on the parsing process
          if (parsingNode?.status && parsingNode?.status !== ProcessStatus.COMPLETED) {
            if (parsingNode?.updatedAt) latestUpdateTime = parsingNode?.updatedAt;
          }

          // third handle state purely based on the parsing process
          if (trainingNode?.status && trainingNode?.status !== ProcessStatus.COMPLETED) {
            if (trainingNode?.updatedAt) latestUpdateTime = trainingNode?.updatedAt;
          }

          return {
            id: file.document.uuid,
            createdAt: file.document.createdAt,
            name: file.document.title,
            uploading: uploadingNode,
            parsing: parsingNode,
            training: trainingNode,
            latestUpdateTime,
            collectionId,
            projectId
          };
        }) || [],
    [collectionId, projectId, dataUploadStatuses, deletedDocumentIds]
  );

  return (
    <Box sx={{ p: theme.spacing(6) }}>
      {collection && (
        <>
          <DocumentUploadHeader
            collection={collection}
            rows={rows}
            projectId={projectId}
            collectionId={collectionId}
            navigate={navigate}
            theme={theme}
          />
          <DocumentUploadDropzone isUploading={isUploading} handleFileUpload={handleFileUpload} theme={theme} />
          {import.meta.env.VITE_ENVIRONMENT !== 'prod' && (
            <Box sx={{ pt: theme.spacing(1.5) }}>
              <GoogleDriveUploadButton setIsUploading={setIsUploading} isUploading={isUploading} theme={theme} />
            </Box>
          )}
        </>
      )}
      {rows.length > 0 && (
        <>
          <DocumentUploadStatusHeader rows={rows} theme={theme} />
          <DocumentUploadStatusTable
            permission={permission}
            theme={theme}
            rows={rows}
            onDeleteClick={handleDeleteClick}
          />
        </>
      )}
      <DeleteConfirmationDialog
        open={isModalOpen}
        onConfirm={handleConfirmDelete}
        onClose={() => setModalOpen(false)}
        rowToDelete={rowToDelete}
        theme={theme}
      />
    </Box>
  );
};
