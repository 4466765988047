import { ThemeOptions, createTheme } from '@mui/material';

// the old theme
export const oldThemeOptions: ThemeOptions = {
  palette: {
    // mode: "light",
    primary: {
      main: '#192a3e',
      light: '#9bb7da'
    },
    secondary: {
      main: '#a90ea5',
      light: '#ffdcfe26'
    },
    info: {
      main: '#7695d7',
      light: '#f4f8ff',
      dark: '#004FC4'
    },
    warning: {
      main: '#ffb427',
      dark: '#E57A00'
    },
    error: {
      main: '#ff372e',
      light: '#ff6666',
      dark: '#E53535'
    },
    success: {
      main: '#38983d',
      dark: '#05A660'
    },
    background: {
      default: '#fff',
      paper: '#fff'
    },
    /**
     * custom colors from new theme work
     *
     * light was calculated via `lighten([COLOR].main, 0.2)`
     */
    red: { main: '#fff2f1', light: '#fff4f3', dark: '#8b1614' },
    orange: { main: '#ffe9cc', light: '#ffedd6', dark: '#eea962' },
    yellow: { main: '#ffe294', light: '#ffe7a9', dark: '#d88d24' },
    green: { main: '#c8edea', light: '#d3eeee', dark: '#00a797' },
    blue: { main: '#b2d2e6', light: '#c1dbeb', dark: '#0158ac' },
    purple: { main: '#a69bc9', light: '#b7afd3', dark: '#5e499d' },
    violet: { main: '#e1dceb', light: '#e7e3ef', dark: '#5e499e' },
    pink: { main: '#ffe5df', light: '#ffeae5', dark: '#ef6262' }
  },
  spacing: 8,
  borderRadius: 45,
  shape: {
    borderRadius: 6
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    h1: {
      fontFamily: 'Canela Text',
      fontWeight: 200
    },
    h2: {
      fontFamily: 'Canela Text'
    },
    h3: {
      fontFamily: 'Canela Text Thin',
      fontWeight: 200
    },
    h4: {
      fontFamily: 'Aeonik Light',
      fontWeight: 200
    },
    h5: {
      fontFamily: 'Aeonik'
    },
    h6: {
      fontFamily: 'Aeonik'
    },
    body1: {
      fontFamily: 'Aeonik',
      fontSize: 16
    },
    caption: {
      fontWeight: 700,
      fontFamily: 'Aeonik',
      fontSize: 14,
      color: 'black'
    },
    button: {
      fontFamily: 'Canela Text',
      fontSize: 16,
      fontWeight: 200,
      textTransform: 'none'
    },
    body2: {
      fontFamily: 'Aeonik',
      fontSize: 12
    },
    fontFamily: 'Canela Text',
    code: {}
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Aeonik',
          fontSize: 14,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          '&.Mui-disabled': {
            color: 'inherit'
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'destructive' },
          style: {
            fontFamily: 'Aeonik',
            fontWeight: 'bold',
            fontSize: '12px',
            borderRadius: 45,
            backgroundColor: 'none',
            color: '#8b1614',
            padding: '0',
            '&:hover': {
              textDecoration: 'underline',
              background: 'none'
            }
          }
        },
        {
          props: { variant: 'destructive-light' },
          style: {
            fontFamily: 'Aeonik',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: 45,
            backgroundColor: 'none',
            color: '#8b1614',
            padding: '0',
            '&:hover': {
              textDecoration: 'underline',
              background: 'none'
            }
          }
        },
        {
          props: { variant: 'creative' },
          style: {
            fontFamily: 'Aeonik',
            fontWeight: 'bold',
            fontSize: '12px',
            borderRadius: 45,
            backgroundColor: '#d3eeee',
            color: '#00a797',
            '&:hover': {
              backgroundColor: '#c8edea'
            }
          }
        },
        {
          props: { variant: 'clean-creative' },
          style: {
            fontFamily: 'Aeonik',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: 45,
            color: '#00a797',
            '&:hover': {
              backgroundColor: '#c8edea'
            }
          }
        }
      ]
    }
  }
};

export const basicTheme = createTheme(oldThemeOptions);
