import { useGetApiViaCallback } from '@/hooks/use-get-api-via-callback';
import { usePostApi } from '@/hooks/use-post-api';
import { Close, SaveAlt } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDocumentContext } from '../../stores/document-context';
import { FileMetadata } from '../../types';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

export const FileView = () => {
  // current doc context
  const { currentDocument, setCurrentDocument } = useDocumentContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const [numPages, setNumPages] = useState<number | null>(null);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isPageRendered, setIsPageRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinalPageRendered, setIsFinalPageRendered] = useState(false); // New state for tracking final page render

  // #region params
  const { collectionId, projectId, documentId } = useParams<{
    collectionId: string;
    projectId: string;
    documentId: string;
  }>();

  const [searchParams] = useSearchParams();
  const pageToNavigateTo = searchParams.get('sourcePage') ? parseInt(searchParams.get('sourcePage')!) : undefined;
  // #endregion params

  // #region resize
  const onResize = useCallback(() => {
    setIsPageRendered(false);
    setIsFinalPageRendered(false);
  }, []);

  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 500,
    onResize
  });
  // #endregion resize

  // #region API handlers
  const { getData: getSingleDocument } = useGetApiViaCallback<FileMetadata>(
    `knowledge-finder/document/${projectId}/${collectionId}/document/${documentId}`
  );
  const { postData: postTrackDocumentDownload } = usePostApi<void>(`knowledge-finder/document/trackDownloadEvent`);
  // #endregion API handlers

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    // Reset page rendered state on document load
    setIsPageRendered(false);
  };

  // #region use effect
  // get & set current document
  useEffect(() => {
    const getDocument = async () => {
      setIsLoading(true);
      if (currentDocument?.uuid === documentId || currentDocument?.documentId === documentId) {
        setIsLoading(false);
        return;
      }
      try {
        const doc = await getSingleDocument();
        setCurrentDocument(doc);
      } catch (e) {
        console.error(`Error getting project: ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (documentId) {
      getDocument();
    } else {
      setCurrentDocument(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  // Call scrollToPage when the target page has rendered
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsFinalPageRendered(true);
      if (pageToNavigateTo) {
        const page = pageRefs.current[pageToNavigateTo - 1];
        if (page) {
          page.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    }, 500);

    return () => clearTimeout(timeoutId); // Clear timeout on cleanup
  }, [pageToNavigateTo, isPageRendered]);
  // #endregion use effect

  return isLoading ? (
    <CircularProgress sx={{ color: theme.palette.green.light }} />
  ) : (
    <Box ref={ref} sx={{ p: theme.spacing(3) }}>
      {!isFinalPageRendered && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            zIndex: 1000
          }}
        >
          <CircularProgress sx={{ color: theme.palette.green.light }} />
        </Box>
      )}
      <Box position="sticky" width="50px" height="50px" sx={{ zIndex: 3, top: 0, right: 0, ml: 'calc(100% - 50px)' }}>
        <IconButton
          onClick={() => {
            setCurrentDocument(undefined);
            navigate(-1);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box position="relative" sx={{ mt: 0 }}>
        <Document file={currentDocument?.previewUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from({ length: numPages || 0 }, (_, index) => (
            <Box
              ref={(el: HTMLDivElement | null) => (pageRefs.current[index] = el)}
              key={`page_${index + 1}`}
              sx={{
                pb: theme.spacing(4)
              }}
            >
              <Page
                pageNumber={index + 1}
                onRenderSuccess={() => {
                  if (index + 1 === pageToNavigateTo) {
                    setIsPageRendered(true);
                  } else if (numPages && index === numPages - 1) {
                    setIsPageRendered(true);
                  }
                }}
                width={width ? width : 1}
                height={height ? height : 1}
                renderAnnotationLayer={false}
              />
            </Box>
          ))}
        </Document>
      </Box>
      {currentDocument?.downloadUrl && (
        <Box
          position="sticky"
          width="60px"
          height="60px"
          sx={{ zIndex: 3, bottom: 0, right: 0, ml: 'calc(100% - 60px)' }}
        >
          <IconButton
            onClick={async (e) => {
              e.preventDefault();
              await postTrackDocumentDownload({
                documentId,
                documentCollectionId: collectionId || currentDocument?.collections?.[0]?.uuid,
                projectId
              });
              const response = await fetch(currentDocument.downloadUrl);
              const blob = await response.blob();
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(blob);
              downloadLink.download = currentDocument.displayName || currentDocument.title;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
            sx={{ color: theme.palette.green.main }}
          >
            <SaveAlt fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
