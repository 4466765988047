import { Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FileMetadata } from '../../types';
import { DocumentCard } from './document-card';

/**
 * Shows latest uploads to a library in card mode
 */
export const CardView = ({ documents, onlyShowLatest }: { documents: FileMetadata[]; onlyShowLatest: boolean }) => {
  const theme = useTheme();

  // just show 3 latest uploads
  const docsToShow = onlyShowLatest
    ? documents
        .sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
        .slice(0, 3)
    : documents;

  return (
    <Box>
      {onlyShowLatest && (
        <Typography variant="h6" sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
          Latest Uploads
        </Typography>
      )}
      <Grid container columnSpacing={theme.spacing(4)}>
        {docsToShow.map((doc) => (
          <Grid key={doc.uuid} item minWidth={350} height={350}>
            <DocumentCard document={doc} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
