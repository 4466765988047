import { FullTatsu } from '@/features/_tatsu';
import { AuthBoundary } from '@/features/auth';
import { ErrorBoundary, ErrorProvider } from '@/features/error-handling';
import { Auth0Provider } from '@auth0/auth0-react';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import sentryConfig from './bootstrap/sentry-config';
import { RouteGuard } from './components';
import Layout from './components/layout';
import { KFV2Routes } from './features/knowledge-finder-v2';
import EmailVerifiedPage from './routes/email-verified';
import LandingPage from './routes/landing';
import { LandingRedirectComponent } from './routes/landing-redirect';
import LearnMorePage from './routes/learn-more';
import LoginPage from './routes/login';
import SessionExpiredPage from './routes/session-expired';
import SubscribeSuccessPage from './routes/subscribe/subscribe-success';
import SubscribeToScholarMonthlyPage from './routes/subscribe/subscribe-to-scholar-monthly';
import SubscribeToScholarYearlyPage from './routes/subscribe/subscribe-to-scholar-yearly';
import SubscribeToVisionaryMonthlyPage from './routes/subscribe/subscribe-to-visionary-monthly';
import SubscribeToVisionaryYearlyPage from './routes/subscribe/subscribe-to-visionary-yearly';
import VerificationPendingPage from './routes/verification-pending';
import { kfLuxuryTheme } from './styles/custom-themes';

const router = createBrowserRouter([
  {
    path: '/landing',
    children: [
      {
        path: '',
        element: <LandingPage />
      },
      {
        path: 'info',
        element: <LearnMorePage />
      }
    ]
  },
  {
    path: '/subscribe',
    children: [
      {
        path: 'scholar/yearly',
        element: <SubscribeToScholarYearlyPage />
      },
      {
        path: 'scholar/monthly',
        element: <SubscribeToScholarMonthlyPage />
      },
      {
        path: 'visionary/yearly',
        element: <SubscribeToVisionaryYearlyPage />
      },
      {
        path: 'visionary/monthly',
        element: <SubscribeToVisionaryMonthlyPage />
      },
      {
        path: 'success',
        element: <SubscribeSuccessPage />
      }
    ]
  },
  {
    path: '/pub',
    element: <Layout />,
    children: [
      {
        path: 'email-verified',
        element: <EmailVerifiedPage />
      },
      {
        path: 'session-expired',
        element: <SessionExpiredPage />
      },
      {
        path: 'verification-pending',
        element: <VerificationPendingPage />
      },
      {
        path: 'login',
        element: <LoginPage />
      }
    ]
  },
  {
    path: '/',
    element: (
      <RouteGuard>
        <Layout />
      </RouteGuard>
    ),
    children: [
      // #region default routing
      {
        path: '/',
        children: [
          {
            path: '',
            // navs to knowledge-finder unless configured otherwise
            element: <LandingRedirectComponent />
          }
        ]
      },
      // #endregion default routing

      // #region internal super user views
      {
        path: '/sonicboom',
        element: <FullTatsu />
      },
      // #endregion internal super user views
      // #region theme demo
      // #endregion theme demo

      // #region knowledge finder new designs
      {
        path: '/v2',
        children: [
          {
            path: 'knowledge-finder/*',
            element: <KFV2Routes />
          }
        ]
      }
      // #endregion knowledge finder new designs
    ]
  }
]);

// #region bootstrap
Sentry.init(sentryConfig);
// #endregion bootstrap

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GlobalStyles
      styles={{
        html: {
          height: '100%',
          margin: 0,
          padding: 0
        },
        body: {
          height: '100%',
          margin: 0,
          padding: 0
        }
      }}
    />
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN || 'login.prism.bio'}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID || 'zpTU5U0lsQa11sKeULXqBzI61tddFbGb'}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE || '/api/',
        redirect_uri: window.location.origin, // go back to the root of the site
        scope: 'profile email'
      }}
      // prevent logout on refresh
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ErrorProvider>
        <ErrorBoundary>
          <AuthBoundary>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider theme={kfLuxuryTheme}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </LocalizationProvider>
          </AuthBoundary>
        </ErrorBoundary>
      </ErrorProvider>
    </Auth0Provider>
  </React.StrictMode>
);
