import { User } from '@/types/user';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { memo, useState } from 'react';

interface ProvisionNewUserDialogProps {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSave: (user: User) => void;
  theme: Theme;
}

export const ProvisionNewUserDialog: React.FC<ProvisionNewUserDialogProps> = memo(
  ({ open, disabled, onClose, onSave, theme }) => {
    const [editedUser, setEditedUser] = useState({
      email: '',
      toggles: {
        canChatWithOpenAI: false,
        canUseLocalModels: false,
        canAccessKnowledgeFinder: false,
        preferredLanding: undefined
      }
    });

    const handleDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setEditedUser((prev) => ({
        ...prev,
        toggles: { ...(prev?.toggles ?? {}), [name]: value },
        [name]: value
      }));
    };

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setEditedUser((prev) => ({
        ...prev,
        toggles: { ...(prev?.toggles ?? {}), [name]: checked }
      }));
    };

    return (
      <Dialog open={open} onClose={onClose}>
        {disabled && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DialogTitle>Provision User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            name="email"
            value={editedUser?.email || ''}
            onChange={handleDetailChange}
          />
          <Typography sx={{ pt: 2, color: theme.palette.grey[600] }} variant="caption" display="block" gutterBottom>
            FLAGS
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canChatWithOpenAI'] || false}
                onChange={handleToggleChange}
                name="canChatWithOpenAI"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="OpenAI Chat Access"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canAccessKnowledgeFinder'] || false}
                onChange={handleToggleChange}
                name="canAccessKnowledgeFinder"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="KF Access"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!editedUser?.toggles?.['canUseLocalModels'] || false}
                onChange={handleToggleChange}
                name="canUseLocalModels"
                style={{ color: theme.palette.green.light }}
              />
            }
            label="Local Model Chat Access"
          />
          <Typography sx={{ pt: 2, color: theme.palette.grey[600] }} variant="caption" display="block" gutterBottom>
            EXPERIENCES
          </Typography>
          <TextField
            margin="dense"
            label="Preferred Landing"
            type="text"
            fullWidth
            variant="standard"
            name="preferredLanding"
            value={editedUser?.toggles?.['preferredLanding'] || ''}
            onChange={handleDetailChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="destructive-light" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={disabled} variant="clean-creative" onClick={() => onSave(editedUser as unknown as User)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
