import { EditPage, SaveSuccessSnackbar } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import { usePostApi } from '@/hooks/use-post-api';
import { LibraryProject } from '@/types/projects';
import { User, UserPermission } from '@/types/user';
import { formatDateString } from '@/utils/date-utils';
import { getNameOrEmail } from '@/utils/name-utils';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLibraryContext } from '../../stores/library-context';
import { AccessAndPermissionsToggle, Share } from './access-and-permissions';
import { ReusableSettingsTextField } from './reusable-settings-text-field';

export const LibrarySettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // #region state
  const { currentUser, library, setLibrary } = useLibraryContext();
  const [state, setState] = useState(library);
  const [saveSuccess, setSaveSuccess] = useState('');
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [accessList, setAccessList] = useState<{ email: string; permission: UserPermission }[]>([]);
  // #endregion state

  // #region API handlers
  const [projectAccessList] = useGetApi<{ email: string; permission: UserPermission }[]>(
    `project/${library.uuid}/access`
  );
  const { postData: postRemoveUserAccess } = usePostApi<LibraryProject>('knowledge-finder/delete/access/library');
  const { postData: postEditProjectUserAccess } = usePostApi<{ email: string; permission: UserPermission }>(
    'project/edit/access'
  );
  const { postData: postEditProject } = usePostApi<LibraryProject>('project/edit');
  const { postData: postDeleteProject } = usePostApi<void>('knowledge-finder/delete/library');
  // #endregion API handlers

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target as HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown };
    if (name) {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    if (projectAccessList) {
      const filteredAccessList = projectAccessList.filter((user) => user.email !== currentUser?.email);
      setAccessList(filteredAccessList);
    }
  }, [currentUser, projectAccessList]);

  return (
    <EditPage
      header={'Library Settings'}
      navigate={navigate}
      handleClose={() => {}}
      confirmDeleteMsg="Are you sure you want to delete this library? This action cannot be undone."
      postDeleteEntity={async () => {
        await postDeleteProject({
          projectId: library.uuid
        });
        setLibrary(undefined);
        navigate(`/v2/knowledge-finder`);
      }}
      onSaveClicked={async () => {
        const updatedLibrary = await postEditProject({
          projectId: library.uuid,
          name: state.name,
          description: state.description
        });
        setSaveSuccess('Library updated successfully!');
        setLibrary((prevState) => {
          if (!prevState) return updatedLibrary;
          return {
            ...prevState,
            name: updatedLibrary.name,
            description: updatedLibrary.description,
            updatedAt: updatedLibrary.updatedAt
          };
        });
        setState((prevState) => {
          return {
            ...prevState,
            name: updatedLibrary.name,
            description: updatedLibrary.description,
            updatedAt: updatedLibrary.updatedAt
          };
        });
        navigate(`/v2/knowledge-finder/${library.uuid}`);
      }}
    >
      <Typography variant="subtitle2" sx={{ color: theme.palette.secondary.light, mt: theme.spacing(-1) }}>
        Created {formatDateString(state.createdAt)} by{' '}
        {getNameOrEmail({
          email: state.createdBy.email,
          firstName: state.createdBy.firstName,
          lastName: state.createdBy.lastName
        })}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ color: theme.palette.secondary.light, mt: theme.spacing(-1), pb: theme.spacing(6) }}
      >
        Settings last saved {formatDateString(state.updatedAt as string)}
      </Typography>
      <ReusableSettingsTextField
        label="Name"
        name="name"
        value={state.name || undefined}
        onChange={handleInputChange}
        theme={theme}
      />
      <ReusableSettingsTextField
        label="Description"
        name="description"
        value={state.description || undefined}
        onChange={handleInputChange}
        theme={theme}
        maxLength={5000}
      />
      <AccessAndPermissionsToggle onToggle={setIsShareVisible} theme={theme} />
      <Share
        initialAccessList={accessList}
        currentUser={currentUser as User}
        entityType="library"
        inviteUserArgs={{
          projectId: library.uuid
        }}
        handleRemoveUserAccess={async (email) =>
          postRemoveUserAccess({
            projectId: library.uuid,
            email
          })
        }
        handleEditUserAccess={async (email, permission) =>
          await postEditProjectUserAccess({
            projectId: library.uuid,
            email,
            permission
          })
        }
        setSaveSuccess={setSaveSuccess}
        isVisible={isShareVisible}
      />
      <SaveSuccessSnackbar saveSuccess={saveSuccess} setSaveSuccess={setSaveSuccess} />
    </EditPage>
  );
};
