export enum UploadProcessType {
  INITIAL_UPLOAD = 'initial_upload',
  CHUNK_EXTRACTION = 'chunk_extraction',
  SEMANTIC_EMBEDDING = 'semantic_embedding'
}

export enum ProcessStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export interface DataUploadStatus {
  document: { uuid: string; title: string; createdAt: string };
  statuses: {
    uuid: string;
    processType: UploadProcessType;
    status: ProcessStatus;
    dataCount: number;
    errorMessage?: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface ParsedDataRow {
  id: string;
  createdAt: string;
  name: string;
  uploading?: {
    uuid: string;
    processType: UploadProcessType;
    status: ProcessStatus;
    dataCount: number;
    errorMessage?: string;
  };
  parsing?: {
    uuid: string;
    processType: UploadProcessType;
    status: ProcessStatus;
    dataCount: number;
    errorMessage?: string;
  };
  training?: {
    uuid: string;
    processType: UploadProcessType;
    status: ProcessStatus;
    dataCount: number;
    errorMessage?: string;
  };
}
