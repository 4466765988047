import { Box, CssBaseline, Grid, Link, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FindAnswer from '../assets/info_1_find_answers.png';
import AccelerateWorkflows from '../assets/info_2_accelerate_workflow.png';
import MaximizeValue from '../assets/info_3_maximize_value.png';
import { PublicAppBar } from './shared/public-app-bar';

export default function LearnMorePage() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ backgroundColor: '#292b28', height: '110vh', width: '100vw', m: 0, p: 0, textAlign: 'center' }}>
      <CssBaseline />
      <PublicAppBar navigate={navigate} />
      <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText, fontSize: 83, mt: theme.spacing(9) }}>
        Knowledge Finder
      </Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500], fontSize: 21, mb: theme.spacing(11) }}>
        An Intelligent Research Partner
      </Typography>
      <Grid container rowSpacing={10} sx={{ pb: theme.spacing(10), backgroundColor: '#292b28' }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'left', pl: theme.spacing(18) }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500] }}>
              Key Features
            </Typography>
            <Typography variant="h2" sx={{ pt: theme.spacing(3) }}>
              Find Answers Immediately
            </Typography>
            <Typography component="div" sx={{ pt: theme.spacing(3), color: theme.palette.grey[500] }}>
              With Knowledge Finder, it takes mere minutes to create a library, upload documents, and start engaging
              with them through intelligent search or chat. You can ask your library questions in whatever ways make
              sense to you.
              <Typography component="div" sx={{ mt: theme.spacing(2) }}>
                <Link
                  href="https://youtu.be/9eXmQ6xFOsw"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Click here to watch a video on how easy it is to get started.
                </Link>
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={FindAnswer} alt="Find Answers" style={{ width: '65%', padding: theme.spacing(2) }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={AccelerateWorkflows}
            alt="Accelerate Workflows"
            style={{ width: '65%', padding: theme.spacing(2) }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'left', pr: theme.spacing(18) }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500] }}>
              Key Features
            </Typography>
            <Typography variant="h2" sx={{ pt: theme.spacing(3) }}>
              Accelerate Your Workflow
            </Typography>
            <Typography component="div" sx={{ pt: theme.spacing(3), color: theme.palette.grey[500] }}>
              Move from source documents to deliverable faster than ever. Knowledge Finder doesn't just find
              information—it also prepares it for you, summarizing key findings, creating detailed outlines, or data
              that you can plug in to deliver your next report or presentation.
              <Typography component="div" sx={{ mt: theme.spacing(2) }}>
                <Link
                  href="https://youtu.be/yIsIBYkGsZY"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Click here to see this in action.
                </Link>
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'left', pl: theme.spacing(18) }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500] }}>
              Key Features
            </Typography>
            <Typography variant="h2" sx={{ pt: theme.spacing(3) }}>
              Maximize Knowledge Value
            </Typography>
            <Typography component="div" sx={{ pt: theme.spacing(3), color: theme.palette.grey[500] }}>
              Knowledge Finder supports multiple document formats (e.g., PDFs and PowerPoints), making it easy to
              transform your library of files into powerful knowledge resources.
              <Typography component="div" sx={{ mt: theme.spacing(2) }}>
                <Link
                  href="https://youtu.be/T1h96bENaPY"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Click here to see this in action.
                </Link>
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={MaximizeValue} alt="Maximize Value" style={{ width: '65%', padding: theme.spacing(2) }} />
        </Grid>
      </Grid>
    </Box>
  );
}
