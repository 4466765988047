import { LoadingScreen } from '@/components/loading-screen';
import { usePostApi } from '@/hooks/use-post-api';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export default function SubscribeToVisionaryYearlyPage() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  // #region API handlers
  const { postData: postInitSubscriptionSession } = usePostApi<string | boolean>(`subscription/session`);
  // #endregion API handlers

  useEffect(() => {
    const getSessionAndRedirect = async () => {
      const sessionUrl = await postInitSubscriptionSession({
        tier: 'visionary',
        frequency: 'yearly'
      });
      if (typeof sessionUrl === 'string') {
        window.location.href = sessionUrl;
      }
      if (sessionUrl === false) {
        return <Navigate to={`/pub/verification-pending`} state={{ from: location.pathname }} />;
      }
    };

    if (!isLoading && user) {
      getSessionAndRedirect();
    }
  }, [postInitSubscriptionSession, user, isLoading]);

  if (!isLoading && !isAuthenticated) {
    return <Navigate to="/pub/login" replace state={{ from: location.pathname }} />;
  }

  return <LoadingScreen />;
}
